import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { clone, get, includes, isEmpty } from "lodash";
import { Button } from "primereact/button";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import QuickLocationModalForm from "../../locations/partials/QuickLocationModalForm";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import QuickBrandModalForm from "../../brands/partials/QuickBrandModalForm";
import QuickBrandModelModalForm from "../../models/partials/QuickBrandModelModalForm";
import moment from "moment-timezone";
import { formatDate, random, utcDateToLocalDate } from "../../../../utils/Helper";
import { Dialog } from "primereact/dialog";
import Location from "../../../../components/Common/Display/Location";
import OrderStatus from "../../../../components/Common/Display/OrderStatus";
import VinWithType from "../../../../components/Common/Display/VinWithType";
import { TabPanel, TabView } from "primereact/tabview";
import { Checkbox } from "primereact/checkbox";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: null | any,
    client_id: string | any,
    from_location_id: string | any,
    to_location_id: string | any,
    brand_id: string | any,
    model_id: string | null | any,
    vin_number: string | any,
    order_cd: string | any,
    colour: string | any,
    net_weight: string | number | any,
    width: string | number | any,
    length: string | number | any,
    height: string | number | any,
    fuel_type: string | any,
    priorisation: boolean | any,
    automatic_start_next_journey: boolean | any,
    ldg_nr: string | any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        from_location_id: yup.string().nullable()
        .when(['id'], {
            is: (id: any) => isEmpty(id),
            then: yup.string().required(t('the field is required.', { field: t('from location') })).nullable()
        }),
        to_location_id: yup.string().nullable()
        .when(['id'], {
            is: (id: any) => isEmpty(id),
            then: yup.string().required(t('the field is required.', { field: t('to location') })).nullable()
        }),
        client_id: yup.string().nullable(),
        brand_id: yup.string().required(t('the field is required.', { field: t('brand') })).nullable(),
        model_id: yup.string().nullable().required(t('the field is required.', { field: t('model') })).nullable(),
        vin_number: yup.string()
        .required(t('the field is required.', { field: t('vin number') }))
        // .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]{5,19}$/, t('value must be between 6 and 20 characters, can only contain letters, numbers, underscores, and dashes, and cannot start with an underscore or dash'))
        .nullable(),
        order_cd: yup.string()
        .required(t('the field is required.', { field: t('order code') }))
        //.matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]{5,19}$/, t('value must be between 6 and 20 characters, can only contain letters, numbers, underscores, and dashes, and cannot start with an underscore or dash'))
        .nullable(),
        ldg_nr: yup.string().nullable(),
        colour: yup.string().max(30, t("the field must not be greater than max.", { // .required(t('the field is required.', { field: t('colour') }))
            field: "colour",
            max: 6
        })).nullable(),
        fuel_type: yup.string().max(6, t("the field must not be greater than max.", { // .required(t('the field is required.', { field: t('fuel type') }))
            field: "fuel type",
            max: 6
        })).nullable(),
        net_weight: yup.string().max(8, t("the field must not be greater than max.", { // .required(t('the field is required.', { field: t('net weight') }))
            field: "net length",
            max: 8
        })).nullable(),
        width: yup.string().max(8, t("the field must not be greater than max.", { // .required(t('the field is required.', { field: t('width') }))
            field: "length",
            max: 8
        })).nullable(),
        length: yup.string().max(6, t("the field must not be greater than max.", {// .required(t('the field is required.', { field: t('length') }))
            field: "length",
            max: 6
        })).nullable(),
        height: yup.string().max(6, t("the field must not be greater than max.", { // .required(t('the field is required.', { field: t('height') }))
            field: "height",
            max: 6
        })).nullable(),
        priorisation: yup.boolean().required(t('the field is required.', { field: t('priorisation') })).nullable(),
        automatic_start_next_journey: yup.boolean().required(t('the field is required.', { field: t('automatic start next journey') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    from_location_id: null,
    to_location_id: null,
    client_id: null,
    brand_id: null,
    model_id: null,
    vin_number: null,
    order_cd: null,
    colour: null,
    net_weight: null,
    width: null,
    length: null,
    height: null,
    fuel_type: null,
    ldg_nr: null,
    priorisation: 0,
    automatic_start_next_journey: true
};

function OrderForm(props: IFormProps) {
    const { t, toastify, params, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});
    const [reactivePopup, setReactivePopup] = useState<any>(false);
    const [isConfirmed, setIsConfirmed] = useState<any>(false);
    const [reactiveOrder, setReactiveOrder] = useState<any>([]);
    const [dropdowns, setDropdowns] = useState<any>({});

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/orders` : `/suppliers/orders/${ payloads.id }` ),
                data: {
                    ...payloads,
                    loading_factors: !payloads.id ? 0 : entity.loading_factors,
                    priorisation: payloads.priorisation ? 1 : 0,
                },
            });

            const data = response.data;
            if(!isEmpty(data) && !isEmpty(data.data) && !isEmpty(data.data.vin_number)) {
                setReactiveOrder(data.data);
                setReactivePopup(true);
            } else {
                if ( payloads.id ) {
                    toastify(t("record updated", { item: t('order') }), "success");
                } else {
                    toastify(t("record added", { item: t('order') }), "info");
                }

                resetForm();
                onClose();
            }
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onClickReactive = async () => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/orders/reactive`,
                data: {
                    ...formRef.current.values,
                    order_id: reactiveOrder.id
                },
            });

            toastify(t("record updated", { item: t('order') }), "success");
            setReactivePopup(false);
            setReactiveOrder(null);
            formRef.current.resetForm();
            onClose();
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    }

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit', 'add', 'clone'], get(params, ['operation']))
            );
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/orders/${ id }`
            });

            const { data } = response.data;
            const fields = clone(response.data.data);
            delete fields.brand_model;
            delete fields.supplier;
            delete fields.status_histories;
            delete fields.brand;
            delete fields.current_journey;
            delete fields.lead_time_start_date;
            delete fields.lead_time_start_time;
            const formData: TInputForm = {
                ...fields,
                priorisation: (fields.priorisation > 0),
                automatic_start_next_journey: fields.automatic_start_next_journey > 0,
                brand_id: get(response.data.data, ['brand', 'id']),
                model_id: get(response.data.data, ['brand_model', 'id']),
                from_location_id: null,
                to_location_id: null,
                client_id: get(response.data.data, ['client', 'id']),
            };

            if ( params.operation === 'clone' ) {
                formData.id = null;
                formData.from_location_id = get(response.data.data, ['current_journey', 'from_location', 'id']);
                formData.to_location_id = get(response.data.data, ['current_journey', 'to_location', 'id']);
                formData.vin_number = moment().format('dM') + '' + random(7, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789').toUpperCase();
                formData.order_cd = formData.vin_number;
                formData.automatic_start_next_journey = true;
            }

            setInitFormState(formData);
            setPanelState(true);
            setEntity(data);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onDetailByShow = async (e: any): Promise<void> => {
        if ( isEmpty(e.target.value) ) {
            return;
        }

        try {
            const response = await request({
                url: `/suppliers/orders/${e.target.value}/show/by/vin`
            });
            const { data } = response.data;
            if ( formRef.current.values && isEmpty(entity)) {
                if ( isEmpty(formRef.current.values.brand_id) ) {
                    formRef.current.setFieldValue('brand_id', data.brand.id);
                }

                if ( isEmpty(formRef.current.values.model_id) ) {
                    formRef.current.setFieldValue('model_id', data.brand_model.id);
                }

                formRef.current.setFieldValue('order_cd', data.order_cd);
            }
        } catch (error: any) {
            // toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        setInitFormState({
            ...DEFAULT_FORM_STATE,
            order_cd: moment().format('dM') + '' + random(7, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789').toUpperCase()
        });
        setEntity(null);
        setDropdowns(props.dropdowns);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit', 'clone'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    const onFromLocationUpdate = (payloads: any) => {
        formRef.current.setFieldValue('from_location_id', undefined);

        setDropdowns({
            ...dropdowns,
            locations: [{ ...payloads, key: payloads.id }].concat(dropdowns.locations.filter((item: any) => item.id !== payloads.id))
        });

        if ( formRef.current ) {
            formRef.current.setFieldValue('from_location_id', payloads.id);
        }
    };

    const onToLocationUpdate = (payloads: any) => {
        formRef.current.setFieldValue('to_location_id', undefined);

        setDropdowns({
            ...dropdowns,
            locations: [{ ...payloads, key: payloads.id }].concat(dropdowns.locations.filter((item: any) => item.id !== payloads.id))
        });

        if ( formRef.current ) {
            formRef.current.setFieldValue('to_location_id', payloads.id);
        }
    };

    const onBrandUpdate = (payloads: any) => {
        formRef.current.setFieldValue('brand_id', undefined);

        setDropdowns({
            ...dropdowns,
            brands: [{ key: payloads.id, id: payloads.id, label: payloads.title }].concat(dropdowns.brands.filter((item: any) => item.id !== payloads.id))
        });

        if ( formRef.current ) {
            formRef.current.setFieldValue('brand_id', payloads.id);
        }
    };

    const onBrandModelUpdate = (payloads: any) => {
        formRef.current.setFieldValue('model_id', undefined);

        setDropdowns({
            ...dropdowns,
            models: [{ ...payloads, key: payloads.id, label: `${payloads.title} ${payloads.version_code}`, brand_id: payloads.brand.id }].concat(dropdowns.models.filter((item: any) => item.id !== payloads.id))
        });

        if ( formRef.current ) {
            formRef.current.setFieldValue('model_id', payloads.id);
        }
    };

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('order') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   options={ dropdowns.clients }
                                                   name="client_id"
                                                   placeholder={ t("select") + ' ' + t("client") }
                                                   panelClassName={"max-width-300px hide-p-toggler"}
                                                   autoFocus={ true }
                                                   label={ t('client') } showClear />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="ldg_nr"
                                                   label={ t("ldg nr") }
                                                   placeholder={ t("enter") + ' ' + t("ldg nr").toLowerCase() }
                                                   />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="vin_number"
                                                   label={ t("vin number") }
                                                   placeholder={ t("enter") + ' ' + t("vin number").toLowerCase() }
                                                   onBlur={ onDetailByShow }
                                                   isRequired />
                                        </Col>
                                        {
                                            ( props.values && !props.values.id ) &&
                                          <>
                                              <Col sm={ 12 } md={ 6 }>
                                                  <div>
                                                      <label htmlFor={ 'from_location_id' } className={"mb-2 d-block"}>
                                                          { t("from location") }
                                                          <strong style={ { color: 'var(--red-500)' } }> *</strong>
                                                          <QuickLocationModalForm
                                                            selected={ props.values.from_location_id }
                                                            onStore={ onFromLocationUpdate }
                                                            onUpdate={ onFromLocationUpdate }
                                                            allowAdd={ true }
                                                            allowEdit={ true }
                                                            classForAddButton={ "ms-1 me-1 text-primary cursor-pointer" }
                                                            classForUpdateButton={ "text-primary cursor-pointer" }
                                                            labelForAddButton={ t('add') }
                                                            labelForUpdateButton={ t('update') }
                                                            fetchDropdowns={ true }
                                                            toastify={ toastify }
                                                          />
                                                    </label>
                                                      <Field component={ InputSingleSelectField }
                                                             options={ dropdowns.locations }
                                                             name="from_location_id"
                                                             placeholder={ t("select") + ' ' + t("from location") }
                                                             panelClassName={"max-width-300px hide-p-toggler"}
                                                             withoutLabel
                                                             showClear />
                                                  </div>
                                              </Col>
                                              <Col sm={ 12 } md={ 6 }>
                                                  <label htmlFor={ 'to_location_id' } className={"mb-2 d-block"}>
                                                      { t("to location") }
                                                      <strong style={ { color: 'var(--red-500)' } }> *</strong>
                                                      <QuickLocationModalForm
                                                        selected={ props.values.to_location_id }
                                                        onStore={ onToLocationUpdate }
                                                        onUpdate={ onToLocationUpdate }
                                                        allowAdd={ true }
                                                        allowEdit={ true }
                                                        classForAddButton={ "ms-1 me-1 text-primary cursor-pointer" }
                                                        classForUpdateButton={ "text-primary cursor-pointer" }
                                                        labelForAddButton={ t('add') }
                                                        labelForUpdateButton={ t('update') }
                                                        fetchDropdowns={ true }
                                                        toastify={ toastify }
                                                      />
                                                  </label>

                                                  <Field component={ InputSingleSelectField }
                                                         options={ dropdowns.locations }
                                                         name="to_location_id"
                                                         placeholder={ t("select") + ' ' + t("to location") }
                                                         panelClassName={"max-width-300px hide-p-toggler"}
                                                         withoutLabel showClear />
                                              </Col>
                                          </>
                                        }

                                        <Col sm={ 12 } md={ 6 }>
                                            <label htmlFor={ 'brand_id' } className={"mb-2 d-block"}>
                                                { t("brand") }
                                                <strong style={ { color: 'var(--red-500)' } }> *</strong>
                                                <QuickBrandModalForm
                                                    selected={ props.values.brand_id }
                                                    onStore={ onBrandUpdate }
                                                    onUpdate={ onBrandUpdate }
                                                    allowAdd={ true }
                                                    allowEdit={ true }
                                                    classForAddButton={ "ms-1 me-1 text-primary cursor-pointer" }
                                                    classForUpdateButton={ "text-primary cursor-pointer" }
                                                    labelForAddButton={ t('add') }
                                                    labelForUpdateButton={ t('update') }
                                                    toastify={ toastify }
                                                />
                                            </label>
                                            <Field component={ InputSingleSelectField }
                                                   options={ dropdowns.brands }
                                                   name="brand_id"
                                                   placeholder={ t("select") + ' ' + t("brand") }
                                                   panelClassName={"max-width-300px hide-p-toggler"}
                                                   onChange={ () => {
                                                       props.setFieldValue('model_id', undefined);
                                                   } }
                                                   withoutLabel showClear />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <label htmlFor={ 'model_id' } className={"mb-2 d-block"}>
                                                { t("model") }
                                                <strong style={ { color: 'var(--red-500)' } }> *</strong>
                                                <QuickBrandModelModalForm
                                                    selected={ props.values.model_id }
                                                    brandId={ props.values.brand_id }
                                                    onStore={ onBrandModelUpdate }
                                                    onUpdate={ onBrandModelUpdate }
                                                    allowAdd={ true }
                                                    allowEdit={ true }
                                                    classForAddButton={ "ms-1 me-1 text-primary cursor-pointer" }
                                                    classForUpdateButton={ "text-primary cursor-pointer" }
                                                    labelForAddButton={ t('add') }
                                                    labelForUpdateButton={ t('update') }
                                                    fetchDropdowns={ true }
                                                    toastify={ toastify }
                                                />
                                            </label>
                                            <Field component={ InputSingleSelectField }
                                                   options={ dropdowns.models?.filter((item: any) => ( item.brand_id === props.values.brand_id )) }
                                                   name="model_id"
                                                   placeholder={ t("select") + ' ' + t("model") }
                                                   panelClassName={"max-width-300px hide-p-toggler"}
                                                   withoutLabel showClear />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="order_cd"
                                                   label={ t("order code") }
                                                   placeholder={ t("enter") + ' ' + t("order code").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="colour"
                                                   label={ t("colour") }
                                                   placeholder={ t("enter") + ' ' + t("colour").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="net_weight"
                                                   label={ t("net weight") }
                                                   placeholder={ t("enter") + ' ' + t("net weight").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="length"
                                                   label={ t("length") }
                                                   placeholder={ t("enter") + ' ' + t("length").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="width"
                                                   label={ t("width") }
                                                   placeholder={ t("enter") + ' ' + t("width").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="height"
                                                   label={ t("height") }
                                                   placeholder={ t("enter") + ' ' + t("height").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="fuel_type"
                                                   label={ t("fuel type") }
                                                   placeholder={ t("enter") + ' ' + t("fuel type").toLowerCase() }
                                                   />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputSwitchField }
                                                   name="priorisation"
                                                   label={ t("priorisation") }
                                                   trueLabel={ t("high") }
                                                   falseLabel={ t("normal") }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => operationCancel(null, null)}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>

            <Dialog visible={ reactivePopup } onHide={ () => setReactivePopup(false) }
                    header={t('attention')} breakpoints={{'2500px': '75vw', '2000px': '75vw', '1700px': '75vw', '960px': '85vw', '641px': '95vw' }}>
                {
                    reactiveOrder &&
                  <>
                      <Col sm="12" md="12">
                          <Row>
                              <Col sm={12} md={6}>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6><span className="text-capitalize">{ t('vin number') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div
                                            className="text-end">{ reactiveOrder.vin_number }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6><span className="text-capitalize">{ t('created at') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div
                                            className="text-end">{ utcDateToLocalDate(reactiveOrder.created_at) }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6><span className="text-capitalize">{ t('brand') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div
                                            className="text-end">
                                              { reactiveOrder.brand?.title }
                                              { ( reactiveOrder.brand && reactiveOrder.brand.alternate_title ) && ` - ${ reactiveOrder.brand.alternate_title }` }
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6><span className="text-capitalize">{ t('model') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div
                                            className="text-end">
                                              { reactiveOrder.brand_model?.title }
                                              {
                                                  reactiveOrder.brand_model?.version_code &&
                                                <span> - { reactiveOrder.brand_model?.version_code }</span>
                                              }
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6><span className="text-capitalize">{ t('supplier') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div
                                            className="text-end">
                                              { reactiveOrder.supplier && reactiveOrder.supplier.name }
                                          </div>
                                      </Col>
                                  </Row>
                                  {
                                      ( reactiveOrder.current_journey && reactiveOrder.current_journey.from_location ) &&
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('from location') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                <Location item={ reactiveOrder.current_journey.from_location }
                                                          titled={ 1 } />
                                            </div>
                                        </Col>
                                    </Row>
                                  }
                                  {
                                      ( reactiveOrder.current_journey && reactiveOrder.current_journey.to_location ) &&
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('to location') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                <Location item={ reactiveOrder.current_journey.to_location }
                                                          titled={ 1 } />
                                            </div>
                                        </Col>
                                    </Row>
                                  }
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('client') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end text-capitalize">
                                              { reactiveOrder.client?.name }
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('ldg nr') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end text-capitalize">
                                              { reactiveOrder.ldg_nr }
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('order code') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.order_cd }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('status') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">
                                              <OrderStatus status={ reactiveOrder.status } />
                                          </div>
                                      </Col>
                                  </Row>
                              </Col>
                              <Col sm={12} md={6}>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('customer allocation') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.built_to_order_cd > 0 ? t("yes") : t("no") }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('priorisation') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.priorisation > 0 ? t("high") : t("normal") }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('automatic start next journey') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div
                                            className="text-end">{ reactiveOrder.automatic_start_next_journey > 0 ? t("yes") : t("no") }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('lead time start date') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">
                                              { reactiveOrder.lead_time_start_date ? formatDate(reactiveOrder.lead_time_start_date) : '' }
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('lead time start time') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">
                                              { reactiveOrder.lead_time_start_time }
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('net weight') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.net_weight }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('length') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.length }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('width') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.width }</div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="6" lg="6" sm="12">
                                          <h6>
                                              <span className="text-capitalize">{ t('height') }</span>
                                          </h6>
                                      </Col>
                                      <Col md="6" lg="6" sm="12">
                                          <div className="text-end">{ reactiveOrder.height }</div>
                                      </Col>
                                  </Row>
                              </Col>
                              <Col sm="12">
                                  <TabView>
                                      <TabPanel header={ t("order journeys") }>
                                          <table className={ "fl-table" }>
                                              <thead>
                                                  <tr>
                                                      <th>#</th>
                                                      <th>{ t("created at") }</th>
                                                      <th>{ t("from location") }</th>
                                                      <th>{ t("to location") }</th>
                                                      <th>{ t("status") }</th>
                                                      <th>{ t("system note") }</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {
                                                      reactiveOrder.order_journeys && reactiveOrder.order_journeys.map((journey: any, orderIndex: number) => {
                                                          return (
                                                              <tr key={ orderIndex }>
                                                                  <td>{ orderIndex + 1 }</td>
                                                                  <td>{ utcDateToLocalDate(get(journey, ['created_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                  <td>{ get(journey, ['from_location', 'label']) }</td>
                                                                  <td>{ get(journey, ['to_location', 'label']) }</td>
                                                                  <td className={ "text-uppercase" }>{ journey.status }</td>
                                                                  <td className={ "text-uppercase" }>{ journey.system_note }</td>
                                                              </tr>
                                                          );
                                                      })
                                                  }
                                              </tbody>
                                          </table>
                                      </TabPanel>
                                      <TabPanel header={ t("order process journeys") }>
                                          <table className={ "fl-table" }>
                                              <thead>
                                                  <tr>
                                                      <th>#</th>
                                                      <th>{ t("load number") }</th>
                                                      <th>{ t("route") }</th>
                                                      <th>{ t("drivers") }</th>
                                                      <th>{ t("transport vehicles") }</th>
                                                      <th>{ t("load status") }</th>
                                                      <th>{ t("created at") }</th>
                                                      <th>{ t("in transit at") }</th>
                                                      <th>{ t("delivered at") }</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {
                                                      reactiveOrder.process_journeys && reactiveOrder.process_journeys.map((journey: any, orderIndex: number) => {
                                                          return (
                                                              <tr key={ orderIndex }>
                                                                  <td>{ orderIndex + 1 }</td>
                                                                  <td>{ get(journey, ['load_number']) }</td>
                                                                  <td>{ get(journey, ['route_title']) }</td>
                                                                  <td>{ get(journey, ['drivers']) }</td>
                                                                  <td>{ get(journey, ['transport_vehicle']) }</td>
                                                                  <td className={"capitalize"}><strong>{ get(journey, ['item_status']) }</strong></td>
                                                                  <td>{ utcDateToLocalDate(get(journey, ['created_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                  <td>{ utcDateToLocalDate(get(journey, ['in_transit_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                  <td>{ utcDateToLocalDate(get(journey, ['finished_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                              </tr>
                                                          );
                                                      })
                                                  }
                                              </tbody>
                                          </table>
                                      </TabPanel>
                                  </TabView>
                              </Col>
                          </Row>
                      </Col>
                  </>
                }

                <Col sm={12}>
                    <p className={ "text-red-500" }>
                        <div className="flex align-items-center">
                            <Checkbox inputId="confirmed" name={ "confirmed" } value={ true } onChange={ (e: any) => setIsConfirmed(e.checked) }
                                      checked={ isConfirmed } />
                            <label htmlFor="confirmed" className="ml-2">
                                { t("vin number is already delivered. please confirm to reactive with new data") }
                            </label>
                        </div>

                    </p>
                    <Button type={ "button" } severity={ "danger" } disabled={!isConfirmed}
                            tooltip={ t("to data save into database") }
                            size={ "small" } outlined
                            tooltipOptions={ { position: 'top' } }
                            onClick={onClickReactive}
                    >
                        <i className="pi pi-save me-2" />
                        { t("confirm") }
                    </Button>
                    <Button type={ "button" } severity={ "warning" }
                            tooltip={ t("operation cancel") }
                            size={ "small" } outlined
                            tooltipOptions={ { position: 'top' } }
                            className={ "ml-2" }
                            onClick={() => setReactivePopup(false)}
                    >
                        <i className="pi pi-arrow-left me-2" />
                        { t("cancel") }
                    </Button>
                </Col>
            </Dialog>
        </>
    );
}

OrderForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(OrderForm);
