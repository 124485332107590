import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { request } from "../../../../utils/Request";
import { isEmpty, groupBy, map, uniqBy, uniq, get, head, last, find, some, includes, sortBy, maxBy } from "lodash";
import moment from "moment/moment";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../../../components/Shared/InputSelectField";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { Divider } from "primereact/divider";

interface IInvoiceableListProps {
    dropdowns?: any | undefined;
    t?: any | undefined;
    state: boolean
    onClose: any;
    toastify: any;
}

const DEFAULT_FILTER_FORM_STATE = {
    clients: [],
    vins: [],
    toInvoiceableDate: null,
    fromInvoiceableDate: null,
};

const DEFAULT_DROPDOWNS = {
    clients: [],
    vins: [],
    routes: [],
};

// @ts-ignore
function InvoiceableList(props: IInvoiceableListProps) {
    const { t, toastify } = props;
    const [isPanelActive, setPanelActive] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [dropdowns, setDropdowns] = useState<any>({ ...DEFAULT_DROPDOWNS });
    const [isOpen, setIsOpen] = useState(false);
    const toggleFilter = () => setIsOpen(!isOpen);

    const filterFormState = { ...DEFAULT_FILTER_FORM_STATE };

    const onCloseDrawer = () => {
        setPanelActive(false);
        props.onClose();
    };

    const onSelectionChange = ({ value }: any) => {
        setSelectedRow(value);
    };

    const onClose = (toBeAdded: boolean = false) => {
        if ( toBeAdded ) {
            props.onClose(selectedRow);
        } else {
            props.onClose();
        }

        setPanelActive(false);
    };

    const findMaxDate = (datesArray: any) => {
        // Parse date strings using Moment.js
        const dateObjects = datesArray.map((dateString: any) => moment(dateString));

        return maxBy(dateObjects, (date: any) => date.valueOf());
    }

    useEffect(() => {
        if ( props.state ) {
            setData([]);
            setPanelActive(true);
            setTimeout(() => setSelectedRow(null), 700)
        }

    }, [props]);

    // @ts-ignore
    useEffect(() => {
        if ( isPanelActive ) {
            request({
                url: `/suppliers/invoices/ready/to/invoice`,
            })
            .then((response: any) => {
                if ( !isEmpty(response.data.data) ) {
                    const vins: any = [];
                    const clients: any = [];
                    const routes: any = [];
                    const loadNumbers: any = [];
                    const grouped: any = map(
                        groupBy(response.data.data, 'group_token'),
                        (group: any, key: any) => {
                            let route_title: string = '';
                            let route_title_display: string = '';
                            let total: number = 0;

                            if ( group[0].price_type === 'per_load' ) {
                                if(group[0].per_load_merge_route !== true) {
                                    map(
                                    uniqBy(group, (item: any) => {
                                        const actualLoading = item.operational_loading_time ? item.operational_loading_time : "";
                                        const actualUnloading = item.operational_unloading_time ? item.operational_unloading_time : "";
                                        let unique = item.route_title;

                                        if ( !isEmpty(actualLoading) && !isEmpty(actualUnloading) ) {
                                            unique += "-" + utcDateToLocalDate(actualLoading, "DD.MM.YYYY");
                                            unique += "-" + utcDateToLocalDate(actualUnloading, "DD.MM.YYYY");
                                        }

                                        return unique;
                                    })
                                    , (route: any, subKey: any) => {
                                        const actualLoading = route.operational_loading_time ? route.operational_loading_time : "";
                                        const actualUnloading = route.operational_unloading_time ? route.operational_unloading_time : "";;
                                        route_title_display += route.route_title;

                                        if ( !isEmpty(actualLoading) && !isEmpty(actualUnloading) ) {
                                            route_title_display += " (" + utcDateToLocalDate(actualLoading, "DD.MM.YYYY");
                                            route_title_display += " - " + utcDateToLocalDate(actualUnloading, "DD.MM.YYYY")+ ")";
                                        } else {
                                            if ( !isEmpty(route.estimate_loading_time) ) {
                                                route_title_display += " (" + utcDateToLocalDate(route.estimate_loading_time, "DD.MM.YYYY");
                                            }
                                            if ( !isEmpty(route.estimate_unloading_time) ) {
                                                route_title_display += " - " + utcDateToLocalDate(route.estimate_unloading_time, "DD.MM.YYYY") + ")";
                                            }

                                            if ( !isEmpty(route.estimate_loading_time) ) {
                                                route_title_display += ")";
                                            }
                                        }

                                        route_title_display += '\n';
                                    });
                                    route_title = route_title_display;
                                } else {
                                    const _group = sortBy(group, 'group_index');
                                    const firstR = get(head(_group), ['from_city']);
                                    const lastR = get(last(_group), ['to_city']);
                                    const route = _group[0];

                                    const operationalLoading = route.operational_loading_time ? route.operational_loading_time : "";
                                    const operationalUnloading = last(_group).operational_unloading_time;
                                    route_title = `${firstR} ${lastR}`;

                                    if ( !isEmpty(operationalLoading) ) { // override
                                        route_title += " (" + utcDateToLocalDate(operationalLoading, "DD.MM.YYYY");
                                    } else if ( !isEmpty(route.estimate_loading_time) ) {
                                        route_title += " (" + utcDateToLocalDate(route.estimate_loading_time, "DD.MM.YYYY");
                                    }

                                    if ( !isEmpty(operationalUnloading) ) {
                                        route_title += " - " + utcDateToLocalDate(operationalUnloading, "DD.MM.YYYY") + ")";
                                    } else {
                                        route_title += ")";
                                    }
                                }

                                const _group = sortBy(group, 'created_at');
                                total = _group[0].load_price;
                                key = _group[0].load_id;
                            } else {
                                const actualLoading = group[0].operational_loading_time ? group[0].operational_loading_time : "";
                                const actualUnloading = group[0].operational_unloading_time ? group[0].operational_unloading_time : "";;
                                route_title = group[0].route_title;

                                if ( !isEmpty(actualLoading) && !isEmpty(actualUnloading) ) {
                                    route_title += " (" + utcDateToLocalDate(actualLoading, "DD.MM.YYYY");
                                    route_title += " - " + utcDateToLocalDate(actualUnloading, "DD.MM.YYYY")+ ")";

                                    if ( group[0].route_price_type == 3 ) {
                                        route_title_display = route_title;
                                        route_title_display += " KM: " + group[0].total_km;
                                        route_title_display += " KMPrice: " + group[0].input_price;
                                    }
                                } else {
                                    if ( !isEmpty(group[0].estimate_loading_time) ) {
                                        route_title += " (" + utcDateToLocalDate(group[0].estimate_loading_time, "DD.MM.YYYY");
                                    }
                                    if ( !isEmpty(group[0].estimate_unloading_time) ) {
                                        route_title += " - " + utcDateToLocalDate(actualUnloading, "DD.MM.YYYY") + ")";
                                    } else {
                                        route_title += ")";
                                    }
                                }

                                map(group, (item: any) => {
                                    total += Number(item.total);
                                });
                            }

                            map(group, ({ vin_number, client_name, route_title, load_number }: any) => {
                                if ( !find(vins, { id: vin_number }) && !isEmpty(client_name) ) {
                                    vins.push({ id: vin_number, label: vin_number });
                                }

                                if ( !find(clients, { id: client_name }) && !isEmpty(client_name)) {
                                    clients.push({ id: client_name, label: client_name });
                                }

                                if ( !find(routes, { id: route_title }) && !isEmpty(route_title)) {
                                    routes.push({ id: route_title, label: route_title });
                                }

                                if ( !find(loadNumbers, { id: load_number }) && !isEmpty(load_number)) {
                                    loadNumbers.push({ id: load_number, label: load_number });
                                }
                            })

                            let title = (group[0].per_load_merge_route == true) ? route_title : route_title_display;

                            if ( isEmpty(title) ) {
                                title = route_title;
                            }

                            const sorted = sortBy(group, 'created_at');

                            return {
                                items: group,
                                group_token: key,
                                group_type: group[0].load_type,
                                id: key,
                                price_type: group[0].price_type,
                                route_title: route_title,
                                route_title_display: title,
                                load_number: group[0].load_number,
                                invoiceable_at: group[0].invoiceable_at,
                                ldg_nr: uniq(map(group, ({ldg_nr }: any) => (!isEmpty(ldg_nr) ? ldg_nr : "") )).join(', '),
                                vin_number: map(group, ({ vin_number, ldg_nr }: any) => `${vin_number}` + (!isEmpty(ldg_nr) ? ` - ${ldg_nr}` : "") ).join(', '),
                                brand: group[0].brand,
                                model: group[0].model,
                                total: Number(total).toFixed(2),
                                from_addresses: uniq(map(sorted, (i) => `${i.from_street_line_1} ${i.from_zip} ${i.from_city}`)),
                                to_addresses: uniq(map(sorted, (i) => `${i.to_street_line_1} ${i.to_zip} ${i.to_city}`)),
                            }
                        }
                    );

                    setDropdowns({
                        clients,
                        vins,
                        routes,
                        loadNumbers
                    })

                    setData(grouped);
                    setFilteredData(grouped);
                    setTimeout(() => setSelectedRow([]), 700);
                }
            })
            .catch((e: any) => {
                toastify(t("server error"), { type: "error" });
            });
        } else {
            setData([]);
        }

    }, [isPanelActive]);

    const onFilter = (values: any) => {
        let filtered: any = data;

        if ( !isEmpty(values.clients) ) {
            filtered = filtered.filter((item: any) => some(item.items, (subItem: any) => values.clients.includes(subItem.client_name)));
        }

        if ( !isEmpty(values.routes) ) {
            filtered = filtered.filter((item: any) => some(item.items, (subItem: any) => values.routes.includes(subItem.route_title)));
        }

        if ( !isEmpty(values.vins) ) {
            filtered = filtered.filter((item: any) => some(item.items, (subItem: any) => values.vins.includes(subItem.vin_number)));
        }

        if ( !isEmpty(values.loadNumbers) ) {
            filtered = filtered.filter((item: any) => includes(values.loadNumbers, item.load_number));
        }

        if ( !isEmpty(values.fromInvoiceableDate) ) {
            filtered = filtered.filter((item: any) => {
                return moment(item.invoiceable_at).isSameOrAfter(moment(values.fromInvoiceableDate).tz(moment.tz.guess()).utc() );
            });
        }

        if ( !isEmpty(values.toInvoiceableDate) ) {
            filtered = filtered.filter((item: any) => moment(item.invoiceable_at).isSameOrBefore(moment(values.toInvoiceableDate).tz(moment.tz.guess()).utc() ));
        }

        setFilteredData(filtered)
    }

    const onFilterReset = (props: any) => {
        props.resetForm();
        setFilteredData(data);
    }

    return (
        <>
            <div className={ "border" }>
                <Sidebar
                    visible={ isPanelActive } onHide={ onCloseDrawer } blockScroll={ true }
                    fullScreen
                >
                    <Card className={ "border-0 p-0" }>
                        <CardHeader className={"mb-2"}>
                            <Row>
                                <Col sm={12} md={6} lg={6}>
                                    <h4 className="card-title">{ t("billable items") }</h4>
                                </Col>
                                <Col sm={12} md={6} lg={6} className={"text-end"}>
                                    <Button type="button" severity={undefined} outlined size={"small"}
                                            onClick={ () => onClose(true) }
                                            className="me-2">
                                        <i className="fas fa-save me-1" /> { t("let's add") }
                                    </Button>
                                    <Button type="button" severity={"danger"} outlined size={"small"}
                                            onClick={ () => onClose(false) }
                                            title={ t("operation cancel") }
                                            className="me-2">
                                        <i className="fas fa-angle-left me-1" /> { t("cancel") }
                                    </Button>
                                    <Button type="button" severity={"info"} outlined size={"small"}
                                            onClick={ toggleFilter  }
                                            title={ t("filter") }>
                                        <i className="fas fa-filter me-1" /> { t("filter") }
                                    </Button>
                                </Col>
                            </Row>
                            <Row className={ `${!isOpen ? 'd-none' : ''}` }>
                                <Divider className={"mt-2"} />

                                <Formik
                                    initialValues={ filterFormState }
                                    onSubmit={ onFilter }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Row>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="routes"
                                                               options={ dropdowns.routes }
                                                               label={ t('routes') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="clients"
                                                               options={ dropdowns.clients }
                                                               label={ t('clients') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="vins"
                                                               options={ dropdowns.vins }
                                                               label={ t('vin(s)') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="loadNumbers"
                                                               options={ dropdowns.loadNumbers }
                                                               label={ t('load number(s)') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputDateTime }
                                                               name="fromInvoiceableDate"
                                                               type={ "datetime" }
                                                               label={ t("from invoiceable date") }
                                                               isClearable={ true }
                                                               enableTimeStartOfDay
                                                               rounded
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputDateTime }
                                                               name="toInvoiceableDate"
                                                               type={ "datetime" }
                                                               label={ t("to invoiceable date") }
                                                               isClearable={ true }
                                                               enableTimeEndOfDay
                                                               rounded
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3} className={"md:mt-4 sm:mt-0"}>
                                                        <Button type="submit" severity={undefined} size={"small"} outlined>
                                                            <i className="fas fa-save me-1" /> { t('filter') }
                                                        </Button>
                                                        <Button type="button" severity={"danger"} size={"small"}
                                                                onClick={ () => onFilterReset(props) } outlined
                                                                className="ms-2">
                                                            <i className="fas fa-cut me-1" /> { t('reset') }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Row>
                        </CardHeader>
                        <Divider />
                        <CardBody style={{border: '1px solid var(surface-50)'}}>
                            {
                                isEmpty(filteredData) && <span>{ t('no data') }</span>
                            }
                            {
                                !isEmpty(filteredData) &&
                              <>
                                  <DataTable
                                    value={ filteredData } dataKey="id"
                                    reorderableColumns size={"small"}
                                    selectionMode="multiple"
                                    selection={ selectedRow }
                                    onSelectionChange={ onSelectionChange }
                                    dragSelection
                                    resizableColumns showGridlines stateStorage="session" stateKey="dt-invoiceable-list"
                                     scrollable scrollHeight="750px" virtualScrollerOptions={{ itemSize: 50 }}>
                                      <Column selectionMode="multiple" headerStyle={{ width: '2rem' }} />
                                      <Column field="load_number" header={t('load number')}/>
                                      <Column field="ldg_nr" header={t('ldg nr')} />
                                      <Column field="client_name" header={t('client')}
                                              body={ (data: any) => {
                                                  return (
                                                      uniq(data.items
                                                          .filter(({client_name}: any) => !isEmpty(client_name))
                                                          .map(({ client_name }: any) => `${client_name}`)
                                                      )
                                                      .map((i: any, index: number) => (<div key={index}>{ i }</div>)));
                                              } }/>

                                      <Column field="from_addresses" header={t('from address')}
                                              body={ (data: any) => {
                                                  if ( data.items[0].per_load_merge_route ) {
                                                      return (<div>{ data.from_addresses[0] }</div>);
                                                  }

                                                  return data.from_addresses.map((i: any, index: number) => (<div key={index}>{ i }</div>));
                                              } }/>

                                      <Column field="to_addresses" header={t('to address')}
                                              body={ (data: any) => {
                                                  return data.to_addresses.map((i: any, index: number) => (<div key={index}>{ i }</div>));
                                              } }/>

                                      <Column field="route_title_display" header={t('route')}
                                              body={ (data: any) => {
                                                  return (
                                                      data.route_title_display.split("\n").map((line: any, index: number) => (
                                                          <div key={ index }>{ line }</div>
                                                      ))
                                                  );
                                              } }/>

                                      <Column field="vin_number" header={t('vin(s)')}
                                              body={ (data: any) => {
                                                  return (
                                                      uniq((get(data, ['items']) ?? []).map(({ vin_number, ldg_nr, input_price, total }: any) => `${vin_number}` + (!isEmpty(ldg_nr) ? ` - ${ldg_nr}` : "") + ((!isEmpty(total) && data.group_type === 'SpotLoadRoutePrice') ? ` - ${total}` : "") + ((!isEmpty(input_price) && data.price_type === "unit_price")  ? ` - ${input_price}` : ""))).map((i: any, index: number) => (<div key={index}>{ i }</div>))
                                                  );
                                              } }/>

                                      <Column field="brand" header={t('brand')}
                                              body={ (data: any) => {
                                                  return (
                                                      <div className={"text-capitalize"}>
                                                          { uniq(( get(data, ['items']) ?? [] ).map(({ brand }: any) => brand)).map((i: any, index: number) => (<div key={index}>{ i }</div>)) }
                                                      </div>
                                                  );
                                              } }/>

                                      <Column field="model" header={t('model')}
                                              body={ (data: any) => {
                                                  return (
                                                      <div className={"text-capitalize"}>
                                                          { uniq(( get(data, ['items']) ?? [] ).map(({ model }: any) => model)).map((i: any, index: number) => (<div key={index}>{ i }</div>)) }
                                                      </div>
                                                  );
                                              } }/>

                                      <Column field="invoiceable_at" header={t('invoiceable at')}
                                              body={ (data: any) => {
                                                  return <>
                                                      { ( data.invoiceable_at ) &&
                                                        <span>{ utcDateToLocalDate(data.invoiceable_at) }</span> }
                                                  </>;
                                              } }/>

                                      <Column field="total" header={t('total')} />
                                  </DataTable>
                              </>
                            }
                        </CardBody>
                    </Card>
                </Sidebar>
            </div>
        </>
    );
}

export default withTranslation()(InvoiceableList);
