import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "./Tooltip";
import { Badge } from 'primereact/badge';

interface IProps {
    type?: string;
    vinNumber: string;
    priorisation?: number | string;
    builtToOrderCd?: number | string;
}

const PriorityAvatar = (props: any) => {
    const { priorisation, builtToOrderCd } = props;
    const { t } = useTranslation();

    if ( priorisation && priorisation > 0 ) {
        return (
            <span className={ "text-uppercase" }>
                <Tooltip tooltip={ ( builtToOrderCd > 0 ) ? t('superHigh') : t('high') }>
                  <Badge
                      value={ ( builtToOrderCd > 0 ) ? 'S' : 'H' }
                      severity="info" />
                </Tooltip>
            </span>
        );
    }

    if ( builtToOrderCd && builtToOrderCd > 0 ) {
        return (
            <span className={ "text-uppercase" }>
            <Tooltip tooltip={ t('superHigh') }>
                <Badge
                    value={ 'S' }
                    severity="warning" />
            </Tooltip>
          </span>
        );
    }

    return <></>;
};

function VinWithType(props: IProps) {
    const { type, vinNumber } = props;

    return (
        <span>
      {
          !type &&
          <>{ vinNumber }</>
      }
            {
                type === 'renault' &&
                <>
                    <Tooltip tooltip={ 'Renault' }>
                        <Badge
                            value={ <>
                                <PriorityAvatar { ...props } />
                                { vinNumber }
                            </> }
                            severity="warning" />
                    </Tooltip>
                </>
            }
            {
                type === 'nissan' &&
                <>
                    <Tooltip tooltip={ 'Nissan' }>
                        <Badge
                            value={ <>
                                <PriorityAvatar { ...props } />
                                { vinNumber }
                            </> }
                            severity="info" />
                    </Tooltip>
                </>
            }
            {
                type === 'alp' &&
                <>
                    <Tooltip tooltip={ 'Emil Frey' }>
                        <Badge
                            value={ <>
                                <PriorityAvatar { ...props } />
                                { vinNumber }
                            </> }
                            severity="info" />
                    </Tooltip>
                </>
            }
            {
                type === 'system' &&
                <>
                    <Tooltip tooltip={ 'System' }>
                        <Badge
                            value={ <>
                                <PriorityAvatar { ...props } />
                                { vinNumber }
                            </> }
                            severity="success" />
                    </Tooltip>
                </>
            }
    </span>
    );
};

export default VinWithType;
