import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field, FieldArray } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "react-bootstrap";
import { find, findIndex, flatten, get, has, head, includes, isEmpty, map, sumBy, toNumber, uniq } from "lodash";
import moment from "moment-timezone";
import { POINT_DECIMAL, random, STORE_POINT_DECIMAL, numberFormat } from "../../../../utils/Helper";
import { DynamicObject } from "../../../../../../admin/src/helpers/commonTypes";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputChip } from "../../../../components/Shared/InputChip";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { Button } from "primereact/button";
import { InputField } from "../../../../components/Shared/InputField";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { confirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import InvoiceableList from "./InvoiceableList";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: null | any,
    receiver_id: null | any,
    status: null | any,
    recipient_type: null | any,
    service_fee: null | any,
    currency: null | any,
    email: any,
    bank_account_number: null | any,
    footer_description: null | any,
    header_description: null | any,
    finalize_date: null | any,
    items: null | any,
    legal_entity_id: null | any,
    discount_code_id: null | any,
    pre_payment_total: null | any,
    is_vatable: null | any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        status: yup.string().required(t('the field is required.', { field: t('status') })).nullable(),
        receiver_id: yup.string().required(t('the field is required.', { field: t('recipient') })).nullable(),
        recipient_type: yup.string().required(t('the field is required.', { field: t('recipient type') })).nullable(),
        bank_account_number: yup.string().trim(t('the field is invalid.')).nullable(), // .required(t('the field is required.', { field: t('bank account number') }))
        finalize_date: yup.string().required(t('the field is required.', { field: t('invoice_date') })).nullable(),
        email: yup.array().nullable(), // .min(1, t('the field is required.', { field: t('email') }))
        items: yup.array()
        .of(
            yup.object().shape({
                title: yup.string().required(t('the field is required.', { field: t('title') })).nullable(),
                price: yup.number().min(0, t("the field value is not valid", { field: t('price') })).required(t('the field is required.', { field: t('title') })).nullable(),
                qty: yup.number().min(0, t("the field value is not valid", { field: t('qty') })).required(t('the field is required.', { field: t('qty') })).nullable(),
                discount: yup.number().min(0, t("the field value is not valid", { field: t('discount') }))
                .test('is-less-than-total', t('the field must be less than',{field: t('discount'), comparableField: t('total')}), (value: any, { parent }: any) => {
                    if ( parent.qty <= 0 || parent.price <= 0 || value <= 0  ) {
                        return true;
                    }

                    if ( value > ( parent.price * parent.qty ) ) {
                        return false;
                    }

                    return true;
                })
                .nullable(),
                discount_percentage: yup.number().min(0, t("the field value is not valid", { field: t('discount') })).nullable(),
                discount_type: yup.string().required(t("the field value is not valid", { field: t('type') })).nullable(),
            })
        )
        .min(1, t('the field is required.', { field: t('item') }))
        .required(t('the field is required.', { field: t('item') })),
        legal_entity_id: yup.string().nullable(),
        discount_code_id: yup.string().nullable(),
        // email: yup.array().min(1),
    });
};

const DEFAULT_ITEM_FIELDS = {
    id: null,
    token: null,
    title: "",
    price: 0,
    qty: 1,
    total: 0,
    is_active_service_fee: true,
    discount: 0,
    service_fee: 0,
    discount_type: "type_value",
    vat: 0,
    vat_total: 0,
    service_fee_amount: 0,
    group_type: null, // SpotLoad, SpotLoadOrder, AlpSpotLoad
    is_vatable: true,
    description: null,
    group_token: null,
    price_type: null, // per_load, per_route
};

const DEFAULT_FORM_STATE = {
    id: null,
    receiver_id: null,
    recipient_type: null,
    status: 'draft',
    bank_account_number: null,
    service_fee: 0,
    footer_description: null,
    email: [],
    currency: 'EUR',
    header_description: null,
    finalize_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    is_vatable: true,
    pre_payment_total: 0,
    items: [{
        id: null,
        token: random(15),
        title: "Item 1",
        price: 0,
        qty: 1,
        total: 0,
        is_active_service_fee: true,
        discount: 0,
        discount_percentage: 0,
        discount_type: "type_value",
        group_type: null,
        price_type: null,
        vat: 0,
        vat_total: 0,
        service_fee_amount: 0,
        description: null,
        is_vatable: true,
        group_token: null,
    }],
    legal_entity_id: null,
    discount_code_id: null,
};

const ITEMS_ERROR_FIELDS = [
    'price',
    'qty',
    'title',
];

const CustomInputComponent = ({ field, meta, ...rest }: any) => {
    return (
        <>
            <InputText
                { ...field }
                { ...rest }
                size={"10"}
            />
        </>
    );
};

const CustomInputTextAreaComponent = ({ field, meta, ...rest }: any) => {
    return (
        <>
            <InputTextarea
                { ...field }
                { ...rest }
                size={"10"}
            />
        </>
    );
};

const DEFAULT_DROPDOWNS = {
    clients: [],
    recipientTypes: [],
    bankAccounts: [],
    currencies: [],
    statuses: [],
    vat: [],
    legalEntities: [],
    discountCodes: []
};

function InvoiceForm(props: IFormProps) {
    const { t, toastify, params, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [isInvoiceablePanelActive, setInvoiceablePanelActive] = useState<boolean>(false);
    const [vat, setVat] = useState<number>(0);
    const [isPanelActive, setPanelActive] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>(null);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);

    const getDropdowns = async () => {
        setInitFormState({ ...DEFAULT_FORM_STATE, finalize_date: moment().format('YYYY-MM-DD HH:mm:ss') });

        try {
            const response = await request({
                url: '/suppliers/invoices/create',
                params: {
                    type: 'create',
                    sub_type: 'suppliers'
                }
            });

            const { data } = response.data;
            let bankAccounts: any = [];
            map(data.legal_entities, (item: DynamicObject) => item.bank_accounts.map((bank: any) => bankAccounts.push({ ...bank, label: bank.account_number })));

            setDropdowns({
                clients: data.clients.map((i: any) => ({...i, label: i.name})),
                currencies: data.currencies,
                recipientTypes: data.recipient_types,
                statuses: data.statuses,
                legalEntities: data.legal_entities,
                bankAccounts: bankAccounts,
                discountCodes: data.discount_codes,
            });

            setVat(Number(data.vat));

            if ( includes(['edit'], get(params, ['operation'])) ) {
                await onEdit(params.operationId);
            } else {
                setInitFormState({
                    ...DEFAULT_FORM_STATE,
                    items: [{
                        id: null,
                        token: random(15),
                        title: "Item 1",
                        price: 0,
                        qty: 1,
                        total: 0,
                        is_active_service_fee: true,
                        discount: 0,
                        discount_percentage: 0,
                        discount_type: "type_value",
                        group_type: null,
                        price_type: null,
                        vat: 0,
                        vat_total: 0,
                        service_fee_amount: 0,
                        description: null,
                        group_token: null,
                        is_vatable: true
                    }],
                    finalize_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    // @ts-ignore
                    bank_account_number: get(head(data.bank_accounts), ['id'])
                });
                setPanelActive(true);
            }
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/invoices` : `/suppliers/invoices/${ payloads.id }` ),
                data: {
                    ...payloads,
                    email: payloads.email ? payloads.email.map((item: any) => item.value) : []
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('invoice') }), "success");
            } else {
                toastify(t("record added", { item: t('invoice') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setInitFormState({...DEFAULT_FORM_STATE});
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/invoices/${ id }/edit`
            });

            const { data } = response.data;
            setEntity(data);

            const formData: TInputForm = {
                id: data.id,
                status: data.status,
                receiver_id: get(data, ['client', 'id']),
                recipient_type: get(data, ['recipient_type']),
                currency: get(data, ['currency']),
                service_fee: get(data, ['service_fee']),
                email: data.email ? data.email.split(',').map((item: any) => ( { label: item, value: item } )) : null,
                footer_description: get(data, ['footer_description']),
                header_description: get(data, ['header_description']),
                finalize_date: data.finalize_date,
                legal_entity_id: get(data, ['legal_entity', 'id']),
                discount_code_id: get(data, ['discount_code', 'id']),
                bank_account_number: get(data, ['bank_account_number']),
                is_vatable:  get(data, ['is_vatable']),
                pre_payment_total:  get(data, ['pre_payment_total']),
                items: data.invoice_items.map((item: any) => ( {
                    id: item.id,
                    token: item.token,
                    title: item.title,
                    price: Number(item.price),
                    qty: Number(item.qty),
                    total: Number(item.total),
                    is_active_service_fee: item.is_active_service_fee,
                    discount: (item.discount_type === "type_value") ? item.discount : 0,
                    discount_percentage: (item.discount_type === "type_percentage") ? item.discount_percentage : 0,
                    vat: item.vat,
                    vat_total: item.vat_total,
                    service_fee_amount: item.service_fee_amount,
                    description: item.description,
                    group_token: item.group_token,
                    group_type: item.group_type,
                    price_type: item.price_type,
                    discount_type: item.discount_type,
                    is_vatable: item.is_vatable,
                } ))
            };
            setInitFormState(formData);
            setPanelActive(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const addItem = (arrayHelpers: any) => {
        const base: any = get(formRef.current, ['values']);
        const newItem = { ...DEFAULT_ITEM_FIELDS, title: `Item ${ base.items.length + 1 }`, token: random(15), is_vatable: base.is_vatable };
        const items: any = map(base.items, (item: any) => {
            return {
                ...item,
                price: Number(item.price).toFixed(POINT_DECIMAL),
                total: Number(item.total).toFixed(POINT_DECIMAL),
                discount: Number(item.discount).toFixed(POINT_DECIMAL),
            }
        });

        setInitFormState({ ...base, items: [...items, newItem] });
    };

    const onDeleteItem = (row: any, index: number, arrayHelpers: any) => {
        const base: any = get(formRef.current, ['values']);
        const shallowItems = base.items.filter(({ token }: any) => token !== row.token);
        arrayHelpers.remove(index);
        setInitFormState({ ...base, items: shallowItems });
    };

    const onChangePrice = (payloads: any, index: number, arrayHelpers?: any, isVatable?: any) => {
        const base: any = get(formRef.current, ['values']);
        const invoiceItem = base.items[index];
        const row = invoiceItem;
        invoiceItem.vat_total = 0;
        invoiceItem.total = 0;
        const price = get(row, ['price']) ?? 0;
        const qty = get(row, ['qty']) ?? 0;

        let basePrice: any = ( parseFloat(Number(price).toFixed(POINT_DECIMAL)) * parseFloat(qty) );
        if ( payloads ) {
            invoiceItem[payloads.key] = payloads.value;
        }

        if ( price > 0 && qty > 0 ) {
            let total: any = ( parseFloat(Number(price).toFixed(POINT_DECIMAL)) * parseFloat(qty) );
            const serviceFee: any = ( base.service_fee > 0 && row.is_active_service_fee > 0 ) ? ( ( total * base.service_fee ) / 100 ) : 0;
            const totalWithServiceFee = parseFloat(total) + parseFloat(serviceFee);
            // const discount :any = (!isEmpty(base.discount_code_id) && invoiceItem.discount > 0) ? toNumber(invoiceItem.discount) : 0;
            // total = ( totalWithServiceFee - discount ).toFixed(POINT_DECIMAL);
            invoiceItem.service_fee = toNumber(base.service_fee);
            invoiceItem.service_fee_amount = ( row.is_active_service_fee > 0 ) ? toNumber(serviceFee.toFixed(STORE_POINT_DECIMAL)) : 0;
            total = totalWithServiceFee;
            if ( !isEmpty(base.discount_code_id) ) {
                if ( row.discount_type === "type_value" && row.discount > 0 ) {
                    total = ( totalWithServiceFee - row.discount ).toFixed(POINT_DECIMAL);
                } else if ( row.discount_type === "type_percentage" && row.discount_percentage > 0 ) {
                    const discount = ((totalWithServiceFee * row.discount_percentage) / 100);
                    total = (totalWithServiceFee - discount).toFixed(POINT_DECIMAL);
                }
            }

            invoiceItem.total = toNumber(( parseFloat(total) ).toFixed(STORE_POINT_DECIMAL));

            if ( base.recipient_type !== 'ino' ) { // without vat
                invoiceItem.vat = 0;
                invoiceItem.vat_total = 0;
            }

            if ( base.recipient_type === 'ino' ) { // with vat
                //( invoiceItem.vat && invoiceItem.vat > 0 ) ? invoiceItem.vat : get(dropdowns, ['vat', 'value']);
                const rowVat = (typeof isVatable !== "undefined" && isVatable == false) ? 0 : ((typeof isVatable !== "undefined" && isVatable !== false) ? vat : ((base.is_vatable === true) ? vat : 0));

                if ( rowVat >= 0 && rowVat <= 100 ) {
                    invoiceItem.vat = rowVat;
                    let vat_total: any = toNumber(( (  parseFloat(total) * parseFloat(invoiceItem.vat) ) / 100 ).toFixed(STORE_POINT_DECIMAL));
                    invoiceItem.vat_total = vat_total;
                    invoiceItem.total = toNumber(( parseFloat(total) + parseFloat(vat_total) ).toFixed(STORE_POINT_DECIMAL));
                    invoiceItem.is_vatable = base.is_vatable;
                }
            }
        }

        if ( !isEmpty(base.discount_code_id) && invoiceItem.discount > 0 && (parseFloat(invoiceItem.discount) > parseFloat(basePrice)) ) {
            invoiceItem.total = 0;
        }

        if ( !isEmpty(arrayHelpers) ) {
            arrayHelpers.replace(invoiceItem, index);
        }

        invoiceItem.price = ( (invoiceItem.price > 0 ) ? ( Number(invoiceItem.price) ).toFixed(POINT_DECIMAL) : 0 );
        invoiceItem.discount = ( ( !isEmpty(base.discount_code_id) && invoiceItem.discount > 0 ) ? Number(invoiceItem.discount).toFixed(POINT_DECIMAL) : 0 );
        return invoiceItem;
    };

    const onChangeClient = (payloads: any, setFieldValue: any) => {
        if ( isEmpty(payloads) || isEmpty(payloads.value) ) {
            return;
        }

        const client = find(dropdowns.clients, { id: payloads.value });

        setFieldValue('service_fee', client.invoice_service_fee);
        setFieldValue('email', client.invoice_email_addresses
            ? client.invoice_email_addresses.split(',').map((item: any) => ( item ))
            : []
        );

        if ( !isEmpty(client.legal_entity) ) {
            setFieldValue('legal_entity_id', client.legal_entity.id);
        }

        onChangeRecipientType({
            value: client.recipient_type
        }, setFieldValue);
    };

    const onChangeRecipientType = (payloads: any, setFieldValue: any) => {
        setFieldValue('recipient_type', get(payloads, ['value']));

        if ( isEmpty(payloads) || isEmpty(payloads.value) ) {
            return;
        }

        setTimeout(() => {
            const base: any = get(formRef.current, ['values']);
            const shallowItems = base.items.map((item: any, index: any) => onChangePrice(undefined, index));
            setFieldValue('items', shallowItems);
        }, 500);
    };

    const onBlurServiceFee = ($event: any, setFieldValue: any) => {
        const base: any = get(formRef.current, ['values']);
        const items = base.items.map((_: any, index: number) => onChangePrice(undefined, index));
        setFieldValue('items', items);
    };

    const onSelectDiscountCode = (item: any, setFieldValue: any) => {
        const base: any = get(formRef.current, ['values']);
        let items = [];

        if ( !isEmpty(item) && !isEmpty(String(item.value)) ) {
            items = base.items.map((_: any, index: number) => onChangePrice(undefined, index));
        } else {
            items = base.items.map((_: any, index: number) => onChangePrice({
                key: 'discount',
                value: 0
            }, index));
        }

        setFieldValue('items', items);
    };

    const onChangeIsVat = (value: any, setFieldValue: any) => {
        const base: any = formRef.current;
        const items = base.values.items.map((_: any, index: number) => onChangePrice(undefined, index, undefined, value));
        setFieldValue('items', items);
    };

    const onClickInvoicable = () => setInvoiceablePanelActive(true);

    const onCloseInvoicable = (items: any[] = []) => {
        setInvoiceablePanelActive(false);
        if ( items ) {
            let base: any = get(formRef.current, ['values']);
            const toBeAddedNew = items.filter((item: any) => findIndex(base.items, { group_token: item.group_token }) < 0);
            const newItems: any = base.items;
            let clients: any[] = [];
            let client: any = null;
            map(toBeAddedNew, (item: any) => {
                const lgd_numbers: any = uniq(item.items.map(({ ldg_nr }: any) => ldg_nr)).join(', ');
                const vin_numbers: any = uniq(item.items.map(({ vin_number }: any) => vin_number)).join(', ');
                const allClients: any = item.items.map(({ client_name }: any) => client_name);
                clients.push(allClients);
                let title: string = "";

                if(!isEmpty(lgd_numbers)) {
                    title = `${t("ldg nr")} ${lgd_numbers} \n`
                }

                title = `${title}${ item.load_number }# ${ item.route_title } \n ${vin_numbers}`;
                const newItem = {
                    ...DEFAULT_ITEM_FIELDS,
                    title: title,
                    token: random(15),
                    price: parseFloat(item.total).toFixed(2),
                    group_token: item.group_token,
                    price_type: item.price_type,
                    group_type: item.group_type,
                };

                if ( item.price_type === "per_route" && get(item, ['items', '0', 'route_price_type']) == 3 ) {
                    newItem.qty = get(item, ['items', '0', 'total_km']) ?? 1;
                    if ( newItem.qty > 0 ) {
                        newItem.price = get(item, ['items', '0', 'input_price']) ?? 0;
                    }
                }

                newItems.push(newItem);
            });

            if ( uniq(flatten(clients)).length == 1 && isEmpty(base.receiver_id)) {
                client = find(dropdowns.clients, { name: uniq(flatten(clients))[0] });
                base.receiver_id = client.id;
            }

            setInitFormState({ ...base, items: newItems });

            setTimeout(() => {
                let base: any = get(formRef.current, ['values']);

                const shallowItems = base.items.map((item: any, index: any) => onChangePrice(undefined, index));
                setInitFormState({ ...base, items: shallowItems });

                if ( !isEmpty(client) ) {
                    setTimeout(() => {
                        onChangeClient({ value: client.id }, formRef.current.setFieldValue);
                    }, 1000)
                }

                focusOnService();
            }, 1000);

        }
    };

    const focusOnService = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="service_fee"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    useEffect(() => {
        async function init() {
            await getDropdowns();
        }

        if ( includes(['add', 'edit'], get(params, ['operation'])) ) {
            init();
        }
    }, [params]);

    if ( !isPanelActive ) {
        return <>
            Loading...
        </>;
    }

    return (
        <>
            <div>
                <Formik
                    innerRef={ formRef }
                    enableReinitialize={ true }
                    initialValues={ initFormState }
                    onSubmit={ onSubmit }
                    validationSchema={ validationSchema(t) }
                >
                    { (props: FormikProps<TInputForm>) => {
                        const { values, errors, setFieldValue }: any = props;

                        return (
                            <Form onSubmit={ props.handleSubmit }>
                                <Row>
                                    <Col sm={ 12 } md={ 4 } lg={ 3 }>
                                        <Card style={ { minHeight: '600px'} }>
                                            <CardHeader className="pb-2">
                                                <h5>
                                                    { !entity && <span className={ "me-1" }>{ t('create') }</span> }
                                                    { entity && <span className={ "me-1" }>{ t('update') }</span> }
                                                    { t('invoice') } { entity &&
                                                  <span>#{ entity.draft_invoice_number }</span> }
                                                </h5>
                                            </CardHeader>
                                            <CardBody>
                                                <div className={ "row" }>
                                                    <Field component={ InputSingleSelectField }
                                                           name="receiver_id"
                                                           options={ dropdowns?.clients }
                                                           label={ t('client') }
                                                           panelClassName={ "hide-p-toggler" }
                                                           placeholder={ t("pick") + ' ' + t("client").toLowerCase() }
                                                           onChange={ (value: any) => onChangeClient(value, setFieldValue) }
                                                           autoFocus={ true }
                                                           itemTemplate={(option: any) => {
                                                               return (
                                                                   <div>
                                                                       { option.name }
                                                                       <strong className={ (option.available > 0) ? "text-green-500 ps-1" : "text-red-500 ps-1" }>
                                                                           { option.available ? Number(option.available) : '' }
                                                                       </strong>
                                                                   </div>
                                                               )
                                                           }}
                                                           valueTemplate={(option: any) => {
                                                               return (
                                                                   option ?
                                                                       <div>
                                                                           { option.name }
                                                                           <strong
                                                                               className={ ( option.available > 0 ) ? "text-green-500 ps-1" : "text-red-500 ps-1" }>
                                                                                [{ Number(option.available) }]
                                                                            </strong>
                                                                       </div>
                                                                       :
                                                                       <span >{ t("pick") + ' ' + t("client").toLowerCase() }</span>
                                                               )
                                                           } }
                                                           isRequired
                                                    />

                                                    <Field component={ InputSingleSelectField }
                                                           name="recipient_type"
                                                           options={ dropdowns?.recipientTypes }
                                                           label={ t('recipient type') }
                                                           panelClassName={ "hide-p-toggler" }
                                                           placeholder={ t("pick") + ' ' + t("recipient type").toLowerCase() }
                                                           onChange={ (value: any) => onChangeRecipientType(value, setFieldValue) }
                                                           isRequired
                                                    />

                                                    <Field
                                                        placeholder={ t("pick a date") }
                                                        component={ InputDateTime }
                                                        name={ `finalize_date` }
                                                        type={ "datetime" }
                                                        label={ t("invoice date") }
                                                        maxDate={ moment().tz(moment.tz.guess()).toDate() }
                                                        isRequired
                                                    />

                                                    <Field component={ InputChip }
                                                           isEmail={ true }
                                                           name={ 'email' }
                                                           label={ t('email addresses') }
                                                           placeHolder={ t("type something and press tab...") }
                                                           formText={ t("any invalid emails may not be processed") }
                                                           />

                                                    <Field component={ InputSingleSelectField }
                                                           name="legal_entity_id"
                                                           panelClassName={ "hide-p-toggler" }
                                                           options={
                                                               (
                                                                   ( dropdowns && dropdowns.legalEntities )
                                                                       ?
                                                                       (
                                                                           entity
                                                                           && !isEmpty(entity.legal_entity_id)
                                                                           && !isEmpty(find(dropdowns.legalEntities, { id: entity.legal_entity.id }))
                                                                       )
                                                                           ? [...dropdowns.legalEntities.filter((item: any) => !item.is_disable), {
                                                                               id: entity.legal_entity.id,
                                                                               label: entity.legal_entity.company_name
                                                                           }]
                                                                           : dropdowns.legalEntities.filter((item: any) => !item.is_disable)
                                                                       :
                                                                       []
                                                               )}
                                                           label={ t('legal entity') }
                                                           placeholder={ t("pick") + ' ' + t("legal entity").toLowerCase() }
                                                           // onChange={ (value: any) => onChangeRecipientType(value, setFieldValue) }

                                                    />

                                                    <Field component={ InputSingleSelectField }
                                                           name="bank_account_number"
                                                           panelClassName={ "hide-p-toggler" }
                                                           options={
                                                                !isEmpty(dropdowns.bankAccounts)
                                                               ? dropdowns.
                                                                    bankAccounts
                                                                    .filter((bank: DynamicObject) => bank.legal_entity_id === values.legal_entity_id)
                                                                    .map((bank: DynamicObject) => ({...bank, key: bank.id}))
                                                               : [] }
                                                           label={ t('bank account number') }
                                                           placeholder={ t("pick") + ' ' + t("bank account number").toLowerCase() }
                                                    />

                                                    <Field component={ InputSingleSelectField }
                                                           name="currency"
                                                           panelClassName={ "hide-p-toggler" }
                                                           options={ dropdowns.currencies }
                                                           label={ t('currency') }
                                                           placeholder={ t("pick") + ' ' + t("currency").toLowerCase() }
                                                           disabled={true}
                                                    />
                                                </div>
                                            </CardBody>
                                            <CardFooter className={ "bg-body-tertiary" }>
                                                <Row>
                                                    <Col sm={ 12 } className={ "mb-2" }>
                                                        <Button type="submit" outlined size={"small"}
                                                                loading={props.isSubmitting} disabled={props.isSubmitting}
                                                                title={ t("to data save into database") } className={"w-full d-block"}>
                                                            <i className="fas fa-save me-1" /> { t("let's save") }
                                                        </Button>
                                                    </Col>
                                                    <Col sm={ 12 }  className={ "mb-2" }>
                                                        <Button type="button" outlined size={"small"} severity={"secondary"}
                                                                onClick={ onClose } className={"w-full d-block"}
                                                                disabled={props.isSubmitting}
                                                                title={ t("operation cancel") }>
                                                            <i className="fas fa-angle-left me-1" /> { t("cancel") }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>

                                    <Col sm={ 12 } md={ 8 } lg={ 9 }>
                                        <Card style={ { minHeight: '600px'} }>
                                            <CardBody>
                                                <Row>
                                                    <Col sm={ 12 } md={ 3 }>
                                                        <Field component={ InputField } type={"number"}
                                                               name="service_fee"
                                                               label={ t("service fee") }
                                                               placeholder={ t("enter") + ' ' + t("service fee").toLowerCase() } />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 3 }>
                                                        <Field component={ InputSingleSelectField }
                                                               name="discount_code_id"
                                                               panelClassName={ "hide-p-toggler" }
                                                               options={ dropdowns?.discountCodes }
                                                               label={ t('discount code') }
                                                               placeholder={ t("pick") + ' ' + t("discount code").toLowerCase() }
                                                               onChange={ (value: any) => onSelectDiscountCode(value, setFieldValue) }
                                                        />
                                                    </Col>
                                                    {
                                                        !isEmpty(values.id) &&
                                                      <Col sm={ 12 } md={ 3 }>
                                                          <Field component={ InputSingleSelectField }
                                                                 name="status"
                                                                 panelClassName={ "hide-p-toggler" }
                                                                 options={ dropdowns?.statuses }
                                                                 label={ t('status') }
                                                                 placeholder={ t("pick") + ' ' + t("status").toLowerCase() }
                                                          />
                                                      </Col>
                                                    }
                                                    {
                                                      values.recipient_type === 'ino' &&
                                                      <Col sm={ 12 } md={ 3 }>
                                                          <Field component={ InputSwitchField }
                                                                 name="is_vatable"
                                                                 label={ t("vat") }
                                                                 trueLabel={ t("yes") }
                                                                 falseLabel={ t("no") }
                                                                 onChange={ (value: any) => onChangeIsVat(value, setFieldValue) }
                                                          />
                                                      </Col>
                                                    }

                                                    <Col sm={ 12 }>
                                                        { isEmpty(values.items) && <div
                                                          className="invalid-feedback d-block first-letter-capitalized">{ t("the field is required.", { field: t("items") }) }</div> }
                                                        <table className={"fl-table"}>
                                                            <thead>
                                                                <tr>
                                                                    <td width={ 50 } className={"text-bold"}>#</td>
                                                                    <td width={ 80 }
                                                                        className={ "text-bold text-center" }>{ t("qty") }</td>
                                                                    <td width={ 320 }
                                                                        className={ "text-bold" }>{ t("description") }</td>
                                                                    <td width={ 100 }
                                                                        className={ "text-bold text-center" }>{ t("price") }</td>
                                                                    <td width={ 150 }
                                                                        className={ "text-bold text-center" }>{ t("discount") }</td>
                                                                    <td width={ 100 }
                                                                        className={ "text-bold text-end" }>{ t("vat") } (%)
                                                                    </td>
                                                                    <td width={ 80 }
                                                                        className={ "text-bold text-end" }>{ t("total") }</td>
                                                                </tr>
                                                            </thead>
                                                            <FieldArray
                                                                render={ arrayHelpers => (
                                                                    <tbody>
                                                                        { !isEmpty(values.items) ? (
                                                                            values.items.map((row: any, index: number) => {
                                                                                const hasErrors = ITEMS_ERROR_FIELDS.filter((field: string) => errors[`items.${ index }.${ field }`]);
                                                                                return (
                                                                                    <tr key={ index }
                                                                                        className={ !isEmpty(row.group_token) ? "muted" : "" }>
                                                                                        <td>
                                                                                            { index + 1 }

                                                                                            <Tooltip
                                                                                                tooltip={ t("delete") }
                                                                                                position={ "left" }>
                                                                                                <a
                                                                                                    title={ t('delete item') }
                                                                                                    className={ "cursor-pointer ms-2 text-danger" }
                                                                                                    onClick={ (event: any) => confirmPopup({
                                                                                                        target: event.currentTarget,
                                                                                                        message: t('do you want to delete this record?'),
                                                                                                        icon: 'pi pi-info-circle',
                                                                                                        // @ts-ignorer
                                                                                                        defaultFocus: 'reject',
                                                                                                        acceptClassName: 'p-button-danger',
                                                                                                        accept: () => onDeleteItem(row, index, arrayHelpers),
                                                                                                        reject: () => {
                                                                                                        },
                                                                                                    }) }>
                                                                                                    <i className="fas fa-trash-alt" />
                                                                                                </a>
                                                                                            </Tooltip>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Field
                                                                                                name={ `items.${ index }.qty` }>
                                                                                                { (props: any) =>
                                                                                                    <CustomInputComponent
                                                                                                        id={ `items.${ index }.qty` }
                                                                                                        onFocus={ ($event: any) => $event.target.select() }
                                                                                                        className={ `text-end p-inputtext-sm w-full ${ has(errors, get(`items.${ index }.qty`, ['name'])) ? 'p-invalid' : ''}` }
                                                                                                        errors={ errors }
                                                                                                        index={ index }
                                                                                                        onBlur={ ($event: any) => onChangePrice({
                                                                                                            key: 'qty',
                                                                                                            value: $event.target.value
                                                                                                        }, index, arrayHelpers) }
                                                                                                        { ...props }
                                                                                                    /> }
                                                                                            </Field>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Field
                                                                                                name={ `items.${ index }.title` }>
                                                                                                { (props: any) =>
                                                                                                    <CustomInputTextAreaComponent
                                                                                                        id={ `items.${ index }.title` }
                                                                                                        onFocus={ ($event: any) => $event.target.select() }
                                                                                                        className={ `p-inputtext-sm w-full ${ has(errors, get(`items.${ index }.qty`, ['name'])) ? 'p-invalid' : ''}` }
                                                                                                        type={ "textarea" }
                                                                                                        errors={ errors }
                                                                                                        index={ index }
                                                                                                        { ...props } /> }
                                                                                            </Field>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Field
                                                                                                name={ `items[${ index }]price` }>
                                                                                                { (props: any) =>
                                                                                                    <CustomInputComponent
                                                                                                        id={ `items[${ index }]price` }
                                                                                                        className={ `text-end p-inputtext-sm w-full ${ has(errors, get(`items.${ index }.qty`, ['name'])) ? 'p-invalid' : ''}` }
                                                                                                        onFocus={ ($event: any) => $event.target.select() }
                                                                                                        index={ index }
                                                                                                        errors={ errors }
                                                                                                        onBlur={ ($event: any) => onChangePrice({
                                                                                                            key: 'price',
                                                                                                            value: $event.target.value
                                                                                                        }, index, arrayHelpers) }
                                                                                                        { ...props }
                                                                                                    /> }
                                                                                            </Field>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div
                                                                                                className="p-inputgroup flex-1">
                                                                                                <span
                                                                                                    className="p-inputgroup-addon p-radio-sm">
                                                                                                    <Field
                                                                                                        name={ `items[${ index }]discount_type` }>
                                                                                                        { (props: any) =>
                                                                                                            <RadioButton
                                                                                                                inputId={ `items.${ index }.discount_type` }
                                                                                                                name={ `items.${ index }.discount_type` }
                                                                                                                disabled={ isEmpty(values.discount_code_id) }
                                                                                                                value="type_value"
                                                                                                                checked={ row.discount_type === "type_value" }
                                                                                                                onChange={() => setFieldValue(`items.${ index }.discount_type`, "type_value")}
                                                                                                                { ...props } />
                                                                                                        }
                                                                                                    </Field>
                                                                                                </span>
                                                                                                <Field
                                                                                                    name={ `items[${ index }]discount` }>
                                                                                                    { (props: any) =>
                                                                                                        <CustomInputComponent
                                                                                                            className={ `text-end p-inputtext-sm w-full ${ has(errors, get(`items.${ index }.discount`, ['name'])) ? 'p-invalid' : '' }` }
                                                                                                            onFocus={ ($event: any) => $event.target.select() }
                                                                                                            index={ index }
                                                                                                            errors={ errors }
                                                                                                            disabled={ isEmpty(values.discount_code_id) || row.discount_type !== 'type_value' }
                                                                                                            onBlur={ ($event: any) => onChangePrice({
                                                                                                                key: 'discount',
                                                                                                                value: $event.target.value
                                                                                                            }, index, arrayHelpers) }
                                                                                                            { ...props }
                                                                                                        />
                                                                                                    }
                                                                                                </Field>
                                                                                            </div>
                                                                                            <div className={ "mt-1" }>
                                                                                                <div
                                                                                                    className="p-inputgroup flex-1">
                                                                                                    <span
                                                                                                        className="p-inputgroup-addon p-radio-sm">
                                                                                                        <Field
                                                                                                            name={ `items[${ index }]discount_type` }>
                                                                                                            { (props: any) =>
                                                                                                                <RadioButton
                                                                                                                    inputId={ `items.${ index }.discount_type` }
                                                                                                                    name={ `items.${ index }.discount_type` }
                                                                                                                    disabled={ isEmpty(values.discount_code_id) }
                                                                                                                    value="type_percentage"
                                                                                                                    checked={ row.discount_type === "type_percentage" }
                                                                                                                    onChange={() => setFieldValue(`items.${ index }.discount_type`, "type_percentage")}
                                                                                                                    { ...props } />
                                                                                                            }
                                                                                                        </Field>
                                                                                                    </span>
                                                                                                    <Field
                                                                                                        name={ `items[${ index }]discount_percentage` }>
                                                                                                        { (props: any) =>
                                                                                                            <CustomInputComponent
                                                                                                                className={ `text-end p-inputtext-sm w-full ${ has(errors, get(`items.${ index }.discount_percentage`, ['name'])) ? 'p-invalid' : '' }` }
                                                                                                                onFocus={ ($event: any) => $event.target.select() }
                                                                                                                index={ index }
                                                                                                                errors={ errors }
                                                                                                                disabled={ isEmpty(values.discount_code_id) || row.discount_type !== 'type_percentage' }
                                                                                                                onBlur={ ($event: any) => onChangePrice({
                                                                                                                    key: 'discount_percentage',
                                                                                                                    value: $event.target.value
                                                                                                                }, index, arrayHelpers) }
                                                                                                                { ...props }
                                                                                                            />
                                                                                                        }
                                                                                                    </Field>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className={ "text-end" }>
                                                                                            { row.vat }%
                                                                                            <div>
                                                                                                <strong>
                                                                                                    <small>{ row.vat_total }</small>
                                                                                                </strong>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className={ "text-end" }>
                                                                                            { row.total }
                                                                                            <div
                                                                                                className={ "font-size-8px line-height-9px" }>
                                                                                                <small>
                                                                                                    <strong>
                                                                                                        { ( row.price && row.qty && row.price > 0 && row.qty > 0 ) && ( row.price * row.qty ).toFixed(POINT_DECIMAL) }
                                                                                                    </strong>
                                                                                                </small>
                                                                                            </div>
                                                                                            {
                                                                                                ( row.discount < ( row.price * row.qty ) ) &&
                                                                                              <div
                                                                                                className={ "font-size-8px line-height-9px text-danger" }>
                                                                                                  <small>
                                                                                                      <strong>
                                                                                                          { ( row.discount && row.discount > 0 ) && row.discount }
                                                                                                      </strong>
                                                                                                  </small>
                                                                                              </div>
                                                                                            }
                                                                                            <div
                                                                                                className={ "font-size-8px line-height-9px" }>
                                                                                                <small>
                                                                                                    <strong>
                                                                                                        { ( row.price && row.qty && row.price > 0 && row.qty > 0 ) && ( ( row.price * row.qty ) - row.discount ).toFixed(POINT_DECIMAL) }
                                                                                                    </strong>
                                                                                                </small>
                                                                                            </div>
                                                                                            <div
                                                                                                className={ "font-size-8px line-height-9px" }>
                                                                                                <small>
                                                                                                    <strong>
                                                                                                        { ( row.service_fee_amount && row.service_fee_amount > 0 ) && row.service_fee_amount }
                                                                                                    </strong>
                                                                                                </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan={ 7 }>{ t("no data") }</td>
                                                                            </tr>
                                                                        ) }

                                                                        <tr key={ 9996 }>
                                                                            <th colSpan={ 3 }>
                                                                                <Tooltip
                                                                                    tooltip={ t("add new invoice item") }
                                                                                    position={ "top" }>
                                                                                    <Button type="button" severity={undefined} size={ "small" } outlined
                                                                                            onClick={ () => addItem(arrayHelpers) }>
                                                                                        <i className="fas fa-plus-square me-1" /> { t("add item") }
                                                                                    </Button>
                                                                                </Tooltip>
                                                                                <Tooltip
                                                                                    tooltip={ t("pick invoiceable item") }
                                                                                    position={ "top" }>
                                                                                    <Button type="button" severity={undefined} size={ "small" } outlined
                                                                                            onClick={ onClickInvoicable }
                                                                                            className="ms-1">
                                                                                        <i className=" fas fa-folder-plus  me-1" /> { t("ready to be invoiced") }
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </th>
                                                                            <th colSpan={ 3 }
                                                                                className={ "text-end" }>{ t("item total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(sumBy(values.items, (r: any) => ( ( r.price > 0 ? r.price : 0 ) ) * Number(( r.qty > 0 ? r.qty : 0 ))), POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                        <tr key={ 9995 }>
                                                                            <th colSpan={ 6 }
                                                                                className={ "text-end" }>{ t("discount total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(sumBy(values.items, (r: any) => ( !isNaN(r.discount) && r.discount > 0 ) ? Number(r.discount) : 0), POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                        <tr key={ 9994 }>
                                                                            <th colSpan={ 6 }
                                                                                className={ "text-end" }>{ t("service fee total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(sumBy(values.items, (r: any) => ( !isNaN(r.service_fee_amount) && r.service_fee_amount > 0 ) ? Number(r.service_fee_amount) : 0), POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                        <tr key={ 9993 }>
                                                                            <th colSpan={ 6 }
                                                                                className={ "text-end" }>{ t("vat total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(sumBy(values.items, (r: any) => ( !isNaN(r.vat_total) && r.vat_total > 0 ) ? Number(r.vat_total) : 0), POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                        <tr key={ 9992 }>
                                                                            <th colSpan={ 6 }
                                                                                className={ "text-end" }>{ t("grand total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(sumBy(values.items, (r: any) => ( !isNaN(r.total) && r.total > 0 ) ? Number(r.total) : 0), POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                        <tr key={ 9991 }>
                                                                            <th colSpan={ 6 }
                                                                                className={ "text-end" }>{ t("pre payment") +' '+ t("total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(values.pre_payment_total, POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                        <tr key={ 9990 }>
                                                                            <th colSpan={ 6 }
                                                                                className={ "text-end" }>{ t("final") +' '+ t("total") }</th>
                                                                            <td className={ "text-end" }>{ numberFormat(sumBy(values.items, (r: any) => ( !isNaN(r.total) && r.total > 0 ) ? Number(r.total) : 0) - values.pre_payment_total, POINT_DECIMAL, ',', '.') }</td>
                                                                        </tr>
                                                                    </tbody>
                                                                ) }
                                                                name="items"
                                                            />
                                                        </table>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        );
                    } }
                </Formik>

                <InvoiceableList
                    state={ isInvoiceablePanelActive }
                    dropdowns={ dropdowns }
                    toastify={ toastify }
                    onClose={ onCloseInvoicable }
                />
            </div>
        </>
    );
}

InvoiceForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(InvoiceForm);
