import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useRef, useState } from "react";
import { CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { capitalize, get, includes, isEmpty, sumBy } from "lodash";
import { request } from "../../../../utils/Request";
import { Card } from "primereact/card";
import { formatDate, numberFormat, POINT_DECIMAL, utcDateToLocalDate } from "../../../../utils/Helper";
import InvoiceStatus from "./InvoiceStatus";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import Auth from "../../../../utils/Auth";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";

const InvoiceDetail = (props: any) => {
    const { t, toastify, params } = props;
    const auth = new Auth();
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/invoices/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const onDeleteDocument = async (id: string) => {
        try {
            await request({
                url: `suppliers/invoices/delete/document/${ id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("file deleted", { item: t('file') }), "success");
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div>
            <Row>
                <Col md={ 6 } lg={ 4 } sm={ 12 }>
                    <Card>
                        {
                            entity &&
                          <>
                              <CardHeader>
                                  <Row>
                                      <Col sm={ 12 } md={ 6 }>
                                          <h4>
                                              { t('invoice') } { t('detail') }
                                              <br />
                                              <small className={ "ms-1" }>
                                                  { entity.status === 'draft' && `#${ entity.draft_invoice_number }` }
                                                  { entity.status !== 'draft' && `#${ entity.final_invoice_number }` }
                                              </small>
                                          </h4>
                                      </Col>
                                      <Col sm={ 12 } md={ 6 } className={ "text-end" }>
                                          <Button type={ "button" }
                                                  tooltip={ t("operation cancel") } className={ "ms-2" }
                                                  size={ "small" } severity={ "warning" } outlined
                                                  tooltipOptions={ { position: 'top' } } onClick={ props.onClose }>
                                              <i className="pi pi-arrow-left me-2" /> { t("back") }
                                          </Button>
                                      </Col>
                                  </Row>
                              </CardHeader>
                              <CardBody>
                                  <Row>
                                      <Col sm="12">
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span className="text-capitalize">{ t('created at') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end">{ utcDateToLocalDate(entity.created_at) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('modified at') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end">{ utcDateToLocalDate(entity.updated_at) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('draft invoice number') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div className="text-end">{ entity.draft_invoice_number }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('final invoice number') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div className="text-end">{ entity.final_invoice_number }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('invoice date') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end">{ formatDate(entity.finalize_date, 'DD-MM-YYYY hh:mm A') }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span className="text-capitalize">{ t('recipient') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div className="text-end">{ get(entity.client, ['name']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('recipient type') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end">{ get(entity, ['recipient_type']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span className="text-capitalize">{ t('email') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div className="text-end">{ get(entity, ['email']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('bank account number') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end">{ get(entity, ['bank_account_number']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span className="text-capitalize">{ t('status') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end text-uppercase">
                                                      <InvoiceStatus status={ get(entity, ['status']) } />
                                                  </div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span className="text-capitalize">{ t('currency') }</span>
                                                  </h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end text-uppercase">{ get(entity, ['currency']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('service fee') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end text-uppercase">{ get(entity, ['service_fee']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('legal entity') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end text-uppercase">{ get(entity, ['legal_entity', 'company_name']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('discount code') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end text-uppercase">{ get(entity, ['discount_code', 'code']) }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col md="6" lg="6" sm="12">
                                                  <h6><span
                                                    className="text-capitalize">{ t('vat') }</span></h6>
                                              </Col>
                                              <Col md="6" lg="6" sm="12">
                                                  <div
                                                    className="text-end text-uppercase">{ get(entity, ['is_vatable']) ? t('true') : t('false') }</div>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col sm="12">
                                                  <hr />
                                              </Col>
                                              <Col md="6" lg="6" sm="12">

                                                  {
                                                      entity.status === 'draft'
                                                      &&
                                                    <Tooltip
                                                      tooltip={ t("draft pdf") }>
                                                        <a
                                                          className={ "cursor-pointer me-1 btn btn-secondary waves-effect waves-light ms-2 btn-sm" }
                                                            /*@ts-ignore*/
                                                          href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ entity.id }?status=draft&types=pdf&uToken=` + auth.getUser().id }
                                                          target={ "_blank" }
                                                          rel="noopener noreferrer"
                                                        >
                                                            <i className="far fa-file-pdf" />
                                                        </a>
                                                    </Tooltip>
                                                  }
                                                  {
                                                      entity.status !== 'draft'
                                                      &&
                                                    <>
                                                        <Tooltip
                                                          tooltip={ t("final pdf") }>
                                                            <a
                                                              className={ "ms-2 p-button p-component p-button-outlined p-button-sm p-button-danger" }
                                                                /*@ts-ignore*/
                                                              href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ entity.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                                                              target={ "_blank" }
                                                              rel="noopener noreferrer"
                                                            >
                                                                <i className="far fa-file-pdf" />
                                                            </a>
                                                        </Tooltip>
                                                        {
                                                            ( entity.status === 'final' && entity.paid_total > 0 )
                                                            &&
                                                          <Tooltip
                                                            tooltip={ t("payment report pdf") }>
                                                              <a
                                                                className={ "ms-2 p-button p-component p-button-outlined p-button-sm p-button-danger" }
                                                                  /*@ts-ignore*/
                                                                href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ entity.id }?status=final&types=pdf&payment=1&uToken=` + auth.getUser().id }
                                                                target={ "_blank" }
                                                                rel="noopener noreferrer"
                                                              >
                                                                  <i className="far fa-file-pdf" />
                                                              </a>
                                                          </Tooltip>
                                                        }
                                                    </>
                                                  }
                                                  {
                                                      <Button type="button" size={"small"} outlined severity={"danger"}
                                                              onClick={ () => onDeleteDocument(entity.id) }
                                                              tooltip={ t("regenerate document") }
                                                              className={ "ms-2" }>
                                                          <i className="fas fa-redo" />
                                                      </Button>
                                                  }
                                              </Col>
                                          </Row>
                                      </Col>
                                  </Row>
                              </CardBody>
                          </>
                        }
                    </Card>
                </Col>
                <Col md={ 6 } lg={ 8 } sm={ 12 }>
                    <Card>
                        {
                            entity &&
                          <>
                              <CardBody>
                                  <Row>
                                      <Col sm={ 12 }>
                                          <h6>{ t("invoice items") }</h6>
                                          <hr />

                                          <DataTable value={ entity.invoice_items } editMode="row" dataKey="id"
                                                     footer={ <>
                                                         <Row>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("item total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( ( r.price > 0 ? r.price : 0 ) ) * Number(( r.qty > 0 ? r.qty : 0 ))), POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("discount total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => parseFloat(r.discount)), POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("service fee total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.service_fee_amount) && r.service_fee_amount > 0 ) ? Number(r.service_fee_amount) : 0), POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("vat total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.vat_total) && r.vat_total > 0 ) ? Number(r.vat_total) : 0), POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("grand total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.total) && r.total > 0 ) ? Number(r.total) : 0), POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("pre payment") + ' ' + t("total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(entity.pre_payment_total, POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                             <Col sm={ 12 }>
                                                                 <Row>
                                                                     <Col sm={ 9 }
                                                                          className={ "text-end" }>{ t("final") + ' ' + t("total") }</Col>
                                                                     <Col sm={ 3 }
                                                                          className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.total) && r.total > 0 ) ? Number(r.total) : 0) - entity.pre_payment_total, POINT_DECIMAL, ',', '.') }</Col>
                                                                 </Row>
                                                             </Col>
                                                         </Row>
                                                     </> }>
                                              <Column field="qty" header={ t("qty") } />
                                              <Column field="name" header={ t("name") }  body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ data.title }</div>
                                                      </>
                                                  );
                                              } } />
                                              <Column field="price" header={ t("price") }  body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ numberFormat(data.price, POINT_DECIMAL, ',', '.') }</div>
                                                      </>
                                                  );
                                              } } />
                                              <Column field="discount" header={ t("discount") } body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ numberFormat(data.discount, POINT_DECIMAL, ',', '.') }</div>
                                                          <div>{ numberFormat(data.discount_percentage, POINT_DECIMAL, ',', '.') }</div>
                                                      </>
                                                  );
                                              } } />
                                              <Column field="vat" header={ t("vat") } body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ numberFormat(data.vat, POINT_DECIMAL, ',', '.') }</div>
                                                      </>
                                                  );
                                              } } />
                                              <Column field="vat_total" header={ t("vat total") } body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ numberFormat(data.vat_total, POINT_DECIMAL, ',', '.') }</div>
                                                      </>
                                                  );
                                              } } />
                                              <Column field="service_fee_amount" header={ t("service amount") } body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ numberFormat(data.service_fee_amount, POINT_DECIMAL, ',', '.') }</div>
                                                      </>
                                                  );
                                              } } />
                                              <Column field="total" header={ t("total") }
                                                      bodyClassName={ "text-end" } body={ (data: any) => {
                                                  return (
                                                      <>
                                                          <div>{ numberFormat(data.total, POINT_DECIMAL, ',', '.') }</div>
                                                      </>
                                                  );
                                              } } />
                                          </DataTable>
                                          <Divider />

                                          <h6>{ t("status history") }</h6>
                                          <DataTable value={ entity.history } editMode="row" dataKey="created_at">
                                              <Column field="status" header={ t("status") }
                                                      bodyClassName={ "text-uppercase" } />
                                              <Column field="comments" header={ t("comments") } body={ (data: any) => {
                                                  return <>
                                                      { data.system_note }
                                                  </>;
                                              } } />
                                              <Column field="created_at" header={ t("date & time") }
                                                      body={ (data: any) => {
                                                          return <>
                                                              { utcDateToLocalDate(data.created_at) }
                                                          </>;
                                                      } } />
                                              <Column field="author" header={ t("updated by") } body={ (data: any) => {
                                                  return get(data, ['author', 'name']);
                                              } } />
                                          </DataTable>
                                      </Col>
                                  </Row>
                              </CardBody>

                              <HasAccess hasAnyAccess={ ['invoicepaymentsview'] }>
                                  <Divider />

                                  <CardBody>
                                      <Row>
                                          <Col sm={ 12 }>
                                              <h6>{ t("payment histories") }</h6>
                                              <hr />
                                              <table className={ "fl-table" }>
                                                  <thead>
                                                      <tr>
                                                          <th>#</th>
                                                          <th>{ t('payment mode') }</th>
                                                          <th>{ t('payment date') }</th>
                                                          <th>{ t('prepayment connection') }</th>
                                                          <th>{ t('status') }</th>
                                                          <th className={ "text-end" }>{ t('total') }</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      { entity.payments.map((item: any, index: number) => {
                                                          return (
                                                              <React.Fragment key={ index }>
                                                                  <tr>
                                                                      <td>{ index + 1 }</td>
                                                                      <td>{ capitalize(item.payment_mode) }</td>
                                                                      <td>{ utcDateToLocalDate(item.payment_date) }</td>
                                                                      <td>{ item.is_prepayment ? t("deduct from pre payment") : t("dont deduct from prepayment") }</td>
                                                                      <td>{ item.status ? t(item.status) : "-" }</td>
                                                                      <td className={ "text-end" }>{ item.total }</td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td colSpan={ 6 } className={"text-left"}>
                                                                              { t('comments') }: { item.comments }
                                                                      </td>
                                                                  </tr>
                                                              </React.Fragment>
                                                          );
                                                      }) }
                                                  </tbody>
                                                      { isEmpty(entity.payments) &&
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={4}>
                                                                    { t("no data available") }
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                      }
                                              </table>
                                          </Col>
                                      </Row>
                                  </CardBody>
                              </HasAccess>
                          </>
                        }
                    </Card>
                </Col>
            </Row>
        </div>

    );
};

InvoiceDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(InvoiceDetail));
