import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import * as yup from "yup";
import { find, findIndex, get, isEmpty } from "lodash";
import { Sidebar } from "primereact/sidebar";
import { InputSelectField } from "../../../../components/Shared/InputSelectField";
import InputAttachment from "../../../../components/Shared/InputAttachment";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";

interface IProps {
    visible: any;
    dropdowns?: any | undefined;
    onClose: any;
    loadOrders: any
    addUpdateDocuments: any
    deleteDocuments: any
}

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        title: yup.string().required(t('the field is required.', { field: t('title') })).min(3).nullable(),
        document_id: yup.string().required(t('please upload the document')).nullable(),
        tags: yup.array().required(t('the field is required.', { field: t('document types') })).nullable()
    });
};

type TInputForm = {
    title: null | string,
    document_id: null | string,
    tags: null | string,
    size: null | string | number,
};

const DEFAULT_FORM_STATE = {
    title: null,
    tags: null,
    document_id: null,
    size: null,
};

// @ts-ignore
function SpotLoadDocument(props: IProps) {
    const { dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>({routes: []});
    const [open, setOpen] = useState<boolean>(false);
    const [documents, setDocuments] = useState([]);
    const [deleteDocuments, setDeleteDocuments] = useState<any>([]);
    const { t }: any = useTranslation();

    const addDocument = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        let _documents: any = [...documents];
        let existingDocument = find(_documents, { document_id: payloads.document_id });

        if ( !isEmpty(existingDocument) ) {
            const index = findIndex(_documents, { document_id: payloads.document_id });
            existingDocument = payloads;
            _documents[index] = existingDocument;
        } else {
            _documents.push(payloads);
        }

        setDocuments(_documents);
        resetForm();
        setInitFormState(DEFAULT_FORM_STATE);
    };

    const onDelete = ({ id }: any) => {
        if ( find(documents, { document_id: id }) ) {
            // @ts-ignore
            const trashIndex = findIndex(documents, { document_id: id });
            const _documents = documents.filter((_: any, index: number) => ( index != trashIndex ));
            setDocuments(_documents);
            const shallowDelete = [...deleteDocuments, id];
            setDeleteDocuments(shallowDelete);
        }
    };

    const onEdit = (item: any) => {
        setInitFormState(item);
    };

    const onClose = () => {
        setOpen(false);
        props.onClose();
    };

    const onSave = () => {
        const index = props.visible.split("~~~")[1];
        props.addUpdateDocuments(documents, index);
        props.deleteDocuments(deleteDocuments, index);
        setOpen(false);
    };

    const onAfterUpload = (name: any, size: any) => {
        const base: any = get(formRef.current, ['values']);

        setInitFormState({
            ...base,
            title: name,
            size: size
        })
    };

    const onReset = () => {

    }

    // @ts-ignore
    useEffect(() => {
        setDocuments([]);
        setDeleteDocuments([]);

        if ( props.visible ) {
            const index = props.visible.split("~~~")[1];
            const loadOrderItem: any = ( props.loadOrders && index >= 0 ) ? props.loadOrders[index] : [];
            if ( loadOrderItem.documents ) {
                const docs = loadOrderItem.documents.map((item: any) => {
                    return {
                        ...item,
                        tags: ( item.tags ? item.tags.map((i: any) => i.title) : [] )
                    };
                });

                setDocuments(docs);
            } else {
                setDocuments(loadOrderItem.documents ?? []);
            }
            setOpen(true);
        }

    }, [props.visible]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('manage routes documents') }</h5>
                            <p>{ t("add or update document that's related to route.") }</p>
                        </div>
                    }
                    visible={ open } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>

                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ addDocument }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   name="title"
                                                   label={ t("title") }
                                                   placeholder={ t("enter") + ' ' + t("title").toLowerCase() }
                                                   isRequired autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 8 }>
                                            <Field
                                                component={ InputSelectField }
                                                name="tags"
                                                options={ dropdowns.documentTypes ?? [] }
                                                label={ t('document types') }
                                                placeholder={ t("select") + ' ' + t("document types") }
                                                isRequired showClear
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputAttachment }
                                                   name="document_id"
                                                   label={ t("upload") }
                                                   onAfterUpload={ onAfterUpload }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }
                                             className={ "mt-sm-0 mt-md-4 mt-lg-4 text-end" }>
                                            <Button type="submit" className={ "me-2" } size={ "small" }
                                                    disabled={ props.isSubmitting } outlined severity={ "success" }
                                                    title={ t("add") }>
                                                <i className="pi pi-plus me-2" /> { t("document") }
                                            </Button>
                                            <Button type="button" size={ "small" } outlined
                                                    disabled={ props.isSubmitting }
                                                    onClick={ onReset }
                                                    title={ t("to make all fields empty") }>
                                                <i className="pi pi-refresh me-2" /> { t("reset form") }
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={ 12 }>
                                            <table className={"fl-table"}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{ t('title') }</th>
                                                        <th>{ t('size') }</th>
                                                        <th>{ t('document types') }</th>
                                                        <th className={ "text-end" }>{ t('action') }</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { documents && documents.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={ index }>
                                                                <td>{ index + 1 }</td>
                                                                <td>{ item.title }</td>
                                                                <td>{ item.size }</td>
                                                                <td className={ "text-capitalize" }>{ item.tags && item.tags.join(", ") }</td>
                                                                <td className={ "text-end" }>
                                                                    <a
                                                                        title={ t('edit item') }
                                                                        onClick={ () => onEdit(item) }>
                                                                        <i className="fas fa-edit" />
                                                                    </a>
                                                                    <a
                                                                        href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ item.document_id }` }
                                                                        className={ "ms-2 " }
                                                                        target={ "_blank" }
                                                                        rel="noopener noreferrer">
                                                                        <i className="fas fa-cloud-download-alt" />
                                                                    </a>
                                                                    <a
                                                                        title={ t('delete item') }
                                                                        className={ "ms-2 " }
                                                                        onClick={ (event: any) => confirmPopup({
                                                                            target: event.currentTarget,
                                                                            message: t('do you want to delete this record?'),
                                                                            icon: 'pi pi-info-circle',
                                                                            // @ts-ignorer
                                                                            defaultFocus: 'reject',
                                                                            acceptClassName: 'p-button-danger',
                                                                            accept: () => onDelete({ id: item.document_id }),
                                                                            reject: () => {
                                                                            },
                                                                        }) }>
                                                                        <i className="fas fa-trash-alt" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) }
                                                </tbody>
                                                {
                                                    isEmpty(documents) &&
                                                  <tfoot>
                                                      <tr>
                                                          <td colSpan={ 5 }>
                                                              { t('no data') }
                                                          </td>
                                                      </tr>
                                                  </tfoot>
                                                }
                                            </table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={ 12 }>
                                            <div className="filter-card-footer"
                                                 style={ { width: '785px' } }>
                                                <Button type={ "button" }
                                                        onClick={ onSave }
                                                        tooltip={ t("to data save into database") } className={ "ms-2" }
                                                        size={ "small" } outlined
                                                        tooltipOptions={ { position: 'top' } }
                                                >
                                                    <i className="pi pi-save me-2" />
                                                    { t("save") }
                                                </Button>
                                                <Button type={ "button" }
                                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                                        size={ "small" } severity={ "danger" } outlined
                                                        tooltipOptions={ { position: 'top' } }
                                                        onClick={ onClose }
                                                >
                                                    <i className="pi pi-arrow-left me-2" />
                                                    { t("cancel") }
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

export default withTranslation()(SpotLoadDocument);
