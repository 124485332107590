import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isEmpty, has } from "lodash";

interface IInputDate {
    label?: string,
    field: any,
    form?: any,
    value: any,
    isRequired?: boolean,
    disabled?: boolean,
    formGroupClass?: any
    placeholder?: string,
    autoFocus?: string,

    dateFormat?: string,
    timeFormat?: string,
    dateTimeFormat?: string,
    displayDateFormat?: string,
    displayTimeFormat?: string,
    displayDateTimeFormat?: string,

    minDate?: string,
    maxDate?: string,

    type: "date" | "datetime" | "time",
    enableTimeStartOfDay: boolean,
    enableTimeEndOfDay: boolean,
    isClearable?: boolean,
    withoutLabel?: boolean,
    formText?: boolean
}

const dateFormat = 'YYYY-MM-DD'; // moment js
const timeFormat = 'HH:mm:ss';

const displayDateFormat = 'dd.MM.yyyy'; // https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
const displayTimeFormat = 'hh:mm a';

export const getDisplayFormat = (props: any) => {
    if ( props.type === 'date' ) {
        return props.displayDateFormat ?? displayDateFormat;
    }

    if ( props.type === 'time' ) {
        return props.displayTimeFormat ?? displayTimeFormat;
    }

    return (
        `${ props.displayDateFormat ?? displayDateFormat } ${ props.displayTimeFormat ?? displayTimeFormat }`
    );
};

export const getDateFormat = (props: any) => {
    if ( props.type === 'date' ) {
        return props.dateFormat ?? dateFormat;
    }

    if ( props.type === 'time' ) {
        return props.timeFormat ?? timeFormat;
    }

    return (
        `${ props.dateFormat ?? dateFormat } ${ props.timeFormat ?? timeFormat }`
    );
};

export const onChangeDate = (selectedDate: any, config: any) => {
    let value = selectedDate;

    if ( value && config.type === "date" ) {
        value = moment(selectedDate).format(getDateFormat(config));
    }

    if ( value && config.type === "datetime" ) {
        if ( typeof selectedDate === "object" ) {
            let newDate = selectedDate.toString().replace(/\s*\([^)]*\)/, '');
            value = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format(getDateFormat(config));
        } else if ( typeof selectedDate === "string" ) {
            let newDate = ( new Date(selectedDate) ).toString().replace(/\s*\([^)]*\)/, '');
            value = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format(getDateFormat(config));
        } else {
            value = moment(selectedDate).format(getDateFormat(config));
        }
    }

    if ( value && config.type === "time" ) {
        value = moment(selectedDate).format(getDateFormat(config));
    }

    if ( config.toString && config.toString > 0 ) {
        value = moment(value).toDate();
    }

    return value;
};

function InputDateTime(props: IInputDate) {
    const [startDate, setStartDate] = useState<any>();
    const { field, form, formGroupClass } = props;
    const { errors } = form;
    const { t } = useTranslation();

    const onChange = (date: any) => {
        if ( date == '' || date == null || typeof date == undefined ) {
            setStartDate(null);
            form.setFieldValue(field.name, null);
            return;
        }

        let _value = onChangeDate(date, props);

        if ( moment(_value).format("HH:mm:ss") === "00:00:00" && props.type === "datetime" ) {
            _value = onChangeDate(
                moment(moment(_value).format('YYYY-MM-DD ') + moment().format('HH:mm:ss')).toString(),
                props
            );

            if ( props.enableTimeStartOfDay ) {
                _value = moment(_value).startOf('day').add('1', 'minute').toDate();
            }

            if ( props.enableTimeEndOfDay ) {
                _value = moment(_value).endOf('day').toDate();
            }
        }

        setStartDate(moment(_value).toDate());
        form.setFieldValue(field.name, moment(_value).format(getDateFormat(props)));
    };

    useEffect(() => {
        if ( props.field.value == '' || props.field.value == null || typeof props.field.value == undefined ) {
            setStartDate(null);
            return;
        }

        if (
            ( isEmpty(startDate) && !isEmpty(props.field.value) ) ||
            ( !isEmpty(startDate) && !isEmpty(props.field.value) && !moment(startDate).isSame(moment(props.field.value)) )
        ) {
            if ( !isEmpty(props.field.value) ) {
                setStartDate(moment(props.field.value).toDate());
            } else {
                setStartDate(null);
            }
        }
    }, [props.field.value]);

    const filterPassedTime = (time: any) => {
        const slotDate = moment(time);

        if ( props.minDate && props.maxDate ) {
            const minDate = moment(props.minDate);
            const maxDate = moment(props.maxDate);

            return (
                minDate.isBefore(slotDate)
                || maxDate.isAfter(slotDate)
            );
        }

        if ( props.minDate ) {
            // send true that to be disabled
            const minDate = moment(props.minDate);
            return minDate.isBefore(slotDate);
        }

        if ( props.maxDate ) {
            // send true that to be disabled
            const maxDate = moment(props.maxDate);
            return maxDate.isAfter(slotDate);
        }

        return true;
    };

    return (
        <div className="p-fluid">
            <div className={ `field ${ formGroupClass } react-${ props.type }` }>
                { !props.withoutLabel
                    && <label htmlFor={ field.id ?? field.name }>
                        { props.label }
                        { props.isRequired && <strong style={ { color: 'var(--red-500)' } }> *</strong> }
                    </label>
                }
                <div className={ "reactdatepicker-container" }>
                    <DatePicker
                        disabled={ props.disabled }
                        selected={ startDate }
                        onChange={ onChange }
                        placeholderText={ props.placeholder ?? t("pick date") }
                        dateFormat={ getDisplayFormat(props) }
                        showTimeSelect={ props.type === 'time' || props.type === 'datetime' }
                        className={ ` ${ has(errors, field.name) ? 'p-invalid' : '' } p-inputtext p-component w-full p-inputtext-sm` }
                        minDate={ props.minDate ?? null }
                        maxDate={ props.maxDate ?? null }
                        minTime={ moment().startOf('day') } // Set minTime to start of the current day
                        maxTime={ moment().endOf('day') } // Set maxTime to end of the current day
                        isClearable={ props.isClearable ?? false }
                        autoFocus={ props.autoFocus ?? false }
                        filterTime={ filterPassedTime }
                        timeIntervals={ 15 }
                        disabledKeyboardNavigation
                    />
                </div>
                { props.formText && <small>{ props.formText }</small> }
                <ErrorMessage name={ field.name } component="small" className="p-error" />
            </div>
        </div>
    );
};

// InputDateTime.defaultProps = {
//     enableTimeStartOfDay: false,
//     enableTimeEndOfDay: false
// };

export default InputDateTime;
