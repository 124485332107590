import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { ButtonGroup } from "primereact/buttongroup";
import { get } from "lodash";
import PrePaymentFilter from "./PrePaymentFilter";
import { css } from "@emotion/css";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import Auth from "../../../../utils/Auth";

const PrePaymentList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [extra, setExtra] = useState({});
    const [initSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const tableRef: any = useRef();
    const auth = new Auth();

    const columns = [
        {
            field: 'client_id',
            title: t('client'),
            sorting: true,
            render: (_row: any) => (get(_row.client, ['name']))
        },
        {
            field: 'author_id',
            title: t('creator'),
            sorting: true,
            render: (_row: any) => <>
                {get(_row, ['author', 'name'])}
                <div>{utcDateToLocalDate(_row.created_at)}</div>
            </>
        },
        // {
        //     field: 'type',
        //     title: t('type'),
        //     sorting: true,
        //     render: (_row: any) => <span className={ "text-capitalize" }>{ _row.type }</span>
        // },
        {
            field: 'invoice',
            title: t('invoice'),
            sorting: false,
            render: (_row: any) => <span className={ "text-capitalize" }>
                {
                    _row.invoice &&
                  <>
                      {
                          _row.invoice.status === 'draft'
                          &&
                        <Tooltip tooltip={ t("draft pdf") }>
                            <a
                              title={ t('draft pdf') }
                              className={ "cursor-pointer ms-2" }
                                /*@ts-ignore*/
                              href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.invoice.id }?status=draft&types=pdf&uToken=` + auth.getUser().id }
                              target={ "_blank" }
                              rel="noopener noreferrer"
                            >
                                { _row.invoice.status === "draft" && get(_row.invoice, ['draft_invoice_number']) }
                            </a>
                        </Tooltip>
                      }
                      {
                          _row.invoice.status === 'final'
                          &&
                        <Tooltip
                          tooltip={ t("final pdf") }
                          position={ "left" }>
                            <a
                              className={ "cursor-pointer ms-2" }
                                /*@ts-ignore*/
                              href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.invoice.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                              target={ "_blank" }
                              rel="noopener noreferrer"
                            >
                                { _row.invoice.status === "final" && get(_row.invoice, ['final_invoice_number']) }
                            </a>
                        </Tooltip>
                      }
                  </>
                }
            </span>
        },
        {
            field: 'price',
            title: t('amount'),
            sorting: true,
            render: (_row: any) => <span>
                <span className={"text-bold"}>{_row.type === "positive" ? '+' : '-'}</span> { t(_row.price) }
            </span>
        },
        {
            field: 'status',
            title: t('status'),
            sorting: true,
            render: (_row: any) => <span className={ "text-capitalize" }>{ t(_row.status) }</span>
        },
        {
            field: 'payment_method',
            title: t('payment method'),
            sorting: true,
            render: (_row: any) => <span className={ "text-capitalize" }>{ _row.payment_method }</span>
        },
        {
            field: 'payment_date',
            title: t('payment date'),
            sorting: true,
            render: (_row: any) => ( _row.payment_date ? utcDateToLocalDate(_row.payment_date) : '' )
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            render: (_row: any) => {
                return (
                    <>
                        <Tooltip tooltip={ t("pdf") }>
                            <a
                                title={ t('pdf') }
                                className={ "cursor-pointer" }
                                /*@ts-ignore*/
                                href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=ClientPrePayment&types=pdf&uToken=` + auth.getUser().id }
                                target={ "_blank" }
                                rel="noopener noreferrer"
                            >
                                <i className="far fa-file-pdf" />
                            </a>
                        </Tooltip>
                    </>
                );
            }
        },
    ];

    const onReset = () => {
        tableRef.current.onRefresh();
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const setRowClass = (row: any) => {
        if(row.type === "positive") {
            return css`
                background-color: var(--green-100);
            `;
        }

        return css`
            background-color: var(--red-100);
        `;
    }

    const getExtra = (props: any) => {
        setExtra(props);
    }

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('pre payments') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        <div>
                            <strong>{t('available')}: {get(extra, ['available']) ?? 0}</strong>
                            <strong className={"ms-2 text-green-500"}>{t('prepaid')}: {get(extra, ['pre_paid']) ?? 0}</strong>
                            <strong className={"ms-2 text-red-500"}>{t('used')}: {get(extra, ['used']) ?? 0}</strong>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ "p-2" }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       name="search" autoFocus={ true }
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/clients/pre/payments?1=1&full=1' }
                                name={ "prePaymentList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                setRowClass={ setRowClass }
                                ref={ tableRef }
                                itemSize={ 45 }
                                setTotal={ setTotal }
                                getExtra={ getExtra }
                                // detailRow={ (data: any) => <DetailRow data={ data } /> }
                            />
                        </Col>
                    </Row>

                    <PrePaymentFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

PrePaymentList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(PrePaymentList));
