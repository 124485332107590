import { MenuModel } from "../../../types";

export function SupplierNav(props: any) {
    const { t, auth } = props;
    let items: any = [
        {
            label: t("dashboard"),
            icon: "pi pi-fw pi-home",
            to: "/dashboard",
            key: 1,
        },
    ];

    let model: MenuModel[] = [
        {
            label: '',
            icon: "pi pi-home",
            items: items,
            key: 2,
        },
    ];


    if ( auth.hasAnyAccess(['managementdashboardview']) ) {
        items = [
            ...items,
            {
                label: t("management dashboard"),
                icon: "pi pi-fw pi-slack",
                to: "/management/dashboard",
                key: 3,
            },
        ]
    }

    if ( auth.hasAnyAccess(['ordersview', 'orderdraftsview']) ) {
        items = [
            ...items,
            {
                label: props.t('order management'),
                icon: "pi pi-fw pi-car",
                key: 4,
                items: [
                    auth.hasAnyAccess(['orderdraftsview__']) ? {
                        label: t("new requests"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/order/drafts",
                        key: 5,
                    } : {},

                    auth.hasAnyAccess(['ordersview']) ? {
                        label: t("orders"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/orders",
                        key: 6,
                    } :  {},
                ],
            }
        ];
    }

    // if ( auth.hasAnyAccess(['alpspotloadsview', 'alpinboundloadsview__1', 'alpoutboundloadsview__1']) ) {
    //     items = [
    //         ...items,
    //         {
    //             label: props.t('emil frey'),
    //             icon: "pi pi-fw pi-star",
    //             key: 7,
    //             items: [
    //                 auth.hasAnyAccess(['alpinboundloadsview__1']) ? {
    //                     label: t("inbound loads"),
    //                     icon: "pi pi-fw pi-circle-fill",
    //                     to: "/alp/inbound/loads",
    //                     key: 8,
    //                 } :  {},
    //
    //                 auth.hasAnyAccess(['alpoutboundloadsview__1']) ? {
    //                     label: t("outbound loads"),
    //                     icon: "pi pi-fw pi-circle-fill",
    //                     to: "/alp/outbound/loads",
    //                     key: 9,
    //                 } :  {},
    //
    //                 auth.hasAnyAccess(['alpspotloadsview']) ? {
    //                     label: t("spot loads"),
    //                     icon: "pi pi-fw pi-circle-fill",
    //                     to: "/alp/spot/loads",
    //                     key: 10,
    //                 } :  {},
    //             ],
    //         }
    //     ];
    // }

    if ( auth.hasAnyAccess(['spotloadsview']) ) {
        items = [
            ...items,
            {
                label: props.t('load management'),
                icon: "pi pi-fw pi-verified",
                key: 11,
                items: [
                    auth.hasAnyAccess(['spotloadsview']) ? {
                        label: t("loads"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/loads",
                        key: 12,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess(['transportvehiclesview', 'driversview']) ) {
        items = [
            ...items,
            {
                label: props.t('fleet management'),
                icon: "pi pi-fw pi-truck",
                key: 13,
                items: [
                    auth.hasAnyAccess(['transportvehiclesview']) ? {
                        label: t("transport vehicles"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/transport/vehicles",
                        key: 14,
                    } : {},
                    auth.hasAnyAccess(['driversview']) ? {
                        label: t("drivers"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/drivers",
                        key: 15,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess(['routesview', 'brandsview', 'modelsview', 'locationsview']) ) {
        items = [
            ...items,
            {
                label: props.t('base management'),
                icon: "pi pi-fw pi-map",
                key: 16,
                items: [
                    auth.hasAnyAccess(['routesview']) ? {
                        label: t("routes"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/routes",
                        key: 17,
                    } : {},
                    auth.hasAnyAccess(['brandsview']) ? {
                        label: t("brands"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/brands",
                        key: 18,
                    } : {},
                    auth.hasAnyAccess(['modelsview']) ? {
                        label: t("models"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/models",
                        key: 19,
                    } : {},
                    auth.hasAnyAccess(['locationsview']) ? {
                        label: t("locations"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/locations",
                        key: 20,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess(['pricesview', 'contractsview']) ) {
        items = [
            ...items,
            {
                label: props.t('contract management'),
                icon: "pi pi-fw pi-dollar",
                key: 21,
                items: [
                    auth.hasAnyAccess(['pricesview']) ? {
                        label: t("prices"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/prices",
                        key: 22,
                    } : {},
                    auth.hasAnyAccess(['contractsview']) ? {
                        label: t("contracts"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/contracts",
                        key: 23,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess(['usersview','rolesview']) ) {
        items = [
            ...items,
            {
                label: props.t('access management'),
                icon: "pi pi-fw pi-users",
                key: 24,
                items: [
                    auth.hasAnyAccess(['usersview']) ? {
                        label: t("users"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/users",
                        key: 25,
                    } : {},
                    auth.hasAnyAccess(['rolesview']) ? {
                        label: t("roles"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/roles",
                        key: 26,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess(['clientsview', 'subcontractorsview', 'legalentitiesview']) ) {
        items = [
            ...items,
            {
                label: props.t('client management'),
                icon: "pi pi-fw pi-users",
                key: 27,
                items: [
                    auth.hasAnyAccess(['clientsview']) ? {
                        label: t("clients"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/clients",
                        key: 28,
                    } : {},
                    auth.hasAnyAccess(['clientsview']) ? {
                        label: t("pre payments"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/pre/payments",
                        key: 49,
                    } : {},
                    auth.hasAnyAccess(['subcontractorsview']) ? {
                        label: t("sub contractors"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/sub/contractors",
                        key: 29,
                    } : {},
                    auth.hasAnyAccess(['legalentitiesview']) ? {
                        label: t("legal entities"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/legal/entities",
                        key: 30,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess(['invoicesview', 'discountcodesview']) ) {
        items = [
            ...items,
            {
                label: props.t('invoicing'),
                icon: "pi pi-fw pi-inbox",
                key: 31,
                items: [
                    auth.hasAnyAccess(['invoicesview']) ? {
                        label: t("invoiced"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/invoices",
                        key: 32,
                    } : {},
                    auth.hasAnyAccess(['discountcodesview']) ? {
                        label: t("discount code"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/discount/codes",
                        key: 33,
                    } : {},
                ],
            }
        ];
    }


    if ( auth.hasAnyAccess(['webhooklogsview', 'transportvehiclesmomentlogsview', 'emaillogsview', 'invoicesview', 'cvsapilogsview']) ) {
        items = [
            ...items,
            {
                label: props.t('logs'),
                icon: "pi pi-fw pi-inbox",
                key: 34,
                items: [
                    auth.hasAnyAccess(['webhooklogsview']) ? {
                        label: t("webhook") + ' ' + t("sent"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/sent/webhooks/logs",
                        key: 35,
                    } : {},
                    auth.hasAnyAccess(['webhooklogsview']) ? {
                        label: t("webhook") + ' ' + t("received"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/received/webhooks/logs",
                        key: 36,
                    } : {},
                    auth.hasAnyAccess(['transportvehiclesmomentlogsview']) ? {
                        label: t("transport vehicle moment"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/transport/vehicles/moment/logs",
                        key: 37,
                    } : {},
                    auth.hasAnyAccess(['emaillogsview']) ? {
                        label: t("email logs"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/email/logs",
                        key: 38,
                    } : {},
                    auth.hasAnyAccess(['invoicesview']) ? {
                        label: t("pantheon logs"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/pantheon/transfer/logs",
                        key: 39,
                    } : {},
                    auth.hasAnyAccess(['cvsapilogsview']) ? {
                        label: t("cvs api logs"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/cvs/api/logs",
                        key: 40,
                    } : {},
                ],
            }
        ];
    }

    if ( auth.hasAnyAccess([
        'archivedalpspotloadsview',
        'archivedinvoicesview',
        'archivedordersview',
    ]) ) {
        items = [
            ...items,
            {
                label: props.t('archive'),
                icon: "pi pi-fw pi-inbox",
                key: 41,
                items: [
                    auth.hasAnyAccess(['archivedalpspotloadsview']) ? {
                        label: t("EF")+" "+t("spot loads"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/archived/alp/spot/loads",
                        key: 42,
                    } : {},
                    auth.hasAnyAccess(['archivedspotloadsview']) ? {
                        label: t("spot loads"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/archived/spot/loads",
                        key: 43,
                    } : {},
                    auth.hasAnyAccess(['archivedinvoicesview']) ? {
                        label: t("invoiced"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/archived/invoices",
                        key: 44,
                    } : {},
                    auth.hasAnyAccess(['archivedordersview']) ? {
                        label: t("orders"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/archived/orders",
                        key: 46,
                    } : {},
                ],
            },
        ];
    }

    if ( auth.hasAnyAccess(['transportvehiclesview']) ) {
        items = [
            ...items,
            {
                label: props.t('map view'),
                icon: "pi pi-fw pi-map",
                key: 45,
                items: [
                    auth.hasAnyAccess(['transportvehiclesview']) ? {
                        label: t("map"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/transport/vehicles/map",
                        key: 42,
                    } : {},
                ],
            },
        ];
    }

    if ( auth.hasAnyAccess(['emailsettingsupdate']) ) {
        items = [
            ...items,
            {
                label: props.t('settings'),
                icon: "pi pi-fw pi-cog",
                key: 47,
                items: [
                    auth.hasAnyAccess(['emailsettingsupdate']) ? {
                        label: t("email setting"),
                        icon: "pi pi-fw pi-envelope",
                        to: "/settings/email/settings",
                        key: 48,
                    } : {},
                ],
            },
        ];
    }

    //50
    model[0].items = items;

    return model;
}
