import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, FieldArray, Field } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "react-bootstrap";
import {
    assign, every, filter, find, findIndex, get, trimStart, uniq, uniqWith,
    groupBy, head, includes, intersection, isEmpty, last, map, orderBy, pick, sortBy,
} from "lodash";
import { Button } from "primereact/button";
import { DynamicObject } from "../../../../helpers/commonTypes";
import moment from "moment-timezone";
import WithRouter from "../../../../components/Common/WithRouter";
import { useSearchParams } from "react-router-dom";
import { formatDate, getTypeOfPrice, random, utcDateToLocalDate } from "../../../../utils/Helper";
import OrderPickerList from "./OrderPickerList";
import { Divider } from "primereact/divider";
import InputDateTime, { getDisplayFormat, onChangeDate } from "../../../../components/Shared/InputDateTime";
import Location from "../../../../components/Common/Display/Location";
import AddMissingRoutes from "./AddMissingRoutes";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { Tag } from "primereact/tag";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputField } from "../../../../components/Shared/InputField";
import { TreeSelectChangeEvent } from "primereact/treeselect";
import DatePicker from "react-datepicker";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import SpotLoadDocument from "./SpotLoadDocument";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
    router: any
}

type TInputForm = {
    id: null | any,
    price_type: string,
    cmr: null | string,
    s_price: null | any,
    per_load_is_billable: any,
    destination_location_id?: any,
    per_load_merge_route: any,
    load_orders: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({

    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    price_type: 'per_route',
    cmr: null,
    s_price: 0,
    per_load_is_billable: "no",
    per_load_merge_route: false,
    destination_location_id: null,
    load_orders: []
};

const DEFAULT_DROPDOWNS = {
    routes: [],
    drivers: [],
    locations: [],
    transportVehicles: [],
    spotLoadStatuses: [],
    spotLoadOrderStatuses: [],
    documentTypes: [],
    legalEntities: [],
    priceModels: [],
    pricePeriods: [],
    clients: [],
};

const LOAD_ORDER_ERROR_FIELDS = [
    // 'driver_id',
    'driver_ids',
    'transport_vehicle_id',
    'accident_location_id',
    'legal_entity_id',
    'estimate_loading_time',
    'estimate_unloading_time',
    'operation_date',
    'status',
    'route_id',
    'orders',
    'documents',
    'prev_status'
];

const DEFAULT_SPOT_LOAD_ROUTE_PRICE = {
    to_be_updated: true,
    is_calculated: false,
    price_id: null,
    price_period_id: null,
    loading_factors: 0,
    type_of_price: 2, // per load
    mode_of_transport: null,
    price_ftl: 0,
    price_spl: 0,
    input_price: 0,
    is_overridden: false,
    row_collapsed: false,
    raw_price: 0,
    total: 0,
    currency: "EUR",
    group_token: null,
    number_of_vehicles: 1,
    is_billable: false,
    is_billed: false,
    total_km: 0,
    truck_price_category: "ftl",
    group_index: 1,
};

const onKeyDownMultiSelect = (e: any) => {
    if ( e.ctrlKey && e.keyCode === 220 ) {
        const firstMultiselectItem: any = document.querySelector("body .p-multiselect-panel .p-multiselect-filter");
        if(firstMultiselectItem) {
            firstMultiselectItem.focus();
        }
    }

    if ( e.keyCode == 40 ) {
        if ( e.target.classList.contains('p-multiselect-filter') ) {
            const firstMultiselectItem: any = document.querySelector("body .p-multiselect-panel .p-multiselect-items li.p-multiselect-item .p-checkbox-input");
            if ( firstMultiselectItem ) {
                firstMultiselectItem.focus();
            }
        }

        if (
            e.target.classList.contains('p-checkbox-input')
            && !isEmpty(e.target.parentElement?.parentElement?.parentElement?.nextElementSibling)
        ) {
            e.target.parentElement.parentElement.parentElement.classList.remove('p-focus');
            e.target.parentElement.parentElement.parentElement.nextElementSibling.focus();
            e.target.parentElement?.parentElement?.parentElement?.nextElementSibling.classList.add('p-focus');
        }
    }

    if ( e.keyCode == 38 ) {
        if (
            e.target.classList.contains('p-checkbox-input')
            && !isEmpty(e.target.parentElement?.parentElement?.parentElement?.previousElementSibling)
        ) {
            e.target.parentElement.parentElement.parentElement.classList.remove('p-focus');
            e.target.parentElement?.parentElement?.parentElement?.previousElementSibling.focus();
            e.target.parentElement?.parentElement?.parentElement?.previousElementSibling.classList.add('p-focus');
        }
    }
}

function SpotLoadForm(props: IFormProps) {
    const { t, toastify, params, operationCancel } = props;
    const formRef: any = useRef();
    const globalCmrRef: any = useRef();
    const globalDriverRef: any = useRef();
    const globalTransportVehicleRef: any = useRef();
    const globalLegalEntityRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [orderPanel, setOrderPanel] = useState<any>(null);
    const [init, setInit] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>(null);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);
    const [searchParams] = useSearchParams();
    const [orders, setOrders] = useState<any>([]); // selected orders from OrderPicker panel
    const [estimatedLoading, setEstimatedLoading] = useState(null);
    const [driverId, setDriverId] = useState<any>(null);
    const [routes, setRoutes] = useState<DynamicObject>([]);
    // const [priceType, setPriceType] = useState('per_route');
    const [transportVehicleId, setTransportVehicleId] = useState<any>(null);
    const [legalEntityId, setLegalEntityId] = useState<any>(null);
    const [estimatedUnLoading, setEstimatedUnLoading] = useState(null);
    const [priceModel, setPriceModel] = useState<boolean>(false);
    const [multiPricesPeriods, setMultiPricesPeriods] = useState<any>([]);
    const [priceLegendModal, setPriceLegendModal] = useState<boolean>(false);
    const [ordersToBeAddedWithMissingRoute, setOrdersToBeAddedWithMissingRoute] = useState<any>([]);
    const [missingRoutes, setMissingRoutes] = useState<any>([]);
    const [confirmMissingRouteModalVisibility, setConfirmMissingRouteModalVisibility] = useState<boolean>(false);
    const [priceType, setPriceType] = useState('per_route');
    const [documentPanel, setDocumentPanel] = useState<any>(null);
    const [deletedDocuments, setDeletedDocuments] = useState<any>([]);

    const getDropdowns = async () => {
        setInitFormState({ ...DEFAULT_FORM_STATE });

        try {
            const response = await request({
                url: '/suppliers/spot/loads/create',
                params: {
                    type: 'create',
                    sub_type: 'suppliers'
                }
            });

            const { data } = response.data;
            setDropdowns({
                routes: map(data.routes, (obj: any) => assign({}, obj, { key: obj.id })),
                drivers: map(data.drivers, (obj: any) => assign({}, obj, {
                    key: obj.id,
                    label: obj.name,
                    is_system: obj.is_system
                })),
                locations: map(data.locations, (obj: any) => assign({}, obj, { key: obj.id })),
                transportVehicles: data.transportVehicles.map((entity: DynamicObject) => (
                    {
                        ...pick(entity, ['id', 'truck_plate_number', 'trailer_plate_number', 'truck_owner', 'active_journey', 'type',  'is_system', 'mode_of_transport']),
                        label: `${ entity.mode_of_transport } | ${ entity.truck_plate_number } | ${ entity.trailer_plate_number } | ${ get(entity, ['truck_owner', 'company_name']) } | ${ !isEmpty(get(entity, ['active_journey', 'load_number'])) ? get(entity, ['active_journey', 'load_number']) + ' ' + get(entity, ['active_journey', 'objectable_type']) : ''}`,
                        key: entity.id,
                        icon: (!isEmpty(entity.active_journey) ? 'fas fa-circle text-yellow-500' : 'fas fa-circle text-green-500'),
                        is_system: entity.is_system
                    }
                )),
                spotLoadStatuses: map(data.spotLoadStatuses, (obj: any) => assign({}, obj, { key: obj.id })),
                spotLoadOrderStatuses: map(data.spotLoadOrderStatuses, (obj: any) => assign({}, obj, { key: obj.id })),
                documentTypes: map(data.documentTypes, (obj: any) => assign({}, obj, { key: obj.id })),
                legalEntities: map(data.legalEntities, (obj: any) => assign({}, obj, { key: obj.id })),
                priceModels: data.priceModels,
                pricePeriods: data.pricePeriods,
                clients: map(data.clients, (obj: any) => assign({}, obj, { key: obj.id })),
                priceTypes: map(data.priceTypes, (obj: any) => assign({}, obj, { key: obj.id, label: obj.name })),
            });

            setRoutes(map(data.routes, (obj: any) => assign({}, obj, { key: obj.id })));
            if ( includes(['edit'], get(params, ['operation'])) ) {
                await onEdit(params.operationId);
            } else {
                setInit(true);
            }
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const values: any = payloads;
        values.load_orders = payloads.load_orders.map((item: any) => ( { ...item, route_id: item.route.id } ));

        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/spot/loads` : `/suppliers/spot/loads/${ payloads.id }` ),
                data: {
                    ...values,
                    step: 'spot-load-create',
                    // deleted_documents: deletedDocuments,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('invoice') }), "success");
            } else {
                toastify(t("record added", { item: t('invoice') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setEntity(null);
        setOrders([]);
        // make sure back_to and back_with are not blank and redirect with all parameters
        if ( !isEmpty(searchParams.get('back_to'))) {
            // @ts-ignore
            // parameters will have r as a prefix so remove it first
            const allParams: string = window.location.search.trim("?").split("&")
            .filter((param: any) => param.startsWith("r_"))
            .map((param: any) => trimStart(param, "r_"))
            .join("&")
            props.router.navigate(`/${searchParams.get('back_to')}?${allParams}`);
            return;
        }

        onReset();
        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/spot/loads/${ id }/edit`
            });

            const { data } = response.data;
            setEntity(data);

            const formData: TInputForm = {
                id: data.id,
                price_type: data.price_type ?? "per_route",
                cmr: data.cmr ?? null,
                destination_location_id: get(data, ['destination_location', 'id']),
                s_price: data.s_price ?? 0,
                per_load_is_billable: data.per_load_is_billable ?? "no",
                per_load_merge_route: data.per_load_merge_route ?? false,
                load_orders: []
            };

            setPriceType(formData.price_type);
            const shallowOrders: any = [];
            map(groupBy(sortBy(data.load_orders, 'group_index'), 'group_token'), (orders: any) => {
                const single: any = head(orders);
                let operation_date: any = moment().format('YYYY-MM-DD HH:mm:ss');

                if ( single.status === 'in-transit' && !isEmpty(single.in_transit_at) ) {
                    operation_date = utcDateToLocalDate(single.in_transit_at, 'YYYY-MM-DD HH:mm:ss');
                }

                if ( single.status === 'delivered' && !isEmpty(single.delivered_at) ) {
                    operation_date = utcDateToLocalDate(single.delivered_at, 'YYYY-MM-DD HH:mm:ss');
                }

                orders.map((item: any) => shallowOrders.push({
                    ...item.order,
                    item_status: item.status,
                    route_id: item.route.id,
                    group_token: single.group_token
                }));

                formData.load_orders.push({
                    group_token: single.group_token,
                    orders: orders.map(({ order }: any) => order.id),
                    order_unit_price: orders.map((order: any) => ({
                        order_id: order.order.id,
                        total: Number(order.total ?? 0)
                    })),
                    documents: single.documents,
                    has_an_accident: single.has_an_accident ?? false,
                    db_has_an_accident:  single.has_an_accident ?? false,
                    mode_of_transport:  single.mode_of_transport ?? false,
                    status: get(single, ['status']),
                    db_status: get(single, ['status']),
                    prev_status: get(single, ['prev_status']),
                    is_delivered: ( single.status === 'delivered' ) ? 1 : 0,
                    is_billable: single.is_billable ?? false,
                    is_billed: single.is_billed ?? false,
                    is_intransit: ( single.status === 'in-transit' ) ? 1 : 0,
                    order_ui: "list",
                    driver_ids: single.load_order_drivers.map((item: any) => item.driver_id),
                    route: {
                        id: get(single, ['route', 'id']),
                        title: get(single, ['route', 'title']),
                        from_location_id: get(single, ['route', 'from_location', 'id']),
                        to_location_id: get(single, ['route', 'to_location', 'id']),
                    },
                    is_selected: !includes(['delivered', 'discarded'], get(single, ['status'])) ? single.is_selected > 0 : false,
                    transport_vehicle_id: get(single, ['transport_vehicle', 'id']),
                    legal_entity_id: get(single, ['legal_entity', 'id']),
                    estimate_loading_time: single.estimate_loading_time ? utcDateToLocalDate(single.estimate_loading_time, 'YYYY-MM-DD HH:mm:ss') : null,
                    estimate_unloading_time: single.estimate_unloading_time ? utcDateToLocalDate(single.estimate_unloading_time, 'YYYY-MM-DD HH:mm:ss') : null,
                    operation_date: operation_date,
                    accident_location_id: get(single, ['route', 'to_location', 'id']),
                    legal_entity: get(single, ['legal_entity']),
                    group_index: get(single, ['group_index']),
                    journeys: orders.map(
                        (item: any) => ( { order_journey_id: item.active_order_journey_id, order_id: item.order.id } )
                    ),
                    spot_load_route_price: {
                        to_be_updated: get(single.spot_load_route_price, ['to_be_updated']) ?? true,
                        currency: get(single.spot_load_route_price, ['currency']) ?? "EUR",
                        group_token: get(single.spot_load_route_price, ['group_token']) ?? single.group_token,
                        loading_factors: get(single.spot_load_route_price, ['loading_factors']) ?? orders.length,
                        number_of_vehicles: get(single.spot_load_route_price, ['number_of_vehicles']) ?? orders.length,
                        price_ftl: get(single.spot_load_route_price, ['price_ftl']) ?? 0,
                        price_id: get(single.spot_load_route_price, ['price_id']) ?? null,
                        price_period_id: get(single.spot_load_route_price, ['price_period_id']) ?? null,
                        price_spl: get(single.spot_load_route_price, ['price_spl']) ?? null,
                        truck_price_category: get(single.spot_load_route_price, ['truck_price_category']) ?? "ftl",
                        is_overridden: get(single.spot_load_route_price, ['is_overridden']) ?? false,
                        input_price: get(single.spot_load_route_price, ['input_price']) ?? 0,
                        raw_price: get(single.spot_load_route_price, ['raw_price']) ?? 0,
                        total: get(single.spot_load_route_price, ['total']) ?? 0,
                        total_km: get(single.spot_load_route_price, ['total_km']) ?? 0,
                        type_of_price: get(single.spot_load_route_price, ['type_of_price']) ?? 2,
                        is_calculated: get(single.spot_load_route_price, ['is_calculated']) ?? false,
                    },
                });
            });

            // console.log(formData.load_orders)
            setOrders(shallowOrders);
            setInitFormState(formData);
            setInit(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onOpenOrderPanel = (row: any, index: number) => {
        const rnm = row.group_token + '~~~' + index; // to give index as well
        setOrderPanel(rnm);
    };

    const onGlobalUnloadClick = () => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders.map(function(load_order: any) {
            if ( load_order.status === 'in-transit' ) {
                return {
                    ...load_order,
                    operation_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    status: "delivered",
                    is_selected: false,
                };
            }

            return load_order;
        });

        setInitFormState({ ...base, load_orders: shallowOrders });
    }

    // when order selected from OrderPicker panel
    const addSelectedOrders = (selectedOrders: any, routeId: string, index: string | number) => {
        let freshOrders: any = [];
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;
        const item = shallowOrders[index];
        // console.log(item)
        selectedOrders.map((order: any) => {
            // if order doesn't exists already only then add to a form
            if (
                filter(orders, (existingOrder: any) => (
                    existingOrder.id === order.id &&
                    existingOrder.route_id == routeId &&
                    existingOrder.item_status !== 'delivered'
                )).length <= 0
            ) {
                freshOrders.push({
                    ...order,
                    route_id: routeId,
                    item_status: item.status,
                    group_token: item.group_token
                });
            }
        });

        // avoid duplicate
        const allOrders = [...freshOrders, ...orders]
        .filter(({ id, route_id, group_token }: any) =>
            ( !includes(item.orders, id) && route_id === item.route.id && group_token === item.group_token )
        );

        // console.log(allOrders)
        setOrders([...freshOrders, ...orders]);
        item.orders = [...item.orders, ...allOrders.map(({ id }: any) => id)];
        shallowOrders[index] = item;
        shallowOrders[index].spot_load_route_price.to_be_updated = true;
        // console.log(shallowOrders[index].spot_load_route_price)
        shallowOrders[index].spot_load_route_price = getPriceFromPrices(
            shallowOrders[index],
            [...freshOrders, ...orders],
            false, base.price_type
        );
        // console.log(shallowOrders[index].spot_load_route_price);

        let destination = base.destination_location_id;
        if ( isEmpty(destination) ) {
            map([...freshOrders, ...orders], ({ order_journeys }: any) => {
                const exist = find(order_journeys, { is_final_journey: true });
                if ( !isEmpty(exist) ) {
                    destination = exist.to_location.id;
                }
            });
        }

        setInitFormState({ ...base, load_orders: shallowOrders, destination_location_id: null }); // destination_location_id: destination
        setOrderPanel(null);
    };

    const getPriceFromPrices = (selectedRow: any, allOrders: any, isChanged: any = false, priceType: string = 'per_load') => {
        let _price = { ...DEFAULT_SPOT_LOAD_ROUTE_PRICE };

        if ( priceType === 'per_load' ) {
            return _price;
        }

        if ( !isEmpty(selectedRow.spot_load_route_price) ) {
            // if edit mode and somebody adding a new order then must not effect to existing records
            if ( !selectedRow.spot_load_route_price.to_be_updated ) {
                return selectedRow.spot_load_route_price;
            }

            _price = { ...DEFAULT_SPOT_LOAD_ROUTE_PRICE, ...selectedRow.spot_load_route_price };
        }

        // console.log(selectedRow)
        // default if no transport vehicle
        if ( isEmpty(selectedRow.transport_vehicle_id) ) {
            return _price;
        }

        // get orders and compare models based on that find price id from dropdown.prices
        const _orders = allOrders.filter((order: any) => includes(selectedRow.orders, order.id));
        const _models = _orders.map(({ brand_model }: any) => brand_model.id);
        const connectedPriceIds: Array<String>|undefined = find(
            dropdowns.priceModels,
            (price: any) => (every(_models, (m: any) => includes(price.models, m)) && includes(price.price_routes, selectedRow.route.id))
        );
        let hasPrice = false;

        if ( !isEmpty(connectedPriceIds) ) {
            // if price found then pick first and get the data;
            const transportType: String = find(dropdowns.transportVehicles, {id: selectedRow.transport_vehicle_id}).mode_of_transport || "";
            const pricePeriods: Array<any>|undefined = dropdowns.pricePeriods.filter((period: any) => {
                return (
                    includes(connectedPriceIds, period.price_id)
                    && transportType !== ""
                    && transportType === period.mode_of_transport
                    && selectedRow.route.id === period.route_id
                );
            });

            // pick first from prices
            let pricePeriod = head(pricePeriods);
            // I'm in edit and I'm trying to adding a new order and then change input price
            // that means if we have match with current data then use it
            if ( selectedRow.spot_load_route_price && selectedRow.spot_load_route_price.price_period_id
                && !isEmpty(find(pricePeriods, { price_period_id: selectedRow.spot_load_route_price.price_period_id }))
            ) {
                pricePeriod = find(pricePeriods, { price_period_id: selectedRow.spot_load_route_price.price_period_id });
            }

            // if I selected price
            // and updating a input text value that means PricePeriod must be same instead of new one
            if ( isChanged && selectedRow.spot_load_route_price.price_period_id ) {
                pricePeriod = find(dropdowns.pricePeriods, { price_period_id: selectedRow.spot_load_route_price.price_period_id });
            }

            if ( !isEmpty(pricePeriod) ) {
                // if found
                _price.price_id = pricePeriod.price_id;
                _price.price_period_id = pricePeriod.price_period_id;
                _price.number_of_vehicles = selectedRow.orders.length;
                _price.loading_factors = pricePeriod.loading_factors;
                _price.type_of_price = pricePeriod.type_of_price;
                _price.group_token = selectedRow.group_token;
                _price.currency = pricePeriod.currency;
                _price.price_ftl = ((isChanged && _price.truck_price_category === 'ftl') ? Number(selectedRow.spot_load_route_price.input_price ?? 0) : Number(pricePeriod.price_ftl));
                _price.price_spl = (((isChanged && _price.truck_price_category === 'spl')) ? Number(selectedRow.spot_load_route_price.input_price ?? 0) : Number(pricePeriod.price_spl));
                _price.is_overridden = ((selectedRow.spot_load_route_price && selectedRow.spot_load_route_price.input_price) && Number(selectedRow.spot_load_route_price.input_price) !== Number(pricePeriod.price_ftl));
                _price.truck_price_category = (selectedRow.spot_load_route_price && selectedRow.spot_load_route_price.truck_price_category) ? selectedRow.spot_load_route_price.truck_price_category : "ftl";
                _price.raw_price = (_price.truck_price_category === 'ftl') ? Number(pricePeriod.price_ftl) : Number(((pricePeriod.price_spl * selectedRow.orders.length).toFixed(2)));
                _price.mode_of_transport = pricePeriod.mode_of_transport;
                _price.total = _price.raw_price;

                if ( !isChanged ) { // means not changed from textbox
                    _price.input_price = ( _price.truck_price_category === 'ftl' ) ? Number(pricePeriod.price_ftl) : Number(pricePeriod.price_spl);
                    const alreadyInputPrice = get(selectedRow, ['spot_load_route_price', 'input_price']);
                    if ( alreadyInputPrice && alreadyInputPrice > 0 ) {
                        _price.input_price = alreadyInputPrice;
                    }
                } else {
                    _price.total = (_price.input_price > 0)
                        ? (_price.truck_price_category === 'ftl' ? _price.input_price : (_price.input_price * selectedRow.orders.length))
                        : _price.raw_price;
                }

                hasPrice = true;
            }
        }

        if ( !hasPrice ) {
            // if not found
            _price.price_id = null;
            _price.price_period_id = null;
            _price.number_of_vehicles = _price.loading_factors = selectedRow.orders.length;
            _price.type_of_price = 2;
            _price.truck_price_category = get(selectedRow, ['spot_load_route_price', 'truck_price_category']) ?? "spl";
            _price.price_spl = (_price.truck_price_category === "spl") ? Number(_price.input_price) : 0;
            _price.price_ftl = (_price.truck_price_category === "ftl") ? Number(_price.input_price) : 0;
            _price.is_overridden = false;
            _price.raw_price = (_price.truck_price_category !== "ftl") ? Number(_price.price_spl * parseInt(selectedRow.orders.length)) : Number(_price.price_ftl);
            _price.group_token = selectedRow.group_token;
            _price.currency = 'EUR';
            _price.mode_of_transport = find(dropdowns.transportVehicles, {id: selectedRow.transport_vehicle_id}).mode_of_transport;
            // @ts-ignore
            // _price.total = Number(parseFloat(_price.raw_price) * parseInt(selectedRow.orders.length));
            _price.total = _price.raw_price;
            _price.price_ftl = 0;
        }

        return _price;
    }

    const addOrdersWithRoutes = (selectedOrders: any, _routes: any = []) => {
        const newToBeAdded: any = [];
        const existingToBeUpdated: any = [];
        const base: any = get(formRef.current, ['values']);
        const shallowLoadOrders: any = [...base.load_orders];
        const ordersToBeAdded: any = [];

        // get first order which is added into load and contains the data so we can pre-fill into other
        const firstLoadOrder = head(base.load_orders.filter((item: any) => !includes(['delivered', 'pending'], item.status) ));
        const _driverIds = driverId ? [driverId] : get(firstLoadOrder, ['driver_ids']);
        const _transportVehicleId = transportVehicleId ?? get(firstLoadOrder, ['transport_vehicle_id']);
        const _estimatedLoading = estimatedLoading ?? get(firstLoadOrder, ['estimate_loading_time']);
        const _estimatedUnLoading = estimatedUnLoading ?? get(firstLoadOrder, ['estimate_unloading_time']);
        const _legalEntityId = legalEntityId ?? get(firstLoadOrder, ['legal_entity_id']);
        const missingLocations: any = [];
        const missingRouteOrders: any = [];
        const allRoutes: any = (!isEmpty(_routes) ? _routes : routes);
        setOrdersToBeAddedWithMissingRoute([]);
        setMissingRoutes([]);
        setConfirmMissingRouteModalVisibility(false);
        let extraJourneys: any = [];
        selectedOrders.map((order: any) => {
            const { current_journey, order_journeys } = order;

            // if current_journey is already exists then skip
            if ( shallowLoadOrders.filter((item: any) => find(item.journeys, {order_id: order.id, order_journey_id: current_journey.token })).length > 0 ) {
                return undefined;
            };

            // find a route from system based on current journey
            const systemRoute = allRoutes
            .filter(({ from_location, to_location }: any) => (
                from_location.id === current_journey.from_location.id && to_location.id === current_journey.to_location.id )
            );

            // collect all other journey expect pending
            const pending_journeys_expect_current = order_journeys.filter((journey: any) => (
                journey.status === "pending"
                && journey.token != current_journey.token
                && (!isEmpty(journey.from_location) && !isEmpty(journey.to_location))
            ));

            extraJourneys = [...extraJourneys, ...pending_journeys_expect_current];

            if ( !isEmpty(systemRoute) ) {
                const hasIndex = findIndex(shallowLoadOrders, (
                    { route, status }: any) => ( route.id === systemRoute[0].id && !includes(['delivered', 'pending'], status) )
                );

                if ( hasIndex >= 0 ) {
                    if ( !shallowLoadOrders[hasIndex].is_billed ) {
                        existingToBeUpdated.push({ order, route_id: systemRoute[0].id });
                    }
                }

                if ( hasIndex < 0 ) {
                    newToBeAdded.push({ order, route_id: systemRoute[0].id });
                }
            } else if(isEmpty(_routes)) {
                missingRouteOrders.push(order);

                if ( !find(missingLocations, {
                    from_location_id: current_journey.from_location.id,
                    to_location_id: current_journey.to_location.id,
                }) ) {
                    missingLocations.push({
                        from_location_id: current_journey.from_location.id,
                        to_location_id: current_journey.to_location.id,
                    });
                }
            }
        });

        // extract other journeys based on that check how many routes are missing
        uniqWith(extraJourneys, (a: any, b: any) => (
            a.from_location.id === b.from_location.id && a.to_location.id === b.to_location.id
        ))
        .filter((journey: any) => {
            const {from_location, to_location} = journey;

            return allRoutes.filter((route: any) =>
                route.from_location.id == from_location.id
                && route.to_location.id == to_location.id).length <= 0;
        })
        .map((journey: any) => {
            missingLocations.push({
                from_location_id: journey.from_location.id,
                to_location_id: journey.to_location.id,
            });
        });

        // based on current_journey create route group and add order into that
        map(groupBy(newToBeAdded, ({ route_id }: any) => route_id), (group: any, index: any) => {
            const timestamp = moment().unix();
            const token = timestamp + '_' + random(28 - ( `${ timestamp }`.length )).toLowerCase();
            const systemRoute = find(allRoutes, { id: index });
            group.map(({ order }: any) => ordersToBeAdded.push({
                ...order, route_id: systemRoute.id,
                item_status: 'announced',
                group_token: token,
            }));
            shallowLoadOrders.push({
                group_token: token,
                orders: group.map(({ order }: any) => order.id),
                order_unit_price: group.map(({ order }: any) => ({
                    order_id: order.id,
                    total: 0,
                })),
                documents: [],
                status: 'announced',
                prev_status: 'announced',
                db_status: null,
                row_collapsed: false,
                order_ui: "list",
                route: {
                    id: systemRoute.id,
                    title: systemRoute.title,
                    from_location_id: systemRoute.from_location.id,
                    to_location_id: systemRoute.to_location.id,
                },
                is_selected: true,
                is_billable: false,
                is_billed: false,
                has_an_accident: false,
                driver_ids: _driverIds,
                transport_vehicle_id: _transportVehicleId,
                estimate_loading_time: _estimatedLoading,
                estimate_unloading_time: _estimatedUnLoading,
                legal_entity_id: _legalEntityId,
                operation_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                accident_location_id: null,
                journeys: group.map(({ order }: any) => {
                    return {
                        order_journey_id: get(find([...orders, ...ordersToBeAdded], {id: order.id}), ['current_journey', 'token']),
                        order_id: order.id
                    };
                })
            });
        });

        // if route group is already exists then add order into that route
        map(groupBy(existingToBeUpdated, ({ route_id }: any) => route_id), (group: any, index: any) => {
            const newOrders = group.map(({ order }: any) => order.id);
            const hasIndex = findIndex(shallowLoadOrders, ({ route, status }: any) =>
                ( route.id === index && !includes(['delivered', 'pending'], status) )
            );

            const existingOrders = shallowLoadOrders[hasIndex].orders;
            const uniqueNewOrders = newOrders.filter((id: string) => !includes(existingOrders, id));
            const finalOrders = [...existingOrders, ...uniqueNewOrders];
            shallowLoadOrders[hasIndex] = {
                ...shallowLoadOrders[hasIndex],
                orders: finalOrders,
                journeys: group.map(({ order }: any) => {
                    return {
                        current_journey_id: get(find([...orders, ...ordersToBeAdded], {id: order.id}), ['current_journey', 'id']),
                        order_id: order.id
                    };
                })
            };

            shallowLoadOrders[hasIndex].order_unit_price = finalOrders.map((item: any) => {
                let defaultUnit = {
                    order_id: item,
                    total: 0
                }

                const existsAlreadyUnitPrice = find(shallowLoadOrders[hasIndex].order_unit_price, { order_id: item });

                if(!isEmpty(existsAlreadyUnitPrice)) {
                    defaultUnit = existsAlreadyUnitPrice;
                }

                return defaultUnit;
            });

            group.filter(({ order }: any) => includes(uniqueNewOrders, order.id)).map((item: any) => ordersToBeAdded.push(item.order));
        });

        setOrders([...orders, ...ordersToBeAdded]);

        const loadOrderWithPrice = shallowLoadOrders.map((loadOrder: any) => {
            return {
                ...loadOrder,
                spot_load_route_price: getPriceFromPrices(loadOrder, [...orders, ...ordersToBeAdded], false, base.price_type)
            }
        })

        let destination = null; //base.destination_location_id;
        // if ( isEmpty(destination) ) {
        //     map([...orders, ...ordersToBeAdded], ({ order_journeys }: any) => {
        //         const exist = find(order_journeys, { is_final_journey: true });
        //         if ( !isEmpty(exist) ) {
        //             destination = exist.to_location.id;
        //         }
        //     });
        // }

        setOrderPanel(null);

        // if all routes not exists into a system then add load orders
        if ( !isEmpty(missingLocations) ) {
            setInitFormState({
                ...base,
                load_orders: loadOrderWithPrice.map(
                    (_: any, index: number) => ({..._, group_index: index + 1})
                ),
                destination_location_id: destination
            });
            setOrdersToBeAddedWithMissingRoute(missingRouteOrders);
            setMissingRoutes(missingLocations);
            setConfirmMissingRouteModalVisibility(true);
        } else {
            setInitFormState({
                ...base,
                load_orders: loadOrderWithPrice.map(
                    (_: any, index: number) => ({..._, group_index: index + 1})
                ),
                destination_location_id: destination
            });

            // if they are doing edit
            if ( !entity || !entity.id ) {
                addPlannedOrders(
                    [...orders, ...ordersToBeAdded],
                    // @ts-ignore
                    [...allRoutes, ...routes], loadOrderWithPrice
                );
            }
        }

        if ( globalCmrRef.current ) {
            globalCmrRef.current.focus();
        }

        return;
    };

    const addPlannedOrders = (allOrders: any, allRoutes: any, loadOrders: any) => {
        const base: any = get(formRef.current, ['values']);
        const allToken: string[] = [];

        // pick orders from scratch always
        loadOrders
        .filter((item: any) => item.status === "announced")
        .map(({ journeys }: any) => journeys.map((a: any) => allToken.push(String(a.order_journey_id))))

        // collect all order's all journeys(after current active)
        const ordersWithJourneys: any = [];
        for(let i = 0; i < allOrders.length; i++) {
            const order = allOrders[i];
            // make sure to take only pending journeys
            // also only pick a journey which are after current journey
            const pendingJourneys: any = filter(
                order.order_journeys.filter((journey: any) => journey.status === "pending"),
                (journey: any) => parseInt(journey.token) > order.current_journey.token
            )
            .filter((item: any) => !includes(allToken, item.token));

            if ( pendingJourneys.length > 0 ) {
                ordersWithJourneys.push({
                    id: order.id,
                    order_id: order.order_cd,
                    vin_number: order.vin_number,
                    journeys: sortBy(pendingJourneys, 'token'),
                    total: pendingJourneys.length
                });
            }
        }

        if ( isEmpty(ordersWithJourneys) ) {
            return undefined;
        }

        const newGroup: any = [];
        let round = 1;
        // loop till the highest length from all journeys
        for (let i = 0; i < last(sortBy(ordersWithJourneys, 'total')).total; i++) {
            map(ordersWithJourneys, (order: any) => {
                // make sure journeys[i] is exists
                if ( !isEmpty(order.journeys[i]) ) {
                    const route: any = find(allRoutes, (route: any) => (
                        route.from_location.id === order.journeys[i].from_location.id
                        && route.to_location.id === order.journeys[i].to_location.id
                    ));

                    // based on round create a group
                    if ( !isEmpty(route) && isEmpty(find(newGroup, { round: round, route_id: route.id })) ) {
                        newGroup.push({
                            activeLength: i,
                            route_id: route.id,
                            route,
                            round,
                            orders: []
                        });
                    }
                }
            });
            round++;
        }

        map(newGroup, (group: any, index: number) => {
            map(ordersWithJourneys, (order: any, orderIndex: number) => {
                if ( !isEmpty(order.journeys[group.activeLength])
                    &&
                    (
                        group.route.from_location.id === order.journeys[group.activeLength].from_location.id
                        && group.route.to_location.id === order.journeys[group.activeLength].to_location.id
                    )) {
                    newGroup[index].orders = [...newGroup[index].orders, order];
                    newGroup[index].journeys = [...(newGroup[index].journeys ?? []), {
                        order_journey_id: order.journeys[group.activeLength].token,
                        order_id: order.id
                    }];
                    newGroup[index].order_unit_price = [
                        ...newGroup[index].order_unit_price ?? [],
                        {
                            order_id: order.id,
                            total: 0
                        }
                    ];
                }
            });
        });

        const shallowLoadOrders: any = [];
        map(newGroup, (group: any, index: number) => {
            const timestamp = moment().unix();
            const token = timestamp + '_' + random(28 - ( `${ timestamp }`.length )).toLowerCase();
            const systemRoute = find(allRoutes, { id: group.route_id })
            const orders = group.orders.map(({vin_number}: any) => find(allOrders, (order: any) => order.vin_number === vin_number));
            const anyOtherOrder = loadOrders
            .filter((item: any) => item.status !== "pending")
            .filter((item: any) => intersection(orders.map((i: any) => i.id), item.orders).length > 0)
            const driverId = get(head(dropdowns.drivers.filter((item: any) => item.is_system)), ['id']) ?? undefined;
            const transportVehicle = head(dropdowns.transportVehicles.filter((item: any) => item.is_system));
            const transportVehicleId = get(transportVehicle, ['id']) ?? undefined;

            const loadOrder: any = {
                group_token: token,
                orders: orders.map((item: any) => item.id),
                order_unit_price: orders.map((o: any) => ({
                    order_id: o.id,
                    total: 0,
                })),
                documents: [],
                status: 'pending',
                prev_status: 'pending',
                db_status: null,
                order_ui: "list",
                route: {
                    id: systemRoute.id,
                    title: systemRoute.title,
                    from_location_id: systemRoute.from_location.id,
                    to_location_id: systemRoute.to_location.id,
                },
                is_selected: true,
                row_collapsed: false,
                is_billable: false,
                is_billed: false,
                has_an_accident: false,
                driver_ids: (driverId ? [driverId] : (get(head(anyOtherOrder), ['driver_ids']) ?? [])) ,
                transport_vehicle_id: transportVehicleId ?? (get(head(anyOtherOrder), ['transport_vehicle_id']) ?? null),
                estimate_loading_time: get(head(anyOtherOrder), ['estimate_loading_time']) ?? null,
                estimate_unloading_time: get(head(anyOtherOrder), ['estimate_unloading_time']) ?? null,
                legal_entity_id: get(transportVehicle, ['truck_owner', 'id']) ?? null,
                operation_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                accident_location_id: null,
                journeys: group.journeys
            }

            shallowLoadOrders.push({
                ...loadOrder,
                spot_load_route_price: getPriceFromPrices(loadOrder, allOrders, false, base.price_type)
            });
        });
        setInitFormState({ ...base, load_orders: [
            ...loadOrders.filter((i: any) => i.status !== "pending"), // pass only main route group
            ...shallowLoadOrders.map((item: any, index: number) => ({...item, group_index: index + 1}))
        ] });
    }

    const onChangeCommonField = (value: any, filed: string) => {
        if ( filed === 'route_id' ) {
            return onChangeRoute(value);
        }

        if ( filed === 'driver_id' ) {
            const _dropdowns = { ...dropdowns }
            const base: any = get(formRef.current, ['values']);
            let ids: any = [];

            base.load_orders.forEach((item: any) => {
                if (item.driver_ids) {
                    ids.push(...item.driver_ids);
                }
            });

            const drivers: any =  orderBy(_dropdowns.drivers, (driver: any) => {
                const index = ids.indexOf(driver.id);
                return index === -1 ? _dropdowns.drivers.length : index;
            });

            setDropdowns({ ..._dropdowns, drivers });
            return onChangeDriver(value);
        }

        if ( filed === 'transport_vehicle_id' ) {
            return onChangeTransportVehicle(value);
        }

        if ( filed === 'legal_entity_id' ) {
            return onChangeLegalEntity(value);
        }

        if ( filed === 'price_type' ) {
            return onChangePriceType(value);
        }
    };

    const onChangeRoute = (value: any, updateForm: boolean = true) => {
        if ( isEmpty(value) ) {
            return;
        }

        const base: any = get(formRef.current, ['values']);
        const shallowLoadOrders: any = base.load_orders;
        const hasIndex = findIndex(shallowLoadOrders, (
            {
                route,
                status
            }: any) => ( route.id === value.id && status !== 'delivered' ));

        if ( hasIndex < 0 ) {
            const timestamp = moment().unix();
            shallowLoadOrders.push({
                group_token: timestamp + '_' + random(28 - ( `${ timestamp }`.length )).toLowerCase(),
                orders: [],
                documents: [],
                status: 'announced',
                prev_status: 'announced',
                db_status: null,
                driver_id: driverId,
                order_ui: "list",
                route: {
                    id: value.id,
                    title: value.title,
                    from_location_id: value.from_location.id,
                    to_location_id: value.to_location.id,
                },
                is_selected: true,
                is_billable: false,
                row_collapsed: false,
                is_billed: false,
                has_an_accident: false,
                transport_vehicle_id: transportVehicleId,
                estimate_loading_time: estimatedLoading,
                estimate_unloading_time: estimatedUnLoading,
                operation_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                accident_location_id: null
            });

            const result = {
                ...base,
                load_orders: shallowLoadOrders
            };

            if ( updateForm ) {
                setInitFormState(result);
                return true;
            }

            return result;
        }

        return false;
    };

    const onChangeDriver = (value: any) => {
        if ( isEmpty(value) || isEmpty(value.id) ) {
            setDriverId(null);
            return;
        }

        const systemDriver = find(dropdowns.drivers, { is_system: true });
        const base: any = get(formRef.current, ['values']);

        const shallowLoadOrders: any = base.load_orders;
        const updated = shallowLoadOrders
        .map((item: any) => {
            // if status is pending then no change
            if ( item.status === "pending" ) {
                return item;
            }

            return ( !includes(['delivered'], item.status) && item.is_selected ) ? {
                ...item,
                driver_ids: uniq([...(item.driver_ids ? item.driver_ids : []), value.id]).filter((item: any) => !includes([get(systemDriver, ['id'])], item))
            } : item;
        });

        // setDriverId(null);
        setInitFormState({ ...base, load_orders: updated });
    };

    const onChangeTransportVehicle = (value: any) => {
        if ( isEmpty(value) || isEmpty(value.id) ) {
            setTransportVehicleId(null);
            return;
        }

        const base: any = get(formRef.current, ['values']);
        const shallowLoadOrders: any = base.load_orders;
        const transportVehicle = find(dropdowns.transportVehicles, {id: value.id});

        const updated = shallowLoadOrders
        .map((item: any) => {
            // if status is pending then no change
            if ( item.status === "pending" ) {
                return item;
            }

            if ( !includes(['delivered'], item.status) && item.is_selected ) {
                const updatedItem = {
                    ...item,
                    transport_vehicle_id: value.id,
                    legal_entity_id: get(transportVehicle, ['truck_owner', 'id']),
                }

                updatedItem.spot_load_route_price = getPriceFromPrices(updatedItem, orders, false, base.price_type);
                return updatedItem;
            }

            return item;
        });

        // setTransportVehicleId(null);

        if ( !isEmpty(get(transportVehicle, ['truck_owner', 'id'])) ) {
            setLegalEntityId(get(transportVehicle, ['truck_owner', 'id']));
        }

        setInitFormState({ ...base, load_orders: updated });
    };

    const onChangeLegalEntity = (value: any) => {
        if ( isEmpty(value) || isEmpty(value.id) ) {
            setLegalEntityId(null);
            return;
        }

        const base: any = get(formRef.current, ['values']);
        const shallowLoadOrders: any = base.load_orders;
        const legalEntity = find(dropdowns.legalEntities, {id: value.id})

        if ( isEmpty(legalEntity) ) {
            return;
        }

        const updated = shallowLoadOrders
        .map((item: any) => {
            return ( !includes(['delivered'], item.status) && item.is_selected ) ? {
                ...item,
                legal_entity_id: legalEntity.id,
                legal_entity: legalEntity,
            } : item;
        });

        setLegalEntityId(null);
        setInitFormState({ ...base, load_orders: updated });
    };

    const onChangePriceType = (value: any) => {
        setPriceType(value.id);
        const base: any = get(formRef.current, ['values']);
        let shallowOrders: any = base.load_orders;

        if ( value.id === 'per_route' ) {
            shallowOrders = base.load_orders.map((item: any) => {
                const _item = { ...item };
                _item.spot_load_route_price.to_be_updated = true;
                _item.spot_load_route_price.input_price = 0;

                const spot_load_route_price = getPriceFromPrices(
                    _item,
                    orders,
                    false,
                    'per_route'
                );

                return { ..._item, spot_load_route_price: spot_load_route_price };
            });
        } else {
            // console.log('else')
        }

        setInitFormState({
            ...base,
            price_type: value.id,
            load_orders: shallowOrders
        });
    }

    const onChangeEstimate = (value: any, field = 'estimate_loading_time') => {
        if ( value == '' || value == null || typeof value == undefined ) {
            if ( field === 'estimate_loading_time' ) {
                setEstimatedLoading(null);
            }

            if ( field === 'estimate_unloading_time' ) {
                setEstimatedUnLoading(null);
            }
            return;
        }

        let _value = onChangeDate(value, { type: 'datetime', toString: 1 });
        // to set a default time value
        if ( moment(value).format("HH:mm:ss") === "00:00:00" ) {
            _value = onChangeDate(
                moment(moment(value).format('YYYY-MM-DD ') + moment().format('HH:mm:ss')).toString(),
                { type: 'datetime', toString: 1 }
            );
        }

        const base: any = get(formRef.current, ['values']);
        const shallowLoadOrders: any = base.load_orders;

        const updated = shallowLoadOrders
        .map((item: any) => {
            return ( !includes(['delivered'], item.status) && item.is_selected ) ? {
                ...item,
                [field]: moment(_value).format('YYYY-MM-DD HH:mm:ss')
            } : item;
        });

        if ( field === 'estimate_loading_time' ) {
            setEstimatedLoading(_value);
        }

        if ( field === 'estimate_unloading_time' ) {
            setEstimatedUnLoading(_value);
        }

        setInitFormState({ ...base, load_orders: updated });
    };

    const onCloseMissingRouteModal = (addedRoutes: any, orders: any) => {
        setConfirmMissingRouteModalVisibility(false);
        setOrdersToBeAddedWithMissingRoute([]);

        if ( isEmpty(addedRoutes) ) {
            return toastify(t("route missing!"), "error");
        }

        // @ts-ignore
        setRoutes([...routes, ...addedRoutes]);
        addOrdersWithRoutes(orders, addedRoutes);
        setTimeout(() => (document.body.style.overflow = 'unset'), 500);
    }

    const onOpenDocumentPanel = (row: any, index: number) => {
        const rnm = row.group_token + '~~~' + index; // to give index as well
        setDocumentPanel(rnm);
    };

    const addUpdateDocuments = (documents: any, index: number) => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;
        shallowOrders[index].documents = documents;
        setInitFormState({ ...base, load_orders: shallowOrders });
        setDocumentPanel(null);
    };

    const deleteDocuments = (documents: any) => {
        const docs = [...deletedDocuments, ...documents];
        setDeletedDocuments(docs);
    }

    useEffect(() => {
        async function init() {
            await getDropdowns();
        }

        if ( includes(['add', 'edit'], get(params, ['operation'])) ) {
            init();
        }
    }, [params]);

    if ( !init ) {
        return <>
            Loading...
        </>;
    }

    const onChangeGroupSelectionCheckbox = (checked: boolean|undefined, index: number) => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;
        shallowOrders[index].is_selected = checked;
        setInitFormState({ ...base, load_orders: shallowOrders });
    }

    const onChangeHasAnAccident = (checked: boolean|undefined, index: number) => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;
        shallowOrders[index].has_an_accident = checked;
        setInitFormState({ ...base, load_orders: shallowOrders });
    }

    const onDeleteRoute = ({ row, index, arrayHelpers }: any) => {
        // console.log(row)
        const base: any = get(formRef.current, ['values']);

        if ( includes(['announced', 'pending'], row.status) ) {
            const shallowOrders: any = [];
            const statusToBeIncluded: string[] = (row.status == 'announced')
                ? ['announced', 'pending']
                : ['pending'];

            base.load_orders.map((item: any, index: number) => {
                if ( includes(statusToBeIncluded, item.status)
                    && item.orders.filter(
                        (order: any) => includes(row.orders, order)
                    ).length > 0
                ) {
                    arrayHelpers.remove(index);
                } else {
                    shallowOrders.push(item);
                }
            });

            setInitFormState({ ...base, load_orders: shallowOrders });
        } else {
            const shallowOrders = base.load_orders.filter(({ route }: any) => route.id !== row.route.id);
            arrayHelpers.remove(index);
            setInitFormState({ ...base, load_orders: shallowOrders });
        }
    };

    const onChangeStatus = (index: number, status: string) => {
        const base: any = get(formRef.current, ['values']);

        const shallowOrders = base.load_orders;

        // skip change status if item is billed
        if( status === "announced" && shallowOrders[index].is_billed ) {
            return;
        }

        shallowOrders[index].status = status;
        shallowOrders[index].is_selected = status !== 'delivered';

        setInitFormState({ ...base, load_orders: shallowOrders });
    };

    const onPriceDetailClick = (selectedRow: any) => {
        setPriceModel(true);
        const _orders = orders.filter((order: any) => includes(selectedRow.orders, order.id));
        const _models = _orders.map(({ brand_model }: any) => brand_model.id);
        const connectedPriceIds: Array<String>|undefined = find(
            dropdowns.priceModels,
            (price: any) => (every(_models, (m: any) => includes(price.models, m)) && includes(price.price_routes, selectedRow.route.id))
        );

        const _multiPricesPeriods: any = [];
        if ( !isEmpty(connectedPriceIds) ) {
            // if price found then pick first and get the data;
            const transportType: String = find(dropdowns.transportVehicles, {id: selectedRow.transport_vehicle_id}).mode_of_transport || "";
            const pricePeriods: Array<any> = dropdowns.pricePeriods.filter((period: any) => (
                includes(connectedPriceIds, period.price_id)
                && transportType !== ""
                && transportType === period.mode_of_transport
                && selectedRow.route.id === period.route_id
            ));

            pricePeriods.map((item: any) => {
                _multiPricesPeriods.push({
                    ...item,
                    active: (selectedRow.spot_load_route_price.price_period_id === item.price_period_id),
                    group_token: selectedRow.group_token
                })
            });
        }

        setMultiPricesPeriods(_multiPricesPeriods);
    }

    const onSelectPrice = (period: any, type: string = "ftl") => {
        const _price = {
            ...DEFAULT_SPOT_LOAD_ROUTE_PRICE,
            truck_price_category: type,
        };
        // if price found then pick first and get the data;
        const pricePeriod: any = find(dropdowns.pricePeriods, { price_period_id: period.price_period_id });
        const base: any = get(formRef.current, ['values']);
        const selectedRow: any = find(base.load_orders, {group_token: period.group_token});

        // if found
        if ( !isEmpty(period) && !isEmpty(selectedRow) && !isEmpty(pricePeriod) ) {
            _price.price_id = pricePeriod.price_id;
            _price.price_period_id = pricePeriod.price_period_id;
            _price.number_of_vehicles = selectedRow.orders.length;
            _price.loading_factors = pricePeriod.loading_factors;
            _price.type_of_price = pricePeriod.type_of_price;
            _price.group_token = selectedRow.group_token;
            _price.truck_price_category = type;
            _price.currency = pricePeriod.currency;
            _price.price_ftl = Number(pricePeriod.price_ftl);
            _price.price_spl = Number(pricePeriod.price_spl);
            _price.is_overridden = false;
            _price.raw_price = (type === "ftl") ? _price.price_ftl : Number((_price.price_spl * selectedRow.orders.length).toFixed(2));
            _price.mode_of_transport = pricePeriod.mode_of_transport;
            _price.input_price = (type === "ftl") ? _price.price_ftl : _price.price_spl;
            // @ts-ignore
            _price.total = _price.raw_price;
            const inputElement: any = document.getElementById(`input-${selectedRow.group_token}`);

            if ( inputElement ) {
                inputElement.value = _price.input_price;
            }
        } else if ( !isEmpty(selectedRow) ) {
            const inputElement: any = document.getElementById(`input-${selectedRow.group_token}`);
            _price.number_of_vehicles = selectedRow.orders.length;
            _price.loading_factors = selectedRow.orders.length;
            _price.type_of_price = 2;
            _price.input_price = 0;
            _price.price_spl = (type === "spl" && !isEmpty(period.input_price)) ? period.input_price : 0;
            _price.price_ftl = (type === "ftl" && !isEmpty(period.input_price)) ? period.input_price : 0;
            _price.is_overridden = true;
            _price.raw_price = (type === "ftl") ? _price.price_ftl : Number((_price.price_spl * selectedRow.orders.length).toFixed(2));
            _price.input_price = (type === "ftl") ? _price.price_ftl : _price.price_spl;
            // @ts-ignore
            _price.total = _price.raw_price;

            if ( inputElement ) {
                inputElement.value = _price.input_price;
            }
        }

        selectedRow.spot_load_route_price = _price;
        const shallowLoadOrders = base.load_orders.map((item: any) => {
            if ( item.group_token === selectedRow.group_token ) {
                return selectedRow;
            }

            return item;
        });
        setInitFormState({ ...base, load_orders: shallowLoadOrders });
        setPriceModel(false);
        setMultiPricesPeriods([]);
    }

    const onClickRemoveDriver = (index: any, driverId: any) => {
        const base: any = get(formRef.current, ['values']);
        const updatedLoadOrders = [...base.load_orders];
        updatedLoadOrders[index].driver_ids = updatedLoadOrders[index].driver_ids.filter(
            (id: any) => id !== driverId
        );

        setDriverId(null);
        setInitFormState({ ...base, updatedLoadOrders});
    };

    const getErrorMessage = (errors: any, key: any) => {
        return errors && errors[key] ? (
            <p className="text-red-500 m-0">
                { head(errors[key]) }
            </p>
        ) : null;
    };

    // when transport vehicle change from a specific record then handle it
    const onChangeFormTransportVehicle = (value: any, index: number) => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;

        if ( isEmpty(value) ) {
            shallowOrders[index].transport_vehicle_id = null;
            shallowOrders[index].legal_entity_id = null;
            shallowOrders[index].spot_load_route_price = { ...DEFAULT_SPOT_LOAD_ROUTE_PRICE };
        }

        if ( !isEmpty(value) ) {
            const transportVehicle = find(dropdowns.transportVehicles, {id: value.id});
            shallowOrders[index].transport_vehicle_id = value.id;

            if ( !isEmpty(get(transportVehicle, ['truck_owner', 'id'])) ) {
                shallowOrders[index].legal_entity_id = get(transportVehicle, ['truck_owner', 'id']);
            }

            shallowOrders[index].spot_load_route_price.to_be_updated = true;
            shallowOrders[index].spot_load_route_price = getPriceFromPrices(shallowOrders[index], orders, false, base.price_type);
        }

        setInitFormState({ ...base, load_orders: shallowOrders });
    };

    const onDeleteOrder = (index: number, orderId: string) => {
        const base: any = get(formRef.current, ['values']);
        let shallowOrders = base.load_orders;
        let _orders = orders;

        // find route group
        const item: any = shallowOrders[index];
        item.orders = item.orders.filter((id: string) => id !== orderId);
        shallowOrders[index] = item;

        shallowOrders[index].spot_load_route_price = getPriceFromPrices(item, orders, false, base.price_type); // refresh price

        const newShallowOrders: any = [];
        // update all other route group that contains the same order, remove it
        map(shallowOrders, (item: any) => {
            if ( item.status !== "pending" ) {
                newShallowOrders.push(item);
            } else {
                newShallowOrders.push({
                    ...item,
                    orders: item.orders.filter((id: string) => id !== orderId)
                })
            }
        });

        // filter route group that must contains at-least one order
        setInitFormState({ ...base, load_orders: newShallowOrders.filter((item: any) => (item.orders.length > 0)) });

        // if there is no other route group contains the same order then remove order
        if ( newShallowOrders.filter(({ orders }: any) => includes(orders, orderId)).length < 0 ) {
            _orders = orders
            .filter(({ route_id, item_status, group_token }: any) => ( route_id === item.route.id && item_status === item.status && group_token === item.group_token ))
            .filter(({ id }: any) => id !== orderId);
        }

        setOrders(_orders);
    };

    const SpotLoadOrder = (props: any) => {
        const { row, orderId, index, price_type } = props;
        const order = find(orders, { id: orderId });

        if ( isEmpty(order) ) {
            return <React.Fragment key={`${random(5)}-${random(5)}`}></React.Fragment>;
        }

        const price = get(find(row.order_unit_price, {order_id: orderId}), ['total']) ?? 0;
        return (
            <React.Fragment key={`${random(5)}-${random(5)}`}>
                {
                    row.order_ui === 'list' &&
                    <tr>
                        <td>{ index + 1 }</td>
                        <td>{ order.vin_number }</td>
                        <td>{ order.order_cd }</td>
                        <td>{ order.ldg_nr }</td>
                        <td className={ "text-capitalize" }>
                            { order.brand.title }
                            { order.brand.alternate_title && <div>( { order.brand.alternate_title })</div> }
                            <div>
                                { order.brand_model.title }
                                { order.brand_model.alternate_title &&
                                  <div>( { order.brand_model.alternate_title })</div> }
                            </div>
                        </td>
                        <td>
                            { row.transport_vehicle_id && price_type === 'per_route' && <>
                                {
                                    !row.is_billed && <>
                                    <InputNumber
                                      onFocus={ ($event: any) => $event.target.select() }
                                      locale="en-US" minFractionDigits={2}
                                      className={"p-inputtext-sm"}
                                      value={ price }
                                      onBlur={(e: any) => props.onChangeSinglePrice(e.target.value, orderId)}
                                    />
                                    </>
                                }
                                {
                                    row.is_billed && <>{ price }</>
                                }
                            </>}
                        </td>
                        <td className={ "text-end" }>
                            {
                                ( !includes(['delivered'], row.status) && !row.is_billed ) &&
                                <a
                                  data-pr-tooltip={ `${ t("delete item") }` }
                                  data-pr-position="left"
                                  className={ `cursor-pointer ms-2 btn btn-soft-danger spot-load-order-tooltip-${ index }` }
                                  onClick={ (event: any) => confirmPopup({
                                      target: event.currentTarget,
                                      message: t('do you want to delete this record?'),
                                      icon: 'pi pi-info-circle',
                                      // @ts-ignore
                                      defaultFocus: 'reject',
                                      acceptClassName: 'p-button-danger',
                                      accept: () => props.onDeleteOrder(),
                                      reject: () => {
                                      },
                                  }) }>
                                    <i className="fas fa-trash-alt" />
                                </a>
                            }
                        </td>
                    </tr>
                }
                {
                    row.order_ui === 'thumb' &&
                    <div className="card p-1">
                        <div className="overflow-hidden position-relative card-body p-3">
                            <div className="faq-count">
                                <h6 className="text-primary">
                                    { index + 1 }. { order.vin_number }
                                </h6>
                            </div>
                            <p className="text-muted mt-1 mb-0">{ order.order_cd }</p>
                            <p className="text-muted mt-1 mb-0">{ order.ldg_nr }</p>
                            <p className="mb-0 text-capitalize">
                                { order.brand.title }
                                { order.brand.alternate_title && <span>( { order.brand.alternate_title })</span> }
                            </p>
                            <p className="mb-0 text-capitalize">
                                { order.brand_model.title }
                                { order.brand_model.alternate_title &&
                                <span>( { order.brand_model.alternate_title })</span> }
                            </p>
                            <div className={ "text-end" }>
                                {
                                    ( !includes(['delivered'], row.status) && !row.is_billed ) &&
                                    <a
                                      data-pr-tooltip={ `${ t("delete item") }` }
                                      data-pr-position="left"
                                      className={ `cursor-pointer ms-2 btn btn-soft-danger spot-load-order-tooltip-${ index }` }
                                      onClick={ (event: any) => confirmPopup({
                                          target: event.currentTarget,
                                          message: t('do you want to delete this record?'),
                                          icon: 'pi pi-info-circle',
                                          // @ts-ignore
                                          defaultFocus: 'reject',
                                          acceptClassName: 'p-button-danger',
                                          accept: () => props.onDeleteOrder(),
                                          reject: () => {
                                          },
                                      }) }>
                                        <i className="fas fa-trash-alt" />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    };

    const onChangeUi = (index: number, ui: string) => {
        const base: any = get(formRef.current, ['values']);

        const shallowOrders = base.load_orders;
        shallowOrders[index].order_ui = ui;
        setInitFormState({ ...base, load_orders: shallowOrders });
    };

    const onTogglePanelClick = (index: number) => {
        const base: any = get(formRef.current, ['values']);

        const shallowOrders = base.load_orders;
        shallowOrders[index].row_collapsed = !shallowOrders[index].row_collapsed;
        setInitFormState({ ...base, load_orders: shallowOrders });
    }

    // when input text changed set a price
    const onChangePriceInput = (value: any, index: number) => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;
        shallowOrders[index].spot_load_route_price.to_be_updated = true;
        shallowOrders[index].spot_load_route_price.input_price = isEmpty(value) ? 0 : Number(value);
        const price = getPriceFromPrices(
            shallowOrders[index],
            orders,
            true,
            base.price_type
        );
        shallowOrders[index].spot_load_route_price = price;

        const totalPerItem = (price.total) / (shallowOrders[index].orders.length);
        shallowOrders[index].order_unit_price = shallowOrders[index].order_unit_price.map((i: DynamicObject) => ({...i, total: totalPerItem}));

        setInitFormState({ ...base, load_orders: shallowOrders });
    }

    const onClickOpenPriceLegends = () => {
        setPriceLegendModal(true);
    };

    const onChangeSinglePrice = (value: any, orderId: any, index: number) => {
        const base: any = get(formRef.current, ['values']);
        const shallowOrders = base.load_orders;
        const order_unit_price: any = [];
        let _value: any = Number(value);
        let total = 0;

        shallowOrders[index].spot_load_route_price.to_be_updated = true;
        shallowOrders[index].order_unit_price.map((i: DynamicObject) => {
            if ( i.order_id === orderId ) {
                order_unit_price.push({ ...i, total: _value });
                total += Number(_value);
            } else {
                order_unit_price.push(i);
                total += Number(i.total);
            }
        });

        shallowOrders[index].order_unit_price = order_unit_price;
        shallowOrders[index].spot_load_route_price.input_price = total;

        setInitFormState({ ...base, load_orders: shallowOrders });
    }

    return (
        <>
            <div>
                <Formik
                    innerRef={ formRef }
                    enableReinitialize={ true }
                    initialValues={ initFormState }
                    onSubmit={ onSubmit }
                    validationSchema={ validationSchema(t) }
                >
                    { (props: FormikProps<TInputForm>) => {
                        const { values, errors, setFieldValue }: any = props; // errors, setFieldValue
                        let selectedDriverIds: any = [];
                        values.load_orders.filter((item: any) => item.status !== "delivered").map((item: any) => item.driver_ids ? item.driver_ids.map((i: any) => selectedDriverIds.push(i)) : null);

                        return (
                            <Form onSubmit={ props.handleSubmit }>
                                <Row>
                                    <Col sm={ 12 } md={ 4 } lg={ 3 }>
                                        <Card style={ { minHeight: '600px'} }>
                                            <CardHeader>
                                                <h5 className={"mb-0"}>
                                                    { !entity && <span className={ "me-1" }>{ t('create') }</span> }
                                                    { entity && <span className={ "me-1" }>{ t('update') }</span> }
                                                    { t('spot load') } { entity &&
                                                <span>#{ entity.load_number }</span> }
                                                </h5>
                                                <p className={ "p-0 m-0" }>{ t("order will be grouped by routes.") }</p>
                                            </CardHeader>
                                            <CardBody>
                                                <Divider />
                                                <div className={ 'd-flex justify-content-space-between' }>
                                                    <div>
                                                        <Button type="button" size={ "small" } outlined
                                                                onClick={ () => onOpenOrderPanel({ group_token: 'pick-orders-with-route' }, -1) }
                                                                tooltip={ t("pick orders to be transported") } autoFocus>
                                                            <i className="fas fa-truck-pickup me-1" />{ t("pick orders") }
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button type="button" size={ "small" } outlined
                                                                disabled={ filter(values.load_orders, (item: any) => item.status === 'in-transit').length <= 0 }
                                                                onClick={ onGlobalUnloadClick } severity={ "danger" }
                                                                tooltip={ t("unload all(in-transit) vehicles") }>
                                                            <i className="fas fa-truck-loading me-1" />{ t("unload load") }
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Divider />

                                                <Field component={ InputSingleSelectField }
                                                       name="price_type"
                                                       options={ dropdowns?.priceTypes }
                                                       label={ t('load price type') }
                                                       placeholder={ `${ t("pick") } ${ t("price type") }` }
                                                       panelClassName={"max-width-300px hide-p-toggler"}
                                                       onChange={ (e: any) => {
                                                           onChangeCommonField({ id: e.value }, 'price_type');
                                                       } }
                                                       isRequired
                                                />

                                                <Field component={ InputField }
                                                       name="cmr"
                                                       label={ t('cmr') }
                                                       inputRef={ globalCmrRef }
                                                       placeholder={ `${ t("enter") } ${ t("cmr") }` }
                                                />

                                                <Field component={ InputSingleSelectField }
                                                       name="destination_location_id"
                                                       options={ dropdowns?.locations }
                                                       label={ t('destination location') }
                                                       placeholder={ `${ t("pick") } ${ t("destination location") }` }
                                                       panelClassName={"max-width-300px hide-p-toggler"}
                                                       isRequired={ values.price_type === 'per_load' }
                                                />

                                                {
                                                    (values.price_type === 'per_load'
                                                        && filter(values.load_orders, {is_billed: true}).length <= 0)
                                                    &&
                                                    <>
                                                        <Row>
                                                            <Col sm={ 12 } md={ 6 }>
                                                                <Field component={ InputField }
                                                                       name="s_price" type="number" min={ 0 } step={ ".01" }
                                                                       label={ t('price') } isRequired
                                                                       placeholder={ t("enter") + ' ' + t("price").toLowerCase() }
                                                                />
                                                            </Col>

                                                            <Col sm={ 12 } md={ 6 }>
                                                                <Field component={ InputSingleSelectField }
                                                                       name="per_load_is_billable"
                                                                       options={ [{ id: "yes", key: "yes", label: t("yes") }, { id: "no", key: "no", label: t("no") }] }
                                                                       label={ t('is billable?') }
                                                                       panelClassName={"max-width-300px hide-p-toggler"}
                                                                       disabled={ filter(values.load_orders, (item: any) => item.is_billable).length > 0 }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }

                                                {
                                                    (values.price_type === 'per_load' && filter(values.load_orders, {is_billed: true}).length > 0)
                                                    && <div className={"mb-2"}> Price: {values.s_price} </div>
                                                }

                                                {
                                                    (
                                                        values &&
                                                        values.load_orders &&
                                                        values.load_orders.length > 0 &&
                                                        filter(values.load_orders, (item: any) => item.is_selected).length > 0
                                                    )
                                                    &&
                                                    <>
                                                        <div className={ "mb-3" }>
                                                            <Dropdown
                                                              id={'global_driver_id'}
                                                              value={ driverId }
                                                              options={ dropdowns.drivers }
                                                              optionValue={ 'key' }
                                                              optionLabel={ 'label' }
                                                              placeholder={ `${ t("pick") } ${ t("driver") }` }
                                                              pt={ { item: { tabIndex: 0 } } }
                                                              showClear={  true }
                                                              onChange={ (e: any) => {
                                                                  setDriverId(e.value);
                                                                  globalDriverRef.current.focus();
                                                              }}
                                                              onHide={() => {
                                                                  onChangeCommonField({ id: globalDriverRef.current.props.value }, 'driver_id');
                                                              }}
                                                              itemTemplate={(option: any) => {
                                                                  return (
                                                                      <div>
                                                                          { includes(selectedDriverIds, option.id) &&
                                                                            <i
                                                                              className="fas fa-circle text-green-500" /> }
                                                                          { option.name }
                                                                      </div>
                                                                  )
                                                              }}
                                                              ref={ globalDriverRef }
                                                              filterInputAutoFocus
                                                              filter
                                                              className="w-full w-full p-inputtext-sm" />
                                                            <small className={"text-blue-500"}>{ t("select value and press enter") }.</small>
                                                            <small className={"ms-1"}>{ t("each selected driver will be appended") }</small>
                                                        </div>

                                                        <div className={ "mb-3" }>
                                                            <Dropdown
                                                              id={ 'transport_driver_id' }
                                                              value={ transportVehicleId }
                                                              options={ dropdowns.transportVehicles }
                                                              optionValue={ 'key' }
                                                              optionLabel={ 'label' }
                                                              placeholder={ `${ t("pick") } ${ t("transport vehicle") }` }
                                                              pt={ { item: { tabIndex: 0 } } }
                                                              showClear={ true }
                                                              onChange={ (e: any) => {
                                                                  setTransportVehicleId(e.value);
                                                                  globalTransportVehicleRef.current.focus();
                                                              } }
                                                              ref={ globalTransportVehicleRef }
                                                              onHide={() => {
                                                                  onChangeCommonField({ id: globalTransportVehicleRef.current.props.value }, 'transport_vehicle_id');
                                                              }}
                                                              itemTemplate={(option: any) => {
                                                                  return (
                                                                      <div>
                                                                          { option.icon &&
                                                                            <i className={ option.icon } /> }
                                                                          <span
                                                                              className={ "ms-1" }>{ option.label }</span>
                                                                      </div>
                                                                  )
                                                              }}
                                                              filterInputAutoFocus
                                                              filter
                                                              panelClassName={ "max-width-300px hide-p-toggler spot-load-global-driver-target" }
                                                              className="w-full w-full p-inputtext-sm" />
                                                            <small
                                                              className={ "text-blue-500" }>{ t("select value and press enter") }.</small>
                                                        </div>

                                                        <div className={ "mb-3" }>
                                                            <Dropdown
                                                              value={ legalEntityId }
                                                              options={ ( dropdowns.legalEntities && dropdowns.legalEntities ? dropdowns.legalEntities.filter((i: any) => !i.is_disable) : [] ) }
                                                              optionValue={ 'key' }
                                                              optionLabel={ 'label' }
                                                              placeholder={ `${ t("pick") } ${ t("legal entity") }` }
                                                              pt={ { item: { tabIndex: 0 } } }
                                                              showClear={ true }
                                                              onChange={ (e: any) => {
                                                                  setLegalEntityId(e.value);
                                                                  globalLegalEntityRef.current.focus();
                                                              } }
                                                              ref={ globalLegalEntityRef }
                                                              onHide={() => {
                                                                  if ( !isEmpty(get(globalLegalEntityRef.current, ['props'])) ) {
                                                                      onChangeCommonField({ id: globalLegalEntityRef.current.props.value }, 'legal_entity_id');
                                                                  }
                                                              }}
                                                              filterInputAutoFocus
                                                              filter
                                                              panelClassName={ "max-width-300px hide-p-toggler spot-load-global-driver-target" }
                                                              className="w-full w-full p-inputtext-sm" />
                                                            <small
                                                              className={ "text-blue-500" }>{ t("select value and press enter") }.</small>
                                                        </div>

                                                        <div
                                                          className={ "mb-3 react-datetime reactdatepicker-container" }>
                                                            <DatePicker
                                                              selected={ estimatedLoading }
                                                              onChange={ (date: any) => onChangeEstimate(date, 'estimate_loading_time') }
                                                              disabledKeyboardNavigation
                                                              placeholderText={ t("pick estimate loading") }
                                                              dateFormat={ getDisplayFormat({ type: 'datetime' }) }
                                                              showTimeSelect={ true }
                                                              className={ `p-inputtext p-component w-full p-inputtext-sm` }
                                                              excludeTimes={ [
                                                                  new Date().setHours(0, 0, 0)
                                                              ] }
                                                            />
                                                        </div>

                                                        <div
                                                          className={ "mb-3 react-datetime reactdatepicker-container" }>
                                                            <DatePicker
                                                              selected={ estimatedUnLoading }
                                                              onChange={ (date: any) => onChangeEstimate(date, 'estimate_unloading_time') }
                                                              disabledKeyboardNavigation
                                                              placeholderText={ t("pick estimate unloading") }
                                                              dateFormat={ getDisplayFormat({ type: 'datetime' }) }
                                                              showTimeSelect={ true }
                                                              className={ `p-inputtext p-component w-full p-inputtext-sm` }
                                                              excludeTimes={ [
                                                                  new Date().setHours(0, 0, 0)
                                                              ] }
                                                            />
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    values.price_type === "per_load"
                                                    &&
                                                    <Field component={ InputSwitchField }
                                                           name="per_load_merge_route"
                                                           label={ t("invoice: merge route") }
                                                           needBoolean
                                                    />
                                                }
                                            </CardBody>
                                            <CardFooter className={ "bg-body-tertiary" }>
                                                <Row>
                                                    <Col sm={ 12 } className={ "mb-2" }>
                                                        <Button type="submit" outlined size={ "small" }
                                                                disabled={props.isSubmitting}
                                                                title={ t("to data save into database") }
                                                                className={ "w-full d-block" }>
                                                            <i className="fas fa-save me-1" /> { t("let's save") }
                                                        </Button>
                                                    </Col>
                                                    <Col sm={ 12 } className={ "mb-2" }>
                                                        <Button type="button" outlined size={ "small" }
                                                                severity={ "secondary" }
                                                                disabled={props.isSubmitting}
                                                                onClick={ onClose } className={ "w-full d-block" }
                                                                title={ t("operation cancel") }>
                                                            <i className="fas fa-angle-left me-1" /> { t("cancel") }
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Tooltip
                                                            target={ `.price-legends` } />
                                                        <a
                                                            data-pr-tooltip={ `${ t("price legends") }` }
                                                            data-pr-position="left"
                                                            onClick={ onClickOpenPriceLegends }
                                                            className={"price-legends cursor-pointer"}>
                                                            <i className="fas fa-info-circle text-blue-400" />
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>

                                    <Col sm={ 12 } md={ 8 } lg={ 9 } className={ "spot-order-container" }>
                                        <Card>
                                            <CardBody>
                                                <FieldArray
                                                    name="load_orders"
                                                    render={ (arrayHelpers: any) => (
                                                        <div
                                                            key={`${random(10)}`}>
                                                            {
                                                                values.load_orders && values.load_orders.length > 0 ? (
                                                                    values.load_orders.map((row: any, index: number) => {
                                                                        const hasErrors = LOAD_ORDER_ERROR_FIELDS.filter((field: string) => errors[`load_orders.${ index }.${ field }`]);
                                                                        const fromLocation = find(dropdowns.locations, { id: row.route.from_location_id });
                                                                        const toLocation = find(dropdowns.locations, { id: row.route.to_location_id });
                                                                        const systemDriver = find(dropdowns.drivers, {is_system: true});

                                                                        return (
                                                                            <div
                                                                                key={ `${ index }-${ random(10) }` }
                                                                                className={ `${ hasErrors.length > 0 ? 'border border-2 border-red-100' : 'surface-border border-2' }  mt-2` }>
                                                                                {/* Header */}
                                                                                <div className={"a-header p-2 surface-100"}>
                                                                                    <Tooltip
                                                                                        target={ `.status-button-tooltip-${ index }` } />
                                                                                    <Row>
                                                                                        <Col sm={ 12 } md={ 6 }
                                                                                             className={ "spot-load-a-h" }>
                                                                                            <a className={ `cursor-pointer` } onClick={() => onTogglePanelClick(index)}>
                                                                                                <strong
                                                                                                    className={ "ms-1" }>{ index + 1 }.</strong> { row.route.title }
                                                                                                <strong
                                                                                                    className={ "ms-2" }>QTY:</strong> { row.orders && row.orders.length }
                                                                                            </a>
                                                                                        </Col>

                                                                                        <Col sm={ 12 } md={ 6 }
                                                                                             className={ "text-end" }>
                                                                                            { ( !row.is_delivered || row.is_delivered <= 0 ) &&
                                                                                            <>
                                                                                                <Button type="button"
                                                                                                        disabled={ row.is_billed }
                                                                                                        tooltip={ `${ t("add") } ${ t("orders") }` }
                                                                                                        className={ "p-button-x-sm me-1" }
                                                                                                        outlined
                                                                                                        onClick={ () => onOpenOrderPanel(row, index) }>
                                                                                                    <i
                                                                                                      className="fas fa-plus pe-1" /> { t("orders") }
                                                                                                </Button>
                                                                                            </>
                                                                                            }

                                                                                            <Button
                                                                                                className={ "p-button-x-sm me-1" }
                                                                                                outlined
                                                                                                type="button"
                                                                                                tooltip={ `${ t("add") } ${ t("documents") }` }
                                                                                                onClick={ () => onOpenDocumentPanel(row, index) }>
                                                                                                <i
                                                                                                    className="fas fa-file pe-1" />{ t("documents") }
                                                                                            </Button>
                                                                                            <Checkbox
                                                                                                onChange={ (e) => onChangeGroupSelectionCheckbox(e.checked, index) }
                                                                                                checked={ values.load_orders[index].is_selected }
                                                                                                tooltip={ "checked means group update possible." }
                                                                                                className={"me-1 spot-load-a-h-group-checkbox"}
                                                                                                tooltipOptions={ { position: "left" } } />
                                                                                            {
                                                                                                !row.is_billed &&
                                                                                                <Button
                                                                                                  type={"button"}
                                                                                                  tooltip={ t('delete item') }
                                                                                                  className={ "p-button-x-sm me-1 spot-load-a-h-group-checkbox" }
                                                                                                  severity={"danger"}
                                                                                                  outlined
                                                                                                  onClick={ (event: any) => confirmPopup({
                                                                                                      target: event.currentTarget,
                                                                                                      message: t('do you want to delete this record?'),
                                                                                                      icon: 'pi pi-info-circle',
                                                                                                      // @ts-ignore
                                                                                                      defaultFocus: 'reject',
                                                                                                      acceptClassName: 'p-button-danger',
                                                                                                      accept: () => onDeleteRoute({
                                                                                                          row,
                                                                                                          index,
                                                                                                          arrayHelpers
                                                                                                      }),
                                                                                                      reject: () => {
                                                                                                      },
                                                                                                  }) }>
                                                                                                    <i className="fas fa-trash-alt" /> {" "}
                                                                                                </Button>
                                                                                            }
                                                                                            {
                                                                                                ( entity && entity.id && row.status !== "pending") &&
                                                                                                <>
                                                                                                      <span
                                                                                                        data-pr-tooltip={ `${ t("status: announced") }` }
                                                                                                        data-pr-position="left"
                                                                                                        className={ ` ${ row.status === "announced" ? 'border border-2 border-circle border-green-500' : '' } p-1 mb-2 status-button-tooltip-${ index } spot-load-a-h-status-icons cursor-pointer` }
                                                                                                        onClick={ () => onChangeStatus(index, 'announced') }>
                                                                                                        <i
                                                                                                          className="fas fa-truck-pickup" />
                                                                                                    </span>
                                                                                                    <span
                                                                                                      data-pr-tooltip={ `${ t("status: in-transit") }` }
                                                                                                      data-pr-position="left"
                                                                                                      className={ ` ${ row.status === "in-transit" ? 'border border-2 border-circle' : '' } p-1 mb-2 status-button-tooltip-${ index } spot-load-a-h-status-icons text-yellow-500 cursor-pointer` }
                                                                                                      onClick={ () => onChangeStatus(index, 'in-transit') }>
                                                                                                        <i className=" fas fa-truck-moving" />
                                                                                                      </span>
                                                                                                    {
                                                                                                        ( row.is_intransit > 0 ) &&
                                                                                                        <span
                                                                                                          data-pr-tooltip={ `${ t("status: delivered") }` }
                                                                                                          data-pr-position="left"
                                                                                                          onClick={ () => onChangeStatus(index, 'delivered') }
                                                                                                          className={ ` ${ row.status === "announced" ? 'border border-2 border-circle' : '' } p-1 mb-2 status-button-tooltip-${ index } spot-load-a-h-status-icons text-green-500 cursor-pointer` }>
                                                                                                            <i className="fas fa-truck-loading" />
                                                                                                        </span>
                                                                                                    }
                                                                                                </>
                                                                                            }

                                                                                            {
                                                                                                ( row.status !== 'pending' && (!entity || !entity.id) ) &&
                                                                                                <span
                                                                                                  data-pr-tooltip={ `${ t("status: announced") }` }
                                                                                                  data-pr-position="left"
                                                                                                  className={ ` ${ row.status === "announced" ? 'border border-2 border-circle' : '' } p-1 mb-2 status-button-tooltip-${ index } spot-load-a-h-status-icons cursor-pointer` }>
                                                                                                    <i className="fas fa-truck-pickup" />
                                                                                                </span>
                                                                                            }
                                                                                            {
                                                                                                (row.order_ui === "thumb") &&
                                                                                                <span
                                                                                                  data-pr-tooltip={ `${ t("order: thumb view") }` }
                                                                                                  data-pr-position="left"
                                                                                                  onClick={ () => onChangeUi(index, 'list') }
                                                                                                  className={ `p-1 mb-2 status-button-tooltip-${ index } spot-load-a-h-status-icons cursor-pointer` }>
                                                                                                    <i className="fas fa-th-large" />
                                                                                                </span>
                                                                                            }

                                                                                            {
                                                                                                (row.order_ui === "list") &&
                                                                                                <span
                                                                                                  data-pr-tooltip={ `${ t("order: list view") }` }
                                                                                                  data-pr-position="left"
                                                                                                  onClick={ () => onChangeUi(index, 'thumb') }
                                                                                                  className={ `p-1 mb-2 status-button-tooltip-${ index } spot-load-a-h-status-icons cursor-pointer` }>
                                                                                                    <i className="fas fa-list" />
                                                                                                </span>
                                                                                            }

                                                                                            {
                                                                                                ( entity && entity.id && row.db_status === 'in-transit' ) &&
                                                                                                <Checkbox
                                                                                                  onChange={ (e) => onChangeHasAnAccident(e.checked, index) }
                                                                                                  checked={ values.load_orders[index].has_an_accident }
                                                                                                  tooltip={ "do exception unload." }
                                                                                                  className={"me-1 spot-load-a-h-group-checkbox"}
                                                                                                  disabled={ row.db_has_an_accident ?? false }
                                                                                                  tooltipOptions={ { position: "left" } } />
                                                                                            }

                                                                                            {
                                                                                                ( row.is_delivered > 0 ) &&
                                                                                                <span className={ "text-uppercase text-green-500" }>
                                                                                                  { t('delivered') }
                                                                                              </span>
                                                                                            }
                                                                                        </Col>

                                                                                        <Col sm={ 12 } md={ 6 } className={ "spot-load-a-h  sm:mt-0 md:mt-2" }>
                                                                                            <Location item={ fromLocation } titled={ 1 } lazyLoading={false} />
                                                                                            <span className={ "pr-2" }>&nbsp;- </span>
                                                                                            <Location item={ toLocation } titled={ 1 } lazyLoading={false} />
                                                                                            {
                                                                                                ( row.has_an_accident > 0 ) &&
                                                                                                <small
                                                                                                  className={ "text-uppercase text-red-500 ps-2" }>{ t('exceptional unload') }</small>
                                                                                            }
                                                                                        </Col>

                                                                                        <Col sm={ 12 } md={ 6 } className={ "text-end  sm:mt-0 md:mt-2" }>
                                                                                            <div className={"pt-1"}>
                                                                                                <span className={`status-button-tooltip-${ index }`}
                                                                                                      data-pr-tooltip={ `${ t("status") }` }
                                                                                                      data-pr-position="left"
                                                                                                >
                                                                                                    <Tag value={ row.status }
                                                                                                         severity={ row.status === 'pending' ? 'danger' : 'info' }
                                                                                                         className={"text-capitalize"} />
                                                                                                </span>
                                                                                            </div>
                                                                                        </Col>

                                                                                        { (row.transport_vehicle_id && values.price_type === 'per_route') &&
                                                                                        <>
                                                                                            <Col sm={ 12 } md={ 6 }
                                                                                                 className={ "spot-load-a-h  sm:mt-0 md:mt-3" }>
                                                                                                {
                                                                                                    isEmpty(row.spot_load_route_price.price_id) &&
                                                                                                    <span
                                                                                                      className={ `status-button-tooltip-${ index }` }
                                                                                                      data-pr-tooltip={ `${ t("custom price") }` }
                                                                                                      data-pr-position="left" >
                                                                                                        <i className="fas fa-circle text-red-500" />
                                                                                                    </span>
                                                                                                }

                                                                                                {
                                                                                                    !isEmpty(row.spot_load_route_price.price_id)
                                                                                                        ? ( row.spot_load_route_price.is_overridden
                                                                                                        ? <span
                                                                                                            className={ `status-button-tooltip-${ index }` }
                                                                                                            data-pr-tooltip={ `${ t("price has been changed") }` }
                                                                                                            data-pr-position="left"
                                                                                                        >
                                                                                                                    <i className="fas fa-circle text-yellow-500" />
                                                                                                                </span>
                                                                                                        : <span
                                                                                                            className={ `status-button-tooltip-${ index }` }
                                                                                                            data-pr-tooltip={ `${ t("price has been changed") }` }
                                                                                                            data-pr-position="left"
                                                                                                        >
                                                                                                                    <i className="fas fa-circle text-red-500" />
                                                                                                                </span> )
                                                                                                        : ""
                                                                                                }

                                                                                                {
                                                                                                    ( !( row.is_delivered > 0 ) && !row.is_billed )
                                                                                                        ?
                                                                                                        <span
                                                                                                            className={ `status-button-tooltip-${ index } ms-1` }
                                                                                                            data-pr-tooltip={ `${ t("price type") }` }
                                                                                                            data-pr-position="left"
                                                                                                            onClick={ () => onPriceDetailClick(row) }
                                                                                                        >
                                                                                                            <Tag
                                                                                                                severity={ undefined }
                                                                                                                value={ getTypeOfPrice(row.spot_load_route_price.type_of_price).toUpperCase() }
                                                                                                                className={ "text-capitalize" } />
                                                                                                            </span>
                                                                                                        :
                                                                                                        <span
                                                                                                            className={ `status-button-tooltip-${ index }` }
                                                                                                            data-pr-tooltip={ `${ t("price type") }` }
                                                                                                            data-pr-position="left"
                                                                                                        >
                                                                                                            <Tag
                                                                                                                severity={ 'danger' }
                                                                                                                value={ getTypeOfPrice(row.spot_load_route_price.type_of_price).toUpperCase() }
                                                                                                                className={ "text-capitalize" } />
                                                                                                        </span>
                                                                                                }

                                                                                                {
                                                                                                    !( row.is_delivered > 0 )
                                                                                                    &&
                                                                                                    <span className={"ms-1"}>
                                                                                                        <Tag
                                                                                                          severity={row.spot_load_route_price && row.spot_load_route_price.truck_price_category === 'ftl' ? "success" : undefined}
                                                                                                          value={ 'FTL' }
                                                                                                          onClick={ () => {
                                                                                                              if(!row.is_billed) {
                                                                                                                  onSelectPrice({
                                                                                                                      ...row.spot_load_route_price,
                                                                                                                      group_token: row.group_token
                                                                                                                  }, "ftl");
                                                                                                              }
                                                                                                          } }
                                                                                                          className={ "border-noround-right cursor-pointer" } />
                                                                                                        <Tag
                                                                                                          severity={row.spot_load_route_price && row.spot_load_route_price.truck_price_category === 'spl' ? "success" : undefined}
                                                                                                          value={ 'SPL' }
                                                                                                          onClick={ () => {
                                                                                                              if(!row.is_billed) {
                                                                                                                  onSelectPrice({
                                                                                                                      ...row.spot_load_route_price,
                                                                                                                      group_token: row.group_token
                                                                                                                  }, "spl");
                                                                                                              }
                                                                                                          } }
                                                                                                          className={ "border-noround-left cursor-pointer" } />
                                                                                                    </span>
                                                                                                }

                                                                                                {
                                                                                                    (row.spot_load_route_price && row.spot_load_route_price.type_of_price == 3)
                                                                                                    && <>
                                                                                                        <label
                                                                                                          className={ "ps-2" }>
                                                                                                            { t("total") }:
                                                                                                        </label>

                                                                                                        <span
                                                                                                          className={ `status-button-tooltip-${ index }` }
                                                                                                          data-pr-tooltip={ `${
                                                                                                              row.spot_load_route_price.truck_price_category === 'spl'
                                                                                                                  ? t("price spl * vehicles * total km")
                                                                                                                  : t("price ftl * total km")
                                                                                                          }` }
                                                                                                          data-pr-position="left"
                                                                                                        >
                                                                                                            { row.is_delivered > 0
                                                                                                                ? row.spot_load_route_price.total
                                                                                                                : t('to be calculated')
                                                                                                            }
                                                                                                        </span>
                                                                                                        {
                                                                                                            row.is_delivered > 0 &&
                                                                                                            <>
                                                                                                                <label
                                                                                                                  className={ "ps-2" }>
                                                                                                                    { t("total km") }:
                                                                                                                </label>
                                                                                                                <span className={"ps-1 btn btn-link"}>
                                                                                                                    { row.spot_load_route_price.total_km }
                                                                                                                </span>
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                }

                                                                                                {
                                                                                                    (row.spot_load_route_price && row.spot_load_route_price.type_of_price != 3)
                                                                                                    && <>
                                                                                                        <label className={"ps-2"}>
                                                                                                            { t("total") }:
                                                                                                        </label>
                                                                                                        <span className={"ps-1"}>{ row.spot_load_route_price.total }</span>
                                                                                                    </>
                                                                                                }

                                                                                                {
                                                                                                    !isEmpty(row.spot_load_route_price.price_id)
                                                                                                    && <>
                                                                                                        <span
                                                                                                          className={ `status-button-tooltip-${ index } ms-1` }
                                                                                                          data-pr-tooltip={ t('loading factors') }
                                                                                                          data-pr-position="left"
                                                                                                        >
                                                                                                            <span className={`${row.spot_load_route_price.loading_factors != row.orders.length ? 'text-red-500': 'text-green-500'}`}>
                                                                                                                { t("LF") }: { row.spot_load_route_price.loading_factors }
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </>
                                                                                                }
                                                                                            </Col>

                                                                                            <Col sm={ 12 } md={ 6 }
                                                                                                 className={ "text-end sm:mt-0 md:mt-2" }>
                                                                                                <label
                                                                                                  className={ "pe-1" }>
                                                                                                    {
                                                                                                        !isEmpty(row.spot_load_route_price.price_id)
                                                                                                        && row.spot_load_route_price.truck_price_category == 'ftl'
                                                                                                        && t("price ftl")
                                                                                                    }
                                                                                                    {
                                                                                                        !isEmpty(row.spot_load_route_price.price_id)
                                                                                                        && row.spot_load_route_price.truck_price_category === 'spl'
                                                                                                        && t("price spl")
                                                                                                    }
                                                                                                </label>

                                                                                                {
                                                                                                    !row.is_billed && <>
                                                                                                        <InputNumber
                                                                                                          value={row.spot_load_route_price.input_price}
                                                                                                          onFocus={ ($event: any) => $event.target.select() }
                                                                                                          onBlur={(e) => {
                                                                                                              onChangePriceInput(e.target.value ? String(e.target.value).replaceAll(",","") : 0, index);
                                                                                                          }}
                                                                                                          locale="en-US" minFractionDigits={2}
                                                                                                          disabled={ row.is_delivered > 0 }
                                                                                                          className={"p-inputtext-sm"}
                                                                                                        />
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    row.is_billed &&
                                                                                                    <span>{ row.spot_load_route_price.input_price }</span>
                                                                                                }
                                                                                            </Col>
                                                                                        </>
                                                                                        }

                                                                                        <Col sm={ 12 } md={ 6 }>
                                                                                            { ( dropdowns.drivers && row.driver_ids ) &&
                                                                                            row.driver_ids
                                                                                            .map((item: any) => {
                                                                                                return (
                                                                                                    <span
                                                                                                        data-pr-tooltip={ `${ t("driver") }` }
                                                                                                        data-pr-position="left"
                                                                                                        className={ `content-tooltip-${ index } ms-1` }>
                                                                                                            <Tag value={<>
                                                                                                                { get(find(dropdowns.drivers, { id: item }), ['name']) }
                                                                                                                { (includes(['announced', 'pending'], row.status)) &&
                                                                                                                <a
                                                                                                                  onClick={ (event: any) => confirmPopup({
                                                                                                                      target: event.currentTarget,
                                                                                                                      message: t('do you want to delete this record?'),
                                                                                                                      icon: 'pi pi-info-circle',
                                                                                                                      // @ts-ignore
                                                                                                                      defaultFocus: 'reject',
                                                                                                                      acceptClassName: 'p-button-danger',
                                                                                                                      accept: () => onClickRemoveDriver(index, item),
                                                                                                                      reject: () => {
                                                                                                                      },
                                                                                                                  }) }
                                                                                                                  className={"ps-1 cursor-pointer"}>
                                                                                                                    <i className="fas fa-trash-alt" />
                                                                                                                </a>
                                                                                                                }
                                                                                                            </>} />
                                                                                                    </span>
                                                                                                )
                                                                                            })
                                                                                            }

                                                                                            { ( dropdowns.transportVehicles && row.transport_vehicle_id ) &&
                                                                                            <>
                                                                                              <span
                                                                                                data-pr-tooltip={ `${ t("truck plate number") }` }
                                                                                                data-pr-position="left"
                                                                                                className={ `ms-1 content-tooltip-${ index }` }>
                                                                                                  <Tag
                                                                                                    value={ get(find(dropdowns.transportVehicles, { id: row.transport_vehicle_id }), ['truck_plate_number']) }
                                                                                                    className={ "border-noround-right" }
                                                                                                  />
                                                                                                </span>
                                                                                                <span
                                                                                                  data-pr-tooltip={ `${ t("trailer plate number") }` }
                                                                                                  data-pr-position="left"
                                                                                                  className={ `content-tooltip-${ index }` }>
                                                                                                  <Tag
                                                                                                    value={ get(find(dropdowns.transportVehicles, { id: row.transport_vehicle_id }), ['trailer_plate_number']) }
                                                                                                    className={ "border-noround-left" } />
                                                                                                </span>
                                                                                            </>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>

                                                                                {/* Content */}
                                                                                <Row className={`${((row.is_selected && row.row_collapsed) || !row.is_selected) ? '' : 'd-none'} p-3`}>
                                                                                    <Tooltip
                                                                                        target={ `.content-tooltip-${ index }` } />
                                                                                    { LOAD_ORDER_ERROR_FIELDS.map((field: string) => (
                                                                                        <React.Fragment
                                                                                            key={`${index}-${random(6)}`}>
                                                                                            { getErrorMessage(errors, `load_orders.${ index }.${ field }`) }
                                                                                        </React.Fragment>
                                                                                    )) }
                                                                                    <Col sm={ 12 }
                                                                                         className={ `${ (row.is_selected) ? 'd-none' : '' }` }>
                                                                                        <Row>
                                                                                            <Col sm={ 12 } md={ 6 }
                                                                                                 lg={ 4 }>
                                                                                                <div className="field">
                                                                                                    <div
                                                                                                        className={ `field` }>
                                                                                                        <label
                                                                                                            htmlFor={ `${ index }.driver_ids` }>
                                                                                                            { t('drivers') }
                                                                                                            <strong
                                                                                                                style={ { color: 'var(--red-500)' } }> *</strong>
                                                                                                        </label>
                                                                                                        <div>
                                                                                                            <MultiSelect
                                                                                                                maxSelectedLabels={2}
                                                                                                                id={ `${ index }.driver_ids` }
                                                                                                                value={ get(values, ['load_orders', index, 'driver_ids']) }
                                                                                                                options={ dropdowns.drivers }
                                                                                                                onChange={ (e: any) => {
                                                                                                                    if ( row.status !== 'pending' && !isEmpty(e.value) ) {
                                                                                                                        setFieldValue(`load_orders.${ index }.driver_ids`, e.value.filter((item: any) => item !== get(systemDriver, ['id'])));
                                                                                                                    } else {
                                                                                                                        setFieldValue(`load_orders.${ index }.driver_ids`, e.value);
                                                                                                                    }
                                                                                                                } }
                                                                                                                showClear={true}
                                                                                                                display={ "comma" }
                                                                                                                placeholder={ t("select") + ' ' + t("drivers") }
                                                                                                                className={ `w-full p-inputtext-sm ${ !isEmpty(errors[`load_orders.${ index }.driver_ids`]) ? 'p-invalid' : '' }` }
                                                                                                                virtualScrollerOptions={ { itemSize: 50 } }
                                                                                                                panelClassName={"max-width-300px hide-p-toggler"}
                                                                                                                optionValue={"id"}
                                                                                                                disabled={ row.is_delivered > 0 }
                                                                                                                filterPlaceholder={"focus: ctrl + \\"}
                                                                                                                optionLabel={"label"}
                                                                                                                filter
                                                                                                                tabIndex={0}
                                                                                                                onKeyDown={onKeyDownMultiSelect}
                                                                                                                itemTemplate={(option: any) => {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            { includes(selectedDriverIds, option.id) &&
                                                                                                                              <i
                                                                                                                                className="fas fa-circle text-green-500" /> }
                                                                                                                            { option.name }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col sm={ 12 } md={ 6 } lg={ 4 }>
                                                                                                <Field component={ InputSingleSelectField }
                                                                                                       options={ dropdowns.transportVehicles }
                                                                                                       id={`${ index }.transport_vehicle_id`}
                                                                                                       name={ `load_orders.${ index }.transport_vehicle_id` }
                                                                                                       placeholder={ t("select") + ' ' + t("transport vehicle") }
                                                                                                       disabled={ row.is_delivered > 0 }
                                                                                                       onChange={ (e: TreeSelectChangeEvent) => {
                                                                                                           setFieldValue(`load_orders.${ index }.legal_entity_id`, undefined);
                                                                                                           setTimeout(() => {
                                                                                                               onChangeFormTransportVehicle({ id: e.value }, index);
                                                                                                           }, 300)
                                                                                                       } }
                                                                                                       panelClassName={"max-width-300px hide-p-toggler"}
                                                                                                       itemTemplate={(option: any) => {
                                                                                                           return (
                                                                                                               <div>
                                                                                                                   { option.icon &&
                                                                                                                     <i className={ option.icon } /> }
                                                                                                                   <span
                                                                                                                       className={ "ms-1" }>{ option.label }</span>
                                                                                                               </div>
                                                                                                           )
                                                                                                       }}
                                                                                                       label={ t('transport vehicle') } showClear isRequired />
                                                                                            </Col>
                                                                                            <Col sm={ 12 } md={ 6 } lg={ 4 }>
                                                                                                <Field component={ InputSingleSelectField }
                                                                                                       id={`${ index }.legal_entity_id`}
                                                                                                       name={ `load_orders.${ index }.legal_entity_id` }
                                                                                                       placeholder={ t("select") + ' ' + t("legal entity") }
                                                                                                       disabled={ row.is_delivered > 0 }
                                                                                                       panelClassName={"max-width-300px hide-p-toggler"}
                                                                                                       label={ t('legal entity') } showClear isRequired
                                                                                                       options={
                                                                                                           (dropdowns && dropdowns.legalEntities)
                                                                                                               ?
                                                                                                               (
                                                                                                                   (
                                                                                                                       row
                                                                                                                       && !isEmpty(row.legal_entity)
                                                                                                                       && isEmpty(find(dropdowns.legalEntities, {
                                                                                                                           id: row.id,
                                                                                                                           is_disable: true
                                                                                                                       }))
                                                                                                                   )
                                                                                                                       ?
                                                                                                                       dropdowns.legalEntities.filter(
                                                                                                                           (i: any) => (
                                                                                                                               i.is_disable != true || i.id !== row.legal_entity_id
                                                                                                                           )
                                                                                                                       )
                                                                                                                       :
                                                                                                                       dropdowns.legalEntities.filter((i: any) => !i.is_disable)
                                                                                                               )
                                                                                                               : []
                                                                                                       }
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={ 12 } md={ 6 } lg={ 4 }>
                                                                                                <Field component={ InputSingleSelectField }
                                                                                                       options={ dropdowns.spotLoadOrderStatuses }
                                                                                                       id={`${ index }.status`}
                                                                                                       name={ `load_orders.${ index }.status` }
                                                                                                       placeholder={ t("select") + ' ' + t("status") }
                                                                                                       panelClassName={"max-width-300px hide-p-toggler"}
                                                                                                       disabled={ row.is_delivered > 0 }
                                                                                                       label={ t('status') } showClear isRequired />
                                                                                            </Col>
                                                                                            <Col sm={ 12 } md={ 6 } lg={ 4 }>
                                                                                                <Field component={ InputDateTime }
                                                                                                       id={`${ index }.estimate_loading_time`}
                                                                                                       placeholder={ t("pick a date") }
                                                                                                       disabled={ row.is_delivered > 0 }
                                                                                                       name={ `load_orders.${ index }.estimate_loading_time` }
                                                                                                       type={ "datetime" }
                                                                                                       label={ t("estimated loading") }
                                                                                                       isClearable={ true }
                                                                                                       isRequired
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={ 12 } md={ 6 } lg={ 4 }>
                                                                                                <Field component={ InputDateTime }
                                                                                                       id={`${ index }.estimate_unloading_time`}
                                                                                                       name={ `load_orders.${ index }.estimate_unloading_time` }
                                                                                                       type={ "datetime" }
                                                                                                       label={ t("estimated unloading") }
                                                                                                       placeholder={ t("pick a date") }
                                                                                                       disabled={ row.is_delivered > 0 }
                                                                                                       isClearable={ true }
                                                                                                       isRequired
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>

                                                                                    <Col sm={ 12 }
                                                                                         className={ `${ ( !( !row.is_selected ) ) ? 'mb-3' : 'd-none' }` }>
                                                                                        { ( dropdowns.drivers && row.driver_ids ) &&
                                                                                        row.driver_ids
                                                                                        .map((item: any) => {
                                                                                            return (
                                                                                                <span
                                                                                                    data-pr-tooltip={ `${ t("driver") }` }
                                                                                                    data-pr-position="left"
                                                                                                    className={ `content-tooltip-${ index } ms-1` }>
                                                                                                        <Tag value={<>
                                                                                                            { get(find(dropdowns.drivers, { id: item }), ['name']) }
                                                                                                            { (includes(['announced', 'pending'], row.status)) &&
                                                                                                            <a
                                                                                                              onClick={ (event: any) => confirmPopup({
                                                                                                                  target: event.currentTarget,
                                                                                                                  message: t('do you want to delete this record?'),
                                                                                                                  icon: 'pi pi-info-circle',
                                                                                                                  // @ts-ignore
                                                                                                                  defaultFocus: 'reject',
                                                                                                                  acceptClassName: 'p-button-danger',
                                                                                                                  accept: () => onClickRemoveDriver(index, item),
                                                                                                                  reject: () => {
                                                                                                                  },
                                                                                                              }) }
                                                                                                              className={"ps-1 cursor-pointer"}>
                                                                                                                <i className="fas fa-trash-alt" />
                                                                                                            </a>
                                                                                                            }
                                                                                                        </>} />
                                                                                                    </span>
                                                                                            )
                                                                                        })
                                                                                        }

                                                                                        { ( dropdowns.transportVehicles && row.transport_vehicle_id ) &&
                                                                                        <>
                                                                                              <span
                                                                                                data-pr-tooltip={ `${ t("truck plate number") }` }
                                                                                                data-pr-position="left"
                                                                                                className={ `ms-1 content-tooltip-${ index }` }>
                                                                                              <Tag
                                                                                                value={ get(find(dropdowns.transportVehicles, { id: row.transport_vehicle_id }), ['truck_plate_number']) }
                                                                                                className={ "border-noround-right" }
                                                                                              />
                                                                                            </span>
                                                                                            <span
                                                                                              data-pr-tooltip={ `${ t("trailer plate number") }` }
                                                                                              data-pr-position="left"
                                                                                              className={ `content-tooltip-${ index }` }>
                                                                                                  <Tag
                                                                                                    value={ get(find(dropdowns.transportVehicles, { id: row.transport_vehicle_id }), ['trailer_plate_number']) }
                                                                                                    className={ "border-noround-left" } />
                                                                                            </span>
                                                                                        </>
                                                                                        }

                                                                                        { ( dropdowns.legalEntities && row.legal_entity_id ) &&
                                                                                        <span
                                                                                          data-pr-tooltip={ `${ t("legal entity") }` }
                                                                                          data-pr-position="left"
                                                                                          className={ `ms-1 content-tooltip-${ index }` }>
                                                                                                 {
                                                                                                     ( dropdowns.legalEntities && row.legal_entity_id )
                                                                                                     &&
                                                                                                     <Tag
                                                                                                       value={ get(find(dropdowns.legalEntities, { id: row.legal_entity_id }), ['label']) } />
                                                                                                 }
                                                                                            </span>
                                                                                        }

                                                                                        { ( row.estimate_loading_time ) &&
                                                                                        <span
                                                                                          data-pr-tooltip={ `${ t("estimated loading") }` }
                                                                                          data-pr-position="left"
                                                                                          className={ `ms-1 content-tooltip-${ index }` }>
                                                                                              <Tag
                                                                                                value={ formatDate(row.estimate_loading_time, "DD.MM.YYYY hh:mm A") } />
                                                                                            </span>
                                                                                        }

                                                                                        { ( row.estimate_unloading_time ) &&
                                                                                        <span
                                                                                          data-pr-tooltip={ `${ t("estimated unloading") }` }
                                                                                          data-pr-position="left"
                                                                                          className={ `ms-1 content-tooltip-${ index }` }>
                                                                                            <Tag
                                                                                              value={ formatDate(row.estimate_unloading_time, "DD.MM.YYYY hh:mm A") } />
                                                                                           </span>
                                                                                        }

                                                                                        { ( row.prev_status ) &&
                                                                                        <span
                                                                                          data-pr-tooltip={ `${ t("status") }` }
                                                                                          data-pr-position="left"
                                                                                          className={ `ms-1 content-tooltip-${ index }` }>
                                                                                              <Tag
                                                                                                value={ `${ t('status') }: ${ row.status }` } />
                                                                                           </span>
                                                                                        }
                                                                                    </Col>
                                                                                    {
                                                                                        ( includes(['in-transit', 'delivered'], row.status) ) &&
                                                                                        <Col sm={ 12 }>
                                                                                            <Row>
                                                                                                <Col sm={ 12 } md={ 6 }
                                                                                                     lg={ 4 }>
                                                                                                    <Field component={ InputDateTime }
                                                                                                           name={ `load_orders.${ index }.operation_date` }
                                                                                                           type={ "datetime" }
                                                                                                           label={ t("operation date") }
                                                                                                           placeholder={ t("pick a date") }
                                                                                                           disabled={ row.is_delivered > 0 }
                                                                                                           isClearable={ true }
                                                                                                           isRequired
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col sm={ 12 } md={ 6 } lg={ 4 }>
                                                                                                    <Field component={ InputSingleSelectField }
                                                                                                           options={ dropdowns.locations }
                                                                                                           name={ `load_orders.${ index }.accident_location_id` }
                                                                                                           placeholder={ t("select") + ' ' + t("exception location") }
                                                                                                           disabled={ (row.is_delivered > 0) ?? (row.db_has_an_accident ?? false) }
                                                                                                           label={ t('exception location') } showClear isRequired />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    }

                                                                                    {
                                                                                        row.order_ui === 'list' &&
                                                                                        <Col sm={ 12 }>
                                                                                            <Tooltip
                                                                                              target={ `.spot-load-order-tooltip-${ index }` } />
                                                                                            <table
                                                                                              className={ "fl-table mb-3" }>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>#</th>
                                                                                                        <th>{ t('vin number') }</th>
                                                                                                        <th>{ t('order code') }</th>
                                                                                                        <th>{ t('ldg nr') }</th>
                                                                                                        <th>{ t('brand') }/{ t('model') }</th>
                                                                                                        <th>{ t('price') }</th>
                                                                                                        <th
                                                                                                          className={ "text-end" }>{ t('action') }</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        row.orders.map((order: any, oIndex: number) => {
                                                                                                            return(
                                                                                                                <SpotLoadOrder
                                                                                                                    key={ `${ oIndex }-${ random(7) }` }
                                                                                                                    row={ row }
                                                                                                                    index={ oIndex }
                                                                                                                    orderId={ order }
                                                                                                                    price_type={ values.price_type }
                                                                                                                    onChangeSinglePrice={ (value: any, orderId: any) => onChangeSinglePrice(value, orderId, index) }
                                                                                                                    onDeleteOrder={ () => onDeleteOrder(index, order) }
                                                                                                                />
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </Col>
                                                                                    }

                                                                                    {
                                                                                        row.order_ui === 'thumb' &&
                                                                                        row.orders.map((order: any, oIndex: number) => {
                                                                                            return (
                                                                                                <Col sm={ 12 }
                                                                                                     md={ 6 }
                                                                                                     lg={ 3 }
                                                                                                     key={`${oIndex}-${random(11)}`}>
                                                                                                    <Tooltip
                                                                                                        target={ `.spot-load-order-tooltip-${ index }` } />
                                                                                                    <SpotLoadOrder
                                                                                                        row={ row }
                                                                                                        index={ oIndex }
                                                                                                        orderId={ order }
                                                                                                        onChangeSinglePrice={ (value: any, orderId: any) => onChangeSinglePrice(value, orderId, index) }
                                                                                                        onDeleteOrder={ () => onDeleteOrder(index, order) }
                                                                                                    />
                                                                                                </Col>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    })
                                                                ) : ( <></> )
                                                            }
                                                        </div>
                                                    ) }
                                                />
                                                {
                                                    isEmpty(values.load_orders) ? <div className={"p-3"}>
                                                            <p className={ "mb-0" }>{ t("no data") }</p>
                                                            <p className={ "mb-0" }>{ t("pick route and then order under routes.") }</p>
                                                        </div>
                                                        : <></>
                                                }

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        );
                    } }
                </Formik>

                <Dialog header={
                    t('price detail')
                } visible={priceModel} style={{ width: '50vw' }} onHide={() => setPriceModel(false)}
                        breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
                    <ul className={"list-none p-0"}>
                        {
                            multiPricesPeriods.map((period: any, index: number) => {
                                return (
                                    // period.active
                                    <li key={ index + random(10) }
                                        className={`surface-border border-2 ps-2 cursor-pointer ${period.active ? 'surface-100' : ''}`}>
                                        <div>
                                            <small>
                                                <strong>{ index + 1 }. { t("price title") }</strong>: { period.identification_number }
                                            </small><br />
                                            <small>
                                                <strong>{ t("loading factors") }</strong>: { period.loading_factors }
                                            </small><br />
                                            <small>
                                                <strong>{ t("route") }</strong>: { period.route_title }
                                            </small><br />
                                            <small>
                                                <strong>{ t("price type") }</strong>: <span
                                                className={ "text-uppercase" }>{ getTypeOfPrice(period.type_of_price) }</span>
                                            </small><br />
                                            <small>
                                                <strong>{ t("mode of transport") }</strong>: { period.mode_of_transport }
                                            </small><br />
                                            <small>
                                                <strong>{ t("price ftl") }</strong>: { period.price_ftl }
                                            </small><br />
                                            <small>
                                                <strong>{ t("price spl") }</strong>: { period.price_spl }
                                            </small><br />
                                            { !period.active &&
                                            <button
                                              type="button"
                                              onClick={ () => onSelectPrice(period) }
                                              className="btn btn-secondary waves-effect waves-light btn-sm me-2 mt-1">
                                                <i
                                                  className="fas fa-file label-icon pe-1" /> { t("select price") }
                                            </button>
                                            }
                                        </div>
                                    </li>
                                )
                            })
                        }
                        { isEmpty(multiPricesPeriods) && <>
                            <li key={0}>{t('no data')}</li>
                        </> }
                    </ul>
                </Dialog>

                <Dialog header={
                    t('price legends')
                } visible={priceLegendModal} style={{ width: '50vw' }} onHide={() => setPriceLegendModal(false)}
                        breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
                    <div>
                        <p className={ "m-0 fs-5" }>
                            🟢 { t('price matches') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            🔴 { t('no price match') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            💰 { t('fixed price') }: { t('price applicable for the entire load') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            💰 { t('spl price') }: { t(' price applies per vehicle') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            ✏️ { t('price editable') }: { t('price can be modified') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            🚚 { t('loading factor') } 🟢 : { t('loading factor is ok') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            🚚 { t('loading factor') } 🔴 : { t('loading factor is nok') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            🚗 { t('model') }, { t('brand') } { t('and') } { t('transport type') }: { t('connected attributes for pricing') }
                        </p>
                    </div>
                </Dialog>

                <OrderPickerList
                    visible={ orderPanel }
                    dropdowns={ dropdowns }
                    loadOrders={ initFormState.load_orders }
                    setSelectedOrders={ addSelectedOrders }
                    setOrdersWithRoute={ addOrdersWithRoutes }
                    toastify={ toastify }
                    onClose={ () => {
                        setOrderPanel(null);
                    } }
                />

                <AddMissingRoutes
                    visible={ confirmMissingRouteModalVisibility }
                    dropdowns={ dropdowns }
                    toastify={ toastify }
                    routes={ missingRoutes }
                    orders={ ordersToBeAddedWithMissingRoute }
                    onClose={ (param1:any = null, param2: any = []) => {
                        onCloseMissingRouteModal(param1, param2);

                        if ( globalCmrRef.current ) {
                            globalCmrRef.current.focus();
                        }
                    } }
                />

                <SpotLoadDocument
                    visible={ documentPanel }
                    dropdowns={ dropdowns }
                    loadOrders={ initFormState.load_orders }
                    addUpdateDocuments={ addUpdateDocuments }
                    deleteDocuments={ deleteDocuments }
                    onClose={ () => {
                        setDocumentPanel(null);

                        if ( globalCmrRef.current ) {
                            globalCmrRef.current.focus();
                        }
                    } }
                />

                <ConfirmPopup />
            </div>
        </>
    );
}

SpotLoadForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(WithRouter(SpotLoadForm));
