import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, has, includes, trim, isEmpty, filter, find, uniqBy } from "lodash";
import { Button } from "primereact/button";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel: any;
}

type TInputForm = {
    id: null | string,
    has_sub_contractor: boolean,
    sub_contractor_id: string | null,
    truck_owner_id: string | null,
    capacity: number | null,
    year_of_production: number | null,
    connect_to_cvs: boolean,
    type: string | null,
    truck_plate_number: string | null,
    trailer_plate_number: string | null,
    brand: string | null,
    euro_norm: string | number | null,
    chassis_number: string | number | null,
    model: string | null,
    vehicle_engine_type: string | null,
    fuel_consumption: string | null,
    tank: string | null,
    mode_of_transport: string | null,
    vehicle_ext_key: string | null,
    number_of_axles: number | null,
    max_load_capacity: number | null,
    trailer_capacity: number | null,
    is_active: null | any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        type: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('type') })).nullable(),
        mode_of_transport: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('mode of transport') })).nullable(),
        truck_plate_number: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('truck plate number') })).min(3).nullable(),
        trailer_plate_number: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('trailer plate number') })).min(3).nullable(),
        truck_owner_id: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('truck owner') })).nullable(),
        connect_to_cvs: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('connect to cvs') })).nullable(),
        is_active: yup.boolean().nullable(),
        has_sub_contractor: yup.boolean(),
        sub_contractor_id: yup.string(t('the field value is not valid', { field: t("sub contractor") })).nullable()
        .when("has_sub_contractor", {
            is: true,
            then: yup.string().required(t('the field is required.', { field: "sub contractor" })).nullable()
        }),
        capacity: yup.number().nullable(), // .required(t('the field is required.', { field: t('capacity') }))
        number_of_axles: yup.number().nullable(), // .required(t('the field is required.', { field: t('number of axles') }))
        max_load_capacity: yup.number().nullable(), // .required(t('the field is required.', { field: t('max load capacity') }))
        trailer_capacity: yup.number().nullable(), // .required(t('the field is required.', { field: t('trailer capacity') }))
        year_of_production: yup.number(t('the field should be numeric.', { field: t('year of production') })).nullable(), // .required(t('the field is required.', { field: t('year of production') }))
        brand: yup.string().min(2).nullable(), // .required(t('the field is required.', { field: t('brand') }))
        euro_norm: yup.string().nullable(), // .required(t('the field is required.', { field: t('euro norm') }))
        model: yup.string().nullable(), // .required(t('the field is required.', { field: t('euro norm') }))
        vehicle_engine_type: yup.string().nullable(), // .required(t('the field is required.', { field: t('euro norm') }))
        fuel_consumption: yup.string().nullable(), // .required(t('the field is required.', { field: t('euro norm') }))
        tank: yup.string().nullable(), // .required(t('the field is required.', { field: t('euro norm') }))
        vehicle_ext_key: yup.string(t('the field value is not valid', { field: t("csv key") })).nullable()
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    truck_owner_id: null,
    capacity: null,
    year_of_production: null,
    connect_to_cvs: false,
    has_sub_contractor: false,
    sub_contractor_id: null,
    type: null,
    truck_plate_number: null,
    trailer_plate_number: null,
    brand: null,
    euro_norm: null,
    chassis_number: null,
    model: null,
    vehicle_engine_type: null,
    fuel_consumption: null,
    tank: null,
    vehicle_ext_key: null,
    mode_of_transport: 'Road',
    number_of_axles: null,
    max_load_capacity: null,
    trailer_capacity: null,
    is_active: false,
};

function TransportVehicleForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/transport/vehicles` : `/suppliers/transport/vehicles/${ payloads.id }` ),
                data: {
                    ...payloads,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('transport vehicle') }), "success");
            } else {
                toastify(t("record added", { item: t('transport vehicle') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/transport/vehicles/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = {
                ...data,
                sub_contractor_id: data.sub_contractor?.id,
                truck_owner_id: data.truck_owner?.id
            };
            formData.is_active = data.is_active > 0;
            setInitFormState(formData);
            setEntity(data);
            setPanelState(true);
        } catch (error: any) {
            setEntity(null);
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);
        setEntity(null);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('transport vehicle') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="mode_of_transport"
                                                   options={ dropdowns.transportModes }
                                                   label={ t('mode of transport') }
                                                   panelClassName={ "hide-p-toggler" }
                                                   isRequired
                                                   autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="type"
                                                   options={ (
                                                       ( !isEmpty(props.values.mode_of_transport) && dropdowns.transportVehicleTypes )
                                                           ? filter(
                                                           dropdowns.transportVehicleTypes,
                                                           (item: any) => includes(item.mode, props.values.mode_of_transport)
                                                           )
                                                           : []
                                                   ) }
                                                   label={ t('type') }
                                                   panelClassName={ "hide-p-toggler" }
                                                   isRequired
                                                   autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="truck_owner_id"
                                                   options={
                                                       (
                                                           ( dropdowns && dropdowns.truckOwners )
                                                               ?
                                                               (
                                                                   entity
                                                                   && !isEmpty(entity.truck_owner)
                                                                   && !isEmpty(find(dropdowns.truckOwners, { id: entity.truck_owner.id }))
                                                               )
                                                                   ? uniqBy([...dropdowns.truckOwners.filter((item: any) => !item.is_disable), {
                                                                       id: entity.truck_owner.id,
                                                                       label: entity.truck_owner.company_name
                                                                   }], 'id')
                                                                   : dropdowns.truckOwners.filter((item: any) => !item.is_disable)

                                                               : []
                                                       )
                                                   }
                                                   label={ t('legal entity') }
                                                   panelClassName={ "hide-p-toggler" }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="truck_plate_number"
                                                   label={ t("truck plate number") }
                                                   placeholder={ t("enter") + ' ' + t("truck plate number").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="trailer_plate_number"
                                                   label={ t("trailer plate number") }
                                                   placeholder={ t("enter") + ' ' + t("trailer plate number").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="has_sub_contractor"
                                                   label={ t("has sub contractor?") }
                                                   needBoolean
                                            />
                                        </Col>
                                        {
                                            props.values.has_sub_contractor &&
                                            <Col sm={ 12 } md={ 6 }>
                                                <Field component={ InputSingleSelectField }
                                                       name="sub_contractor_id"
                                                       options={ dropdowns?.subContractors }
                                                       label={ t('subcontractor(transport company)') }
                                                       isClearable
                                                       isRequired
                                                />
                                            </Col>
                                        }
                                        {
                                            !props.values.has_sub_contractor &&
                                            <Col sm={ 12 } md={ 6 }>
                                                { " " }
                                            </Col>
                                        }
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="capacity"
                                                   label={ t("truck capacity") + ' (' + t("in kilograms") + ')' }
                                                   placeholder={ t("enter") + ' ' + t("capacity").toLowerCase() }
                                                   type={ "number" }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="trailer_capacity"
                                                   label={ t("trailer capacity") + ' (' + t("in kilograms") + ')' }
                                                   placeholder={ t("enter") + ' ' + t("trailer capacity").toLowerCase() }
                                                   type={ "number" }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="max_load_capacity"
                                                   label={ t("max load capacity") }
                                                   placeholder={ t("enter") + ' ' + t("max load capacity").toLowerCase() }
                                                   type={ "number" }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="number_of_axles"
                                                   label={ t("number of axles") }
                                                   placeholder={ t("enter") + ' ' + t("number of axles").toLowerCase() }
                                                   type={ "number" }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="year_of_production"
                                                   label={ t("year of production") }
                                                   placeholder={ t("enter") + ' ' + t("year of production").toLowerCase() }
                                                   type={ "number" }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="brand"
                                                   label={ t("brand") }
                                                   placeholder={ t("enter") + ' ' + t("brand").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="euro_norm"
                                                   label={ t("euro norm") }
                                                   placeholder={ t("enter") + ' ' + t("euro norm").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="model"
                                                   label={ t("Model") }
                                                   placeholder={ t("enter") + ' ' + t("model").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="vehicle_engine_type"
                                                   label={ t("vehicle engine type") }
                                                   placeholder={ t("enter") + ' ' + t("vehicle engine type").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="fuel_consumption"
                                                   label={ t("fuel consumption") }
                                                   placeholder={ t("enter") + ' ' + t("fuel consumption").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="connect_to_cvs"
                                                   label={ t("connect to cvs") }
                                                   needBoolean
                                            />
                                        </Col>
                                        {
                                            props.values.connect_to_cvs &&
                                            <Col sm={ 12 } md={ 6 }>
                                                <Field component={ InputField }
                                                       name="vehicle_ext_key"
                                                       label={ t("cvs key") }
                                                       placeholder={ t("enter") + ' ' + t("cvs key").toLowerCase() }
                                                />
                                            </Col>
                                        }
                                        {
                                            ( !entity || !entity.is_system ) &&
                                            <Col sm={ 12 } md={ 6 }>
                                                <Field component={ InputSwitchField }
                                                       name="is_active"
                                                       label={ t("status") }
                                                       onLabel={ t("active") }
                                                       offLabel={ t("inactive") }
                                                       labelClass={ "w-25" }
                                                       needBoolean
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => operationCancel(null, null)}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

TransportVehicleForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(TransportVehicleForm);
