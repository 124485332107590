import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useEffect, useRef, useState } from "react";
import { request } from "../../../../../utils/Request";
import Tooltip from "../../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Col, Row } from "react-bootstrap";
import { utcDateToLocalDate } from "../../../../../utils/Helper";
import PrePaymentForm from "./PrePaymentForm";
import { css } from "@emotion/css";
import { get, isEmpty } from "lodash";
import Auth from "../../../../../utils/Auth";

const PrePaymentList = (props: any) => {
    const { t, toastify, moduleState, params } = props;
    const tableRef: any = useRef();
    const [payment, setPayment] = useState(null);
    const [total, setTotal] = useState(0);
    const [entity, setEntity] = useState(null);
    const auth = new Auth();

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/clients/pre/payments/${ payloads.id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("record deleted", { item: t('pre payment') }), "info");
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const DetailRow = ({ data }: any) => {
        return (
            <div>
                <strong
                    className={ 'text-capitalize' }>{ t("comments") }:</strong> { data.comment }
            </div>
        );
    };

    const columns = [
        {
            field: 'author_id',
            title: t('creator'),
            sorting: true,
            render: (_row: any) => <>
                {get(_row, ['author', 'name'])}
                <div>{ utcDateToLocalDate(_row.created_at) }</div>
            </>
        },
        // {
        //     field: 'type',
        //     title: t('type'),
        //     sorting: true,
        //     render: (_row: any) => <span className={ "text-capitalize" }>{ _row.type }</span>
        // },
        {
            field: 'invoice',
            title: t('invoice'),
            sorting: false,
            render: (_row: any) => <span className={ "text-capitalize" }>
                {
                    _row.invoice &&
                  <>
                      {
                          _row.invoice.status === 'draft'
                          &&
                        <Tooltip tooltip={ t("draft pdf") }>
                            <a
                              title={ t('draft pdf') }
                              className={ "cursor-pointer ms-2" }
                                /*@ts-ignore*/
                              href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.invoice.id }?status=draft&types=pdf&uToken=` + auth.getUser().id }
                              target={ "_blank" }
                              rel="noopener noreferrer"
                            >
                                { _row.invoice.status === "draft" && get(_row.invoice, ['draft_invoice_number']) }
                            </a>
                        </Tooltip>
                      }
                      {
                          _row.invoice.status === 'final'
                          &&
                        <Tooltip
                          tooltip={ t("final pdf") }
                          position={ "left" }>
                            <a
                              className={ "cursor-pointer ms-2" }
                                /*@ts-ignore*/
                              href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.invoice.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                              target={ "_blank" }
                              rel="noopener noreferrer"
                            >
                                { _row.invoice.status === "final" && get(_row.invoice, ['final_invoice_number']) }
                            </a>
                        </Tooltip>
                      }
                  </>
                }
            </span>
        },
        {
            field: 'price',
            title: t('amount'),
            sorting: true,
            render: (_row: any) => <span>
                <span className={"text-bold"}>{_row.type === "positive" ? '+' : '-'}</span> { t(_row.price) }
            </span>
        },
        {
            field: 'status',
            title: t('status'),
            sorting: true,
            render: (_row: any) => <span className={ "text-capitalize" }>{ t(_row.status) }</span>
        },
        {
            field: 'payment_method',
            title: t('payment method'),
            sorting: true,
            render: (_row: any) => <span className={ "text-capitalize" }>{ _row.payment_method }</span>
        },
        {
            field: 'payment_date',
            title: t('payment date'),
            sorting: true,
            render: (_row: any) => ( _row.payment_date ? utcDateToLocalDate(_row.payment_date) : '' )
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            render: (_row: any) => {
                return (
                    <>
                        {
                            isEmpty(_row.invoice) &&
                            <>
                                <HasAccess hasAllAccess={ ['clientsupdate'] }>
                                    <Tooltip
                                      tooltip={ t('edit item') }>
                                        <a onClick={() => setPayment(_row)}>
                                            <i className="fas fa-edit" />
                                        </a>
                                    </Tooltip>
                                </HasAccess>
                                <HasAccess hasAllAccess={ ['clientsdestroy'] }>
                                    <Tooltip
                                      tooltip={ t('delete item') }>
                                        <a
                                          title={ t('delete item') }
                                          className={ "cursor-pointer ms-2 btn btn-soft-danger waves-effect waves-light btn-xs" }
                                          onClick={ (event: any) => confirmPopup({
                                              target: event.currentTarget,
                                              message: t('do you want to delete this record?'),
                                              icon: 'pi pi-info-circle',
                                              // @ts-ignorer
                                              defaultFocus: 'reject',
                                              acceptClassName: 'p-button-danger',
                                              accept: () => onDelete(_row),
                                              reject: () => {
                                              },
                                          }) }>
                                            <i className="fas fa-trash-alt" />
                                        </a>
                                    </Tooltip>
                                </HasAccess>
                            </>
                        }

                        <Tooltip tooltip={ t("pdf") }>
                            <a
                                title={ t('pdf') }
                                className={ "cursor-pointer ms-2" }
                                /*@ts-ignore*/
                                href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=ClientPrePayment&types=pdf&uToken=` + auth.getUser().id }
                                target={ "_blank" }
                                rel="noopener noreferrer"
                            >
                                <i className="far fa-file-pdf" />
                            </a>
                        </Tooltip>
                    </>
                );
            }
        },
    ];

    const setRowClass = (row: any) => {
        if(row.type === "positive") {
            return css`
                background-color: var(--green-100);
            `;
        }

        return css`
            background-color: var(--red-100);
        `;
    }

    const onClose = () => {
        tableRef.current.onRefresh();
        onDetail();
    }

    const onDetail = async (): Promise<void> => {
        try {
            const response = await request({
                url: `suppliers/clients/${ params.operationId }`,
            });

            const { data } = response;
            setEntity(data.data);
        } catch (e: any) {

        }
    };

    useEffect(() => {
        onDetail();
    }, []);

    return (
        <div className={ "block-viewer" }>
            <PrePaymentForm
                dropdowns={ props.dropdowns }
                toastify={ props.toastify }
                onClose={ onClose }
                params={ params }
                payment={ payment }
            />

            <Row className={ "p-2" }>
                <Col sm={ 12 }>
                    {
                        entity &&
                        <>
                            <span className={"me-2"}>
                                {t('available')}: <strong>{Number(get(entity, ['pre_payment_positive']) ?? 0) - Number(get(entity, ['pre_payment_negative']) ?? 0)}</strong>
                            </span>
                            <span className={"me-2"}>
                                { t('prepaid') }: <strong className={ "text-green-500" }>{ get(entity, ['pre_payment_positive']) }</strong>
                            </span>
                            <span className={"me-2"}>
                                { t('used') }: <strong className={ "text-red-500" }>{ get(entity, ['pre_payment_negative']) }</strong>
                            </span>
                        </>
                    }

                    <StateLessGrid
                        size={ "normal" }
                        url={ '/suppliers/clients/pre/payments?1=1' }
                        name={ "PrePaymentList" }
                        moduleState={ moduleState }
                        columns={ columns }
                        setQuery={ props.setQuery }
                        setRowClass={ setRowClass }
                        itemSize={ 45 }
                        ref={ tableRef }
                        setTotal={ setTotal }
                        detailRow={ (data: any) => <DetailRow data={ data } /> }
                    />
                </Col>
            </Row>
            <ConfirmPopup />
        </div>
    );
};

PrePaymentList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(PrePaymentList));
