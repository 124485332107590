import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { request } from "../../../../utils/Request";
import { dateToUtcDate, setLastUpdate } from "../../../../utils/Helper";
import { Field, Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import {  isEmpty, get } from 'lodash';
import moment from "moment";
import { Sidebar } from "primereact/sidebar";
import { InputField } from "../../../../components/Shared/InputField";
import {  Col, Row } from "react-bootstrap";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import { Button } from "primereact/button";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import Location from "../../../../components/Common/Display/Location";

interface IFormProps {
    id: string | null,
    t: any | null,
    onRefresh?: any | null,
    dropdowns?: any | null,
    onClose?: any | null,
    toastify?: any | null,
}

type TInputForm = {
    date?: any,
    comments?: any,
    id?: any,
    cmr?: any,
};

const DEFAULT_FORM_STATE: TInputForm = {
    date: null,
    comments: null,
    id: null,
    cmr: null,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        date: yup.date(t("the field value is not valid", { field: t('date') }))
        .required(t('the field is required.', { field: t('date') })).nullable(),
        comments: yup.string().nullable(),
        cmr: yup.string().nullable(),
    });
};

function AlpSpotLoadInTransit(props: IFormProps) {
    const { dropdowns, toastify } = props;
    const { t } = useTranslation();
    const formRef: any = useRef();
    const [entity, setEntity] = useState<any>(null);
    const [initFormState, setInitFormState] = useState<any>({ ...DEFAULT_FORM_STATE });
    const [panelState, setPanelState] = useState<boolean>(false);

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/alp/spot/loads/${ props.id }/in/transit`,
                data: {
                    ...payloads,
                    date: ( ( payloads.date ) ? dateToUtcDate(payloads.date, 'YYYY-MM-DD[T]HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') : null ),
                },
            });

            toastify(t("record updated", { item: t('spot load') }), "info");
            setLastUpdate(payloads.id, 'AlpSpotLoad');
            resetForm();
            onClose();

            if ( props.onRefresh ) {
                props.onRefresh();
            }

        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            }  if ( e.status === 404 ) {
                toastify(t("action not allowed: 404"), "error");
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/alp/spot/loads/${ id }`
            });
            const { data } = response.data;
            setEntity(data);
            setInitFormState({
                id: data.id,
                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                cmr: data.cmr ?? null
            });
            setPanelState(true);
        }  catch (error: any) {
            if ( error.status === 404 ) {
                toastify(t("action not allowed: 404"), "error");
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    useEffect(() => {
        setEntity(null);
        if ( !isEmpty(props.id) ) {
            // @ts-ignorer
            getDetail(props.id);
        }

    }, [props.id]);
    return (
        <>
            <div>
                {
                    entity &&
                  <>

                      <Sidebar
                        header={
                            <div className="bg-body-tertiary card-header">
                                <h5 className={ "card-title mt-0" }>{ t('start journey') }{ entity && ` #${ entity.load_number }` }</h5>
                                <p>{ t("using this form you can add or update the record") }</p>
                            </div>
                        }
                        visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                        style={ { width: '800px' } } closeOnEscape={false}
                      >
                          <div style={ {
                              height: '3px',
                              background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                          } }></div>

                          <Formik
                            innerRef={ formRef }
                            enableReinitialize={ true }
                            initialValues={ initFormState }
                            onSubmit={ onSubmit }
                            validationSchema={ validationSchema(t) }
                          >
                              { (props: FormikProps<TInputForm>) => {
                                  return (
                                      <Form onSubmit={ props.handleSubmit }>
                                          <Row className={ "mt-4 mb-7" }>
                                              <Col sm={ 12 } md={ 6 }>
                                                  <Field
                                                      placeholder={ t("pick a date") }
                                                      component={ InputDateTime }
                                                      name={ `date` }
                                                      type={ "datetime" }
                                                      label={ t("date") }
                                                      maxDate={ moment().endOf('day').tz(moment.tz.guess()).toDate() }
                                                      autoFocus={true}
                                                      // minDate={ moment().tz(moment.tz.guess()).toDate() }
                                                      isRequired
                                                  />
                                              </Col>
                                              <Col sm={ 12 } md={ 6 }>
                                                  <Field component={ InputField }
                                                         name="cmr"
                                                         label={ t('cmr') }
                                                         placeholder={ t("enter") + ' ' + t("cmr").toLowerCase() }
                                                  />
                                              </Col>
                                              <Col sm={ 12 } md={ 12 }>
                                                  <Field component={ InputTextAreaField }
                                                         name="comments"
                                                         label={ t('comments') }
                                                         placeholder={ t("enter") + ' ' + t("comments").toLowerCase() }
                                                  />
                                              </Col>

                                              <Col sm={ 12 } md={ 12 } className={"mb-5"}>
                                                  <hr />
                                                  <h4 className="card-title">{ t('orders') }</h4>
                                                  { entity &&
                                                    <div className={'table-responsive'}>
                                                        <table className={"fl-table"}>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{ t('from location') }</th>
                                                                    <th>{ t('to location') }</th>
                                                                    <th>{ t('brand') }</th>
                                                                    <th>{ t('model') }</th>
                                                                    <th>{ t('order code') }</th>
                                                                    <th>{ t('vin number') }</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    // @ts-ignore
                                                                    entity.alp_spot_load_orders.map((row: any, index: number) => (
                                                                        <tr
                                                                            key={ index }
                                                                        >
                                                                            <th>{ index + 1 }</th>
                                                                            <td>
                                                                                <Location item={ get(row, ['from_location']) } titled={ 1 } />
                                                                            </td>
                                                                            <td><Location item={ get(row, ['to_location']) } titled={ 1 } /></td>
                                                                            <td className={"text-capitalize"}>{ get(row, ['order', 'brand', 'title']) }</td>
                                                                            <td className={"text-capitalize"}>{ get(row, ['order', 'brand_model', 'title']) } - {  get(row, ['order', 'brand_model', 'version_code']) } </td>
                                                                            <td>{ get(row, ['order', 'order_cd']) }</td>
                                                                            <td>{ get(row, ['order', 'vin_number']) }</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                  }
                                              </Col>
                                          </Row>
                                          <div className="filter-card-footer"
                                               style={ { width: '785px' } }>
                                              <Button tooltip={ t("to data save into database") }
                                                      size={ "small" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      disabled={props.isSubmitting}
                                              >
                                                  <i className="pi pi-save me-2" />
                                                  { t("let's save") }
                                              </Button>
                                              <Button type={ "button" }
                                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                                      size={ "small" } severity={ "danger" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      onClick={ onClose }
                                                      disabled={props.isSubmitting}
                                              >
                                                  <i className="pi pi-arrow-left me-2" />
                                                  { t("cancel") }
                                              </Button>
                                          </div>
                                      </Form>
                                  );
                              } }
                          </Formik>
                      </Sidebar>
                  </>
                }
            </div>
        </>
    );
}

AlpSpotLoadInTransit.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(AlpSpotLoadInTransit);
