import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as yup from "yup";
import { request } from "../../../../../utils/Request";
import { isEmpty } from "lodash";
import { Field, Form, Formik, FormikProps } from "formik";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../../components/Shared/InputField";
import InputDateTime from "../../../../../components/Shared/InputDateTime";
import { InputSingleSelectField } from "../../../../../components/Shared/InputSingleSelectField";
import { InputTextAreaField } from "../../../../../components/Shared/InputTextAreaField";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { dateToUtcDate, utcDateToLocalDate } from "../../../../../utils/Helper";

interface IFormProps {
    t?: any;
    dropdowns: any;
    toastify: any;
    onClose?: any;
    payment?: any;
    params: any;
}

type TInputForm = {
    id: any,
    price: any,
    status: any,
    comments: any,
    payment_method: any,
    payment_date: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        price: yup.number(t('the field should be numeric.', { field: t("price") })).required(t('the field is required.', { field: t('price') })).nullable(),
        status: yup.string().required(t('the field is required.', { field: t('status') })).nullable(),
        payment_method: yup.string().required(t('the field is required.', { field: t('payment method') })).nullable(),
        payment_date: yup.date(t('the field is not valid', { field: t("payment date") })).required(t('the field is required.', { field: t('payment date') })).nullable(),
        comments: yup.string().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    price: 0,
    payment_method: 'cash',
    status: 'prepaid',
    payment_date: null,
    id: null,
    comments: null,
};

function PrePaymentForm(props: IFormProps) {
    const { t, toastify, dropdowns, params } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/clients/pre/payments` : `/suppliers/clients/pre/payments/${ payloads.id }` ),
                data: {
                    ...payloads,
                    payment_date: dateToUtcDate(payloads.payment_date),
                    client_id: params.operationId,
                    type: "positive"
                },
            });

            toastify(t("record updated", { item: t('pre payment updated') }), "success");
            onClose();
            resetForm();
            setInitFormState(DEFAULT_FORM_STATE);
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onReset = (props: any) => {
        props.resetForm();
        props.setValues({...DEFAULT_FORM_STATE});
    };

    const onClose = () => {
        formRef.current.resetForm();
        props.onClose();
    };

    useEffect(() => {
        if ( !isEmpty(props.payment) && !isEmpty(props.payment.id) ) {
            setInitFormState({
                id: props.payment.id,
                price: props.payment.price,
                status: props.payment.status,
                payment_date: utcDateToLocalDate(props.payment.payment_date, 'YYYY-MM-DD HH:mm:ss'),
                payment_method: props.payment.payment_method,
                comments: props.payment.comments,
            });
        }
    }, [props.payment]);

    return (
        <>
            <div>
                <Formik
                    innerRef={ formRef }
                    enableReinitialize={ true }
                    initialValues={ initFormState }
                    onSubmit={ onSubmit }
                    validationSchema={ validationSchema(t) }
                >
                    { (props: FormikProps<TInputForm>) => {
                        return (
                            <Form onSubmit={ props.handleSubmit }>
                                <Row className={ "pt-4" }>
                                    <Col sm={ 12 }>
                                        <Row>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Row>
                                                    <Col sm={ 12 } md={ 4 }>
                                                        <Field component={ InputField }
                                                               type={ "number" }
                                                               name="price" autoFocus
                                                               withoutLabel={ true }
                                                               placeholder={ t("enter") + ' ' + t("price").toLowerCase() }
                                                               isRequired />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 }>
                                                        <Field component={ InputDateTime }
                                                               name="payment_date"
                                                               type={ "datetime" }
                                                               withoutLabel={ true }
                                                               placeholder={ t("pick a date") }
                                                               isRequired
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 }>
                                                        <Field
                                                            component={ InputSingleSelectField }
                                                            name="payment_method"
                                                            withoutLabel={ true }
                                                            options={ dropdowns.paymentMethods }
                                                            placeholder={ t('pick') + ' ' + t('payment method') }
                                                            isRequired
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 }>
                                                        <Field
                                                            withoutLabel={ true }
                                                            component={ InputTextAreaField }
                                                            name="comments"
                                                            label={ t('comments') }
                                                            placeholder={ t('enter') + ' ' + t('comment') }
                                                            isRequired
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={ 12 } md={ 4 }>
                                                <Row>
                                                    <Col sm={ 12 } md={ 4 }>
                                                        <Button type={ "submit" } severity={ "success" }
                                                                disabled={ props.isSubmitting }
                                                                tooltip={ t("to data save into database") }
                                                                size={ "small" } outlined className={ "w-full" }
                                                                tooltipOptions={ { position: 'bottom' } }
                                                        >
                                                            <i className="fas fa-plus-square me-1" />
                                                            { t('save') }
                                                        </Button>
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 }>
                                                        <Button type={ "button" } severity={ "danger" }
                                                                tooltip={ t("reset") } disabled={ props.isSubmitting }
                                                                size={ "small" } outlined className={ "w-full" }
                                                                onClick={ () => onReset(props) }
                                                                tooltipOptions={ { position: 'bottom' } }
                                                        >
                                                            <i className="fas fa-sync-alt me-1"></i>
                                                            { t('reset') }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Divider />
                                    </Col>
                                </Row>
                            </Form>
                        );
                    } }
                </Formik>
            </div>
        </>
    );
}

PrePaymentForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PrePaymentForm);
