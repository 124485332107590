import React from 'react';
import { ErrorMessage } from 'formik';
import { find, get, has, isEmpty } from 'lodash';
import { TreeSelectChangeEvent } from 'primereact/treeselect';
import { TreeNode } from 'primereact/treenode';
import { Dropdown } from "primereact/dropdown";

interface IInputSingleSelectFieldProps {
    label?: string,
    withoutLabel?: boolean,
    field: any,
    form?: any,
    options?: TreeNode[],
    display?: string,
    formGroupClass?: string,
    trackBy?: string,
    isRequired?: any,
    inputGroupIconClass?: string,
    placeholder?: string,
    showClear?: boolean,
    itemTemplate?: boolean,
    valueTemplate?: boolean,
    panelClassName?: boolean,
    autoFocus?: boolean,
    onChange?: (e: TreeSelectChangeEvent) => void,
    formText?: any,
}

export class InputSingleSelectField extends React.Component<IInputSingleSelectFieldProps, {}> {
    componentRef: any;

    constructor(props: IInputSingleSelectFieldProps) {
        super(props);

        this.componentRef = React.createRef();
    }

    keepFocus = () => {
        if ( this.componentRef && this.componentRef.current ) {
            this.componentRef.current.focus();
        }
    };

    render() {
        const {
            field,
            form,
            withoutLabel,
            options,
            display,
            onChange,
            formText,
            formGroupClass,
            inputGroupIconClass,
            isRequired,
            placeholder,
            showClear,
            trackBy,
            ...props
        } = this.props;
        const { errors } = form;

        return (
            <div className={ `field ${ formGroupClass }` }>
                { !withoutLabel && (
                    <label htmlFor={ field.id ?? field.name }>
                        { props.label }
                        { isRequired && <strong style={ { color: 'var(--red-500)' } }> *</strong> }
                    </label>
                ) }

                { inputGroupIconClass && (
                    <span className="p-input-icon-left">
                        <i className={ `${ inputGroupIconClass }` } />
                         <Dropdown
                             { ...props }
                             id={ field.id ?? field.name }
                             value={ get(field, ['value']) || null }
                             onChange={ (e: any) => {
                                 form.setFieldValue(field.name, e.value);

                                 if ( onChange ) {
                                     onChange(e);
                                 }

                                // this.keepFocus()
                            } }
                            ref={ this.componentRef }
                            options={ options }
                            optionValue={ trackBy ?? 'key' }
                            // blur causes the issue on load screen so removed
                            placeholder={ placeholder ?? "" }
                            virtualScrollerOptions={ { itemSize: 38 } }
                            className={ `w-full p-inputtext-sm ${ has(errors, field.name) ? 'p-invalid' : '' }` }
                            showClear={ showClear ?? true }
                            // @ts-ignore
                            panelClassName={ props.panelClassName ?? "" }
                            pt={ { item: { tabIndex: 0 } } }
                            autoFocus={props.autoFocus ?? false}
                            filterInputAutoFocus
                            filter
                        />
                        { formText && <small>{ formText }</small> }
                        <ErrorMessage name={ field.name } component="small" className="p-error" />
                    </span>
                ) }

                { !inputGroupIconClass && (
                    <div>
                        <Dropdown
                            { ...props }
                            id={ field.id ?? field.name }
                            value={ get(field, ['value']) || null }
                            onChange={ (e: any) => {
                                form.setFieldValue(field.name, e.value);

                                if ( onChange ) {
                                    onChange(e);
                                }

                                this.keepFocus()
                            } }
                            ref={ this.componentRef }
                            options={ options }
                            optionValue={ trackBy ?? 'key' }
                            // blur causes the issue on load screen so removed
                            placeholder={ placeholder ?? "" }
                            virtualScrollerOptions={ { itemSize: 38 } }
                            className={ `w-full p-inputtext-sm ${ has(errors, field.name) ? 'p-invalid' : '' }` }
                            showClear={  true }
                            filterPlaceholder={"focus: ctrl + \\"}
                            // @ts-ignore
                            panelClassName={ props.panelClassName ?? "" }
                            pt={ { item: { tabIndex: 0 } } }
                            itemTemplate={props.itemTemplate}
                            valueTemplate={props.valueTemplate}
                            autoFocus={props.autoFocus ?? false}
                            filterInputAutoFocus
                            filter
                            onKeyDown={(e: any) => {
                                if(e.ctrlKey && e.keyCode === 220) {
                                    const firstMultiselectItem: any = document.querySelector("body .p-dropdown-panel .p-dropdown-filter");
                                    if(firstMultiselectItem) {
                                        firstMultiselectItem.focus();
                                    }
                                }

                                if(e.keyCode == 27) {
                                    this.componentRef.current.hide();
                                    this.componentRef.current.focus();
                                }
                            }}
                        />
                        { formText && <small>{ formText }</small> }
                        <ErrorMessage name={ field.name } component="small" className="p-error" />
                    </div>
                ) }
            </div>
        );
    }
}
