import React, { Component, createRef } from "react";
import qs from "qs";
import { MultiSelect } from 'primereact/multiselect';
import { isEmpty, get, intersection } from "lodash";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { request } from "../../utils/Request";
import { ColumnGroup } from "primereact/columngroup";
import { Col, Row } from "react-bootstrap";
import { Paginator } from 'primereact/paginator';
import { css, cx } from '@emotion/css';

interface ITableProps {
    url: string,
    columns: any,
    size?: string,
    moduleState?: any,
    setQuery?: any,
    name?: any,
    detailRow?: any,
    trackBy?: any,
    selectAllMode?: any,
    enableTableSearch?: any,
    getFilterRef?: any,
    getMetaData?: any,
    onRowSelection?: any,
    setRowClass?: any,
    itemSize?: number,
    setTotal?: any,
    getExtra?: any,
}

class StateLessGrid extends Component<any, any> {
    dataGrid: any = null;

    static defaultProps: any = {
        size: "small",
        trackBy: "id",
        itemSize: 75,
    };

    constructor(props: ITableProps) {
        super(props);
        this.dataGrid = createRef();
        const storageCols = localStorage.getItem(`dt-state-${ props.name }.hCols`);

        // @ts-ignore
        const _storageCols = get(JSON.parse(storageCols), ['visible_columns']);
        this.state = {
            entities: [],
            total: 0,
            page: !isEmpty(get(props, ['moduleState', 'page'])) ? props.moduleState.page : 1,
            per_page: !isEmpty(get(props, ['moduleState', 'per_page'])) ? props.moduleState.per_page : 20,
            visible_columns: !isEmpty(_storageCols) ? intersection(props.columns.map(({field}: any) => field), _storageCols) : props.columns.map(({field}: any) => field),
            sort_field: !isEmpty(get(props, ['moduleState', 'sort_field'])) ? props.moduleState.sort_field : null,
            sort_order: !isEmpty(get(props, ['moduleState', 'sort_order'])) ? props.moduleState.sort_order : null,
            selected: [],
            fetching: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps: any) {
        if ( this.props.moduleState !== prevProps.moduleState  ) {
            this.fetchData();
        }
    }

    async fetchData() {
        if ( this.state.fetching ) {
            return true;
        }

        this.clearAllSelection()

        this.setState({
            fetching: true
        });

        const { url, moduleState, getMetaData } = this.props;

        try {
            const response = await request({
                url: url,
                params: {
                    ...moduleState,
                    sort_order: (moduleState.sort_order == 1) ? "desc" : (moduleState.sort_order == -1 ? "asc" : null)
                },
                paramsSerializer: {
                    serialize: (params_1: any) => qs.stringify(params_1)
                },
            });

            if ( getMetaData ) {
                getMetaData(response.data);
            }

            const { data, meta, extra } = response.data;

            this.setState({
                entities: data,
                total: ( meta ? meta.total : data.length ),
                page: ( meta ? meta.current_page : 1 ),
                per_page: ( meta ? meta.per_page : 10 ),
            });

            if ( this.props && this.props.setTotal ) {
                this.props.setTotal( meta ? meta.total : data.length )
            }

            if ( this.props && this.props.getExtra ) {
                this.props.getExtra( extra )
            }
        } catch (error) {
            this.setState({
                entities: [],
            });
            // console.log(error)
            throw new Error('Data Loading Error');
        } finally {
            this.setState({
                fetching: false
            });
        }
    }

    onColumnToggle = (event: any) => {
        let selectedColumns = event.value;
        const { columns } = this.props;

        let orderedSelectedColumns = columns.filter((col: any) => selectedColumns.some((sCol: any) => sCol === col.field));

        localStorage.setItem(`dt-state-${ this.props.name }.hCols`, JSON.stringify({
            visible_columns: orderedSelectedColumns.map((col: any) => col.field)
        }));

        this.setState({ visible_columns: orderedSelectedColumns.map((col: any) => col.field) });
    };

    onPageChange = (event: any) => {
        const { rows, page } = event;

        this.props.setQuery({
            ...this.props.moduleState,
            page: ( page + 1 ),
            per_page: rows
        });
    };

    onSelectRow = (event: any) => {
        this.setState({
            selected: event.value
        });

        if ( this.props.onRowSelection ) {
            this.props.onRowSelection(event.value);
        }
    };

    clearAllSelection = () => {
        this.setState({
            selected: []
        });

        if ( this.props.onRowSelection ) {
            this.props.onRowSelection([]);
        }
    };

    onExpandRow = (event: any) => {
        this.setState({
            expanded: event.data
        });
    };

    onSort = (event: any) => {
        this.props.setQuery({
            ...this.props.moduleState,
            sort_order: event.sortOrder,
            sort_field: event.sortField
        });
    }

    onRefresh = () => this.fetchData();

    render() {
        const { columns, name, size, detailRow, trackBy, onRowSelection, setRowClass } = this.props;
        const { entities, total, page, per_page, selected, expanded } = this.state;

        if ( selected.length > 0 ) {
            // @ts-ignore
            this.state.selected = []
        }

        const footerGroup: any = (
            <ColumnGroup>
                <Row>
                    <Column footer={
                        <Row>
                            <Col sm={ 12 } md={ 6 } className={ "sm:mt-2" }>
                                <Paginator first={ ( page - 1 ) * per_page }
                                           rows={ per_page }
                                           totalRecords={ total } pageLinkSize={ 3 }
                                           rowsPerPageOptions={ [10, 20, 200, 500] }
                                           onPageChange={ this.onPageChange } alwaysShow />
                            </Col>
                            <Col sm={ 12 } md={ 6 } className={ "mt-3" }>
                                <MultiSelect options={ this.props.columns.map((column: any) => ({id: column.field, label: column.title})) }
                                             onChange={ this.onColumnToggle } value={ this.state.visible_columns }
                                             optionValue={ "id" } filter
                                             optionLabel={ "label" }
                                             className="w-full sm:w-20rem" display="chip" />
                            </Col>
                        </Row>
                    } colSpan={ this.props.columns.length + 2 }>
                    </Column>
                </Row>
            </ColumnGroup>
        );

        return (
            <>
                <DataTable
                    dataKey={ trackBy }
                    ref={ this.dataGrid }
                    totalRecords={ total }
                    value={ entities }
                    size={ size }
                    virtualScrollerOptions={ { itemSize: this.props.itemSize ?? 76 } }
                    scrollHeight="800px"
                    stripedRows
                    scrollable
                    resizableColumns
                    showGridlines
                    reorderableColumns
                    stateStorage="local"
                    stateKey={ `dt-state-${ name }` }
                    footerColumnGroup={ footerGroup }
                    selectionMode={ null }
                    onSelectionChange={ this.onSelectRow }
                    selection={ selected }
                    expandedRows={ expanded }
                    onRowToggle={ this.onExpandRow }
                    rowExpansionTemplate={ detailRow }
                    rowClassName={ setRowClass ?? "" }
                    onSort={ this.onSort }
                    sortMode={"single"}
                    removableSort
                    sortField={ this.props.moduleState.sort_field }
                    sortOrder={ this.props.moduleState.sort_order }
                >
                    { onRowSelection && <Column selectionMode="multiple" headerStyle={ { width: '3rem' } } /> }
                    { detailRow && <Column
                      header={ '#' } alignHeader={ 'center' } expander={ true } style={ { width: '2rem' } }
                      bodyClassName={"detailed-row"}
                    /> }

                    {
                        columns
                        && columns
                        .filter((column: any) => this.state.visible_columns.some((vc: any) => vc === column.field))
                        .map((item: any, index: number) => {
                            return (
                                <Column
                                    // @ts-ignore
                                    alignHeader={ item.align ?? 'left' }
                                    bodyStyle={ { textAlign: item.align ?? 'left', backgroundColor: item.bgColor ?? "initial" } }
                                    field={ item.field } header={ item.title } body={ item.render }
                                    hidden={ item.hidden ?? false } key={ item.field }
                                    sortable={ item.sorting === true }
                                    sortField={ item.sortField ?? item.field }
                                    bodyClassName={ cx(css`
                                      &:before {
                                        content: ${ item.title } ': ';
                                      }
                                    `, `${ name }-${ item.title } ${ item.title }`)
                                    }
                                />
                            );
                        })
                    }
                </DataTable>
            </>
        );
    }
}

export default StateLessGrid;
